import React, {useContext, useRef, useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import DatePicker from 'react-datepicker'
import {UserContex} from '../App'
import { Carousel } from 'react-responsive-carousel';

import NavBar from './nav'

import "react-responsive-carousel/lib/styles/carousel.min.css";

require('react-datepicker/dist/react-datepicker.css')


const TimberPrice = ()=>{


  const history = useHistory()
  const {state, dispatch}= useContext(UserContex)

  const  [data, setData]= useState([])

  const [search,setSearch] = useState("")

  const [addStock,setAddStock]= useState(false)
  const [editStock,setEditStock]= useState(false)

  const[name,setName]= useState("")
  const[nameEdit,setNameEdit]= useState("")
  const[idEdit,setidEdit]= useState("")
  const[quantity,setQuantity]= useState()
  const[quantityEdit,setQuantityEdit]= useState()
  const[rate,setRate]= useState()
  const[bill,setBill]= useState()
  const[buyrate,setBuyRate]= useState()
  const[rateEdit,setRateEdit]= useState()
  const [date,setDate] = useState(new Date())
  const[cop,setCOP] = useState(false)
  const [labor,setLabour] = useState(0)
  const [timberCost,setTimberCost] = useState(0)
  const [materialCost,setMaterialCost] = useState(0)
  const [otherCost,setOtherCost] = useState(0)
  const [url,setUrl] = useState([])  

  const[progress,setProgress]= useState(false)


  

//     const [startDate, setStartDate] = useState(new Date());


//   const monthNames = ["January", "February", "March", "April", "May", "June",
//   "July", "August", "September", "October", "November", "December"];

// var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

// const d = new Date();




  useEffect(  ()=>{

    fetch('/gettimberprice',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.stocks)

      
      
  })

  },[])


  const postStockUpdate = ()=>{

    fetch('/addtimberprice',{

      method:"post",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
        name:name,
        rate:rate,
        
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        window.location.reload(false);
      }
    })
    .catch(err=>{
      console.log(err)
    })
    history.push('/timberprice')

  }

  
  const postStockUpdateUpdate = ()=>{

    fetch('/updatetimberprice',{

      method:"put",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
        id:idEdit,
        name:nameEdit,
        rate:rateEdit,
      
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        window.location.reload(false);
      }
    })
    .catch(err=>{
      console.log(err)
    })
    history.push('/timberprice')

  }





  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/search-stock',{

      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setData(results.stock)
      })


  }

  const singleStock = (Itemid)=>{

      console.log(Itemid)
    fetch('/getsingletimberprice',{
      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),
        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        id:Itemid
      })
  }).then(res=>res.json())
  .then(result=>{


    // setDataEdit(result.stock)
    console.log(result)
    setidEdit(result.stock._id)
    setNameEdit(result.stock.name)
    setRateEdit(result.stock.rate)
            
  })

  }


 
//   const uploadppt=({target:{files}})=>{

//     setProgress(true)

//     let data = new FormData()

//     for(const file of files){

//    data.append('uploadimage', file)
//    data.append('name',file.name)
// }
//        fetch("/uploadimage", {

//          method:"post",
//          body: data
//      })
//      .then(res=>res.json())
//      .then(data=>{

//       setUrl(old=>[...old, data.url])
//       setProgress(false)

      

       
//      })
//      .catch(err=>{
//          console.log(err)
//      })

// }


  


const uploadppt=({target:{files}})=>{

  setProgress(true)

 let data = new FormData()

 for(const file of files){

data.append('uploadimage', file)
data.append('name',file.name)
}
    fetch("/uploadimage", {

      method:"post",
      body: data
  })
  .then(res=>res.json())
  .then(data=>{

        setUrl(old=>[...old, data.url])
        setProgress(false)

   

    
  })
  .catch(err=>{
      console.log(err)
  })

}



return(

    <div>


 <script src="https://cdn.jsdelivr.net/gh/alpinejs/alpine@v2.x.x/dist/alpine.min.js" defer></script>

 <div  className="flex h-screen bg-white">

 <div className=" "></div>

        <link rel="stylesheet" href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css" />
  






        <div className="flex-1 flex flex-col overflow-hidden ">

            
          <NavBar/>


            <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white">
  <div className="container mx-auto px-6 py-8">
    <h3 className="text-gray-400 text-3xl font-medium">Timber Price</h3>


<div className="flex  md:flex-row flex-col ...">


<div class="shadow-lg rounded-xl bg-gray-200 w-full md:w-64 p-6 bg-white dark:bg-gray-500 relative overflow-hidden">
    <p class="text-black text-xl">
        Items
    </p>
    <div class="flex items-center my-4 text-blue-500 rounded justify-between">
        <span class="rounded-lg p-2 bg-white">
        <svg class="h-8 w-8 text-gray-400"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M14.5 10c-.83 0-1.5-.67-1.5-1.5v-5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5z" />  <path d="M20.5 10H19V8.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />  <path d="M9.5 14c.83 0 1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5S8 21.33 8 20.5v-5c0-.83.67-1.5 1.5-1.5z" />  <path d="M3.5 14H5v1.5c0 .83-.67 1.5-1.5 1.5S2 16.33 2 15.5 2.67 14 3.5 14z" />  <path d="M14 14.5c0-.83.67-1.5 1.5-1.5h5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-5c-.83 0-1.5-.67-1.5-1.5z" />  <path d="M15.5 19H14v1.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z" />  <path d="M10 9.5C10 8.67 9.33 8 8.5 8h-5C2.67 8 2 8.67 2 9.5S2.67 11 3.5 11h5c.83 0 1.5-.67 1.5-1.5z" />  <path d="M8.5 5H10V3.5C10 2.67 9.33 2 8.5 2S7 2.67 7 3.5 7.67 5 8.5 5z" /></svg>        </span>
        <div class="flex flex-col w-full ml-2 items-start justify-evenly">
            <p class="text-black text-lg">
                {data.length ? data.length:0}
            </p>
            <p class="text-gray-700 text-sm">
                Total Item Types
            </p>
        </div>
    </div>
   
    <div class="mt-4">
        <button type="button" class="py-2 px-4  bg-black hover:bg-gray-400 focus:ring-gray-400 focus:ring-offset-gray-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md "
        
        onClick={()=>setAddStock(true)}
        >
            Add Price +
        </button>
    </div>
</div>



  
</div>
    
   
    
    <br></br>

    {
addStock? 
(<>


{
addStock? 
(

  <>
  
    <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full mb-5" >
      <div className="md:flex w-full">
        
        <div className="w-full md:w-1/2 py-10 px-5 md:px-10">
          <div className="text-center mb-10">
            <h1 className="font-bold text-3xl text-gray-900">Add Price List </h1>
          </div>
          <div>


<div className="py-1">
<span className="px-1 text-sm text-gray-600">Name</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Name"
value={name}
onChange={(e)=>setName(e.target.value)}
>

</input>
</div>



<div className="py-1">
<span className="px-1 text-sm text-gray-600">Selling Rate</span>
                <input type="number"  className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                
               placeholder='Selling Price'
                value={rate}
                onChange={(e)=>setRate(e.target.value)} 
                ></input>
</div>




     



  <div className="w-full px-3 mb-5">

    
  
      <button className="block w-full max-w-xs mx-auto bg-blue-300 hover:bg-blue-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold"
                  onClick={() => {setAddStock(false);postStockUpdate()}}

      > Add Stock</button>
      <button className="block w-full max-w-xs mx-auto bg-red-300 hover:bg-red-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold mt-4"
                  onClick={() => {setAddStock(false)}}

      > Cancel</button>
                  


    </div>



    
</div>
       
</div>

<div className=" md:block md:w-1/2 w-full  bg-white py-10 px-10">


<Carousel showArrows={true} >
         
         
               {url ? url.map(pic=>{
                return(
                  <div>
                  <img src={pic} alt="medicine"  />
          
                   </div>
                )
               }):<></> }         
        
       
         
        
       

   

     </Carousel>


     {progress?         <span className=" text-base leading-normal"> Uploading...</span>:   <span className=" text-base leading-normal"> Upload Photo </span>
         }
                <input type='file' className="hidden"  required
                onChange={uploadppt}
                />
                
        
            {progress?
                <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
            :<></>}

</div>
          </div>
        </div>
  </>






)


:null

}

</>

)


:null

}




    {
editStock? 
(
  <>
  
  <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full mb-5" >
    <div className="md:flex w-full">
      
      <div className="w-full md:w-1/2 py-10 px-5 md:px-10">
        <div className="text-center mb-10">
          <h1 className="font-bold text-3xl text-gray-900">Update Product  </h1>
        </div>
        <div>


<div className="py-1">
<span className="px-1 text-sm text-gray-600">Name</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Name"
value={nameEdit}
onChange={(e)=>setNameEdit(e.target.value)}
>

</input>
</div>





<div className="py-1">
<span className="px-1 text-sm text-gray-600">Selling Rate</span>
              <input type="number"  className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
              bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
              
             placeholder='Selling Price'
              value={rateEdit}
              onChange={(e)=>setRateEdit(e.target.value)} 
              ></input>
</div>








<div className="w-full px-3 mb-5">

  

    <button className="block w-full max-w-xs mx-auto bg-blue-300 hover:bg-blue-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold"
                onClick={() => {setAddStock(false);postStockUpdateUpdate()}}

    > Update Stock</button>
    <button className="block w-full max-w-xs mx-auto bg-red-300 hover:bg-red-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold mt-4"
                onClick={() => {setEditStock(false)}}

    > Cancel</button>
                


  </div>



  
</div>
     
</div>

<div className=" md:block md:w-1/2 w-full  bg-white py-10 px-10">


<Carousel showArrows={true} >
       
       
             {url ? url.map(pic=>{
              return(
                <div>
                <img src={pic} alt="medicine"  />
        
                 </div>
              )
             }):<></> }         
      
     
       
      
     

 

   </Carousel>


   {progress?         <span className=" text-base leading-normal"> Uploading...</span>:   <span className=" text-base leading-normal"> Upload Photo </span>
       }
              <input type='file' className="hidden"  required
              onChange={uploadppt}
              />
              
      
          {progress?
              <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
          :<></>}

</div>
        </div>
      </div>
</>

)


:null

}








    <div className="w-full h-10 pl-3 pr-2 bg-gray-100 border-gray-50 rounded-full  outline-none  border-none flex focus:outline-none justify-between items-center relative">
  <input type="text" name="search" id="search" placeholder="Search" 
         className=" w-full bg-gray-100 border-none rounded-full    focus:outline-none focus:border-white active:outline-none"
         value= {search} onChange={(e)=>fetchPost(e.target.value)}
         />
  <button type="submit" className="ml-1 outline-none border-none focus:border-none  active:outline-none">
    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
         viewBox="0 0 24 24" className="w-6 h-6">
      <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
    </svg>
  </button>
</div>

 


    <div className="mt-8">
    </div>
    <div className="flex flex-col mt-8">
      <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="px-6 py-3 border-b border-gray-200 bg-black text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                Name
                  </th>
               
                <th className="px-6 py-3 border-b border-gray-200 bg-black text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                 Price                   
                   </th>
              
               
              
              </tr>
            </thead>




 <tbody className="bg-white">
            {data.map(item=>{


         
                
                // var timeDiff = Math.abs(date1.getTime() - date2.getTime());
                // var diffDays = Math.ceil(parseInt((date2 - date1) / (24 * 3600 * 1000)));
                

              return(

                
            
              <tr data-href="/profile" key={item._id} style={{cursor:"pointer"}} class="bg-white" >

                  <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                  
                  onClick={()=>{setEditStock(true);singleStock(item._id)}}
                  >
                             
                  <div className="flex items-center">
                                    
                    <div className="ml-4">
                      <div className="text-sm leading-5 font-medium text-gray-900">{item.name}
                      </div>
                    </div>
                  </div>
                </td>


                
              
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                  onClick={()=>{setEditStock(true);singleStock(item._id)}}

                >
                  <div className="text-sm leading-5 text-gray-900">{item.rate}</div>
                </td>
               
                             
                          
                        
              </tr> 


              )
            })}
           
  
             
            </tbody>

          
          </table>
        </div>
      </div>
    </div>
  </div>
</main>
</div>

    </div>
  </div>  
);


  
}


export default TimberPrice


