
import React, { useState,createContext,useContext,useEffect,useReducer } from 'react';
import {BrowserRouter,Link,Route, Switch, useHistory} from 'react-router-dom';
import {UserContex} from '../App'
import NavBar from './nav'
import Header from './Header';
import AOS from "aos"
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import "aos/dist/aos.css"

import { Autoplay, Navigation, Pagination } from 'swiper/modules';

const Landing = ()=>{


    const history = useHistory()
  
   
    const  [Employee, setEmployeeData]= useState()
  


        console.log(Employee)
var StockAmount = 0;

    //console.log(state.role)
    useEffect(  ()=>{
        AOS.init();
        AOS.refresh();
      
      },[])


      const [slidesPerView, setSlidesPerView] = useState(false); // Default number of slides

      // Update slidesPerView based on screen width
      const updateSlidesPerView = () => {
        if (window.innerWidth < 768) {
          setSlidesPerView(false);
        } else if (window.innerWidth < 1024) {
          setSlidesPerView(false);
        } else {
          setSlidesPerView(true);
        }
      };

      useEffect(() => {
        // Initial setup
        updateSlidesPerView();
    
        // Update slidesPerView when the window is resized
        window.addEventListener('resize', updateSlidesPerView);
    
        // Clean up the event listener
        return () => {
          window.removeEventListener('resize', updateSlidesPerView);
        };
      }, []);
    
      


    return(
            
<>




<Header></Header>
     {/* <img src="banner.jpg" class="absolute h-1/2  w-full md:object-cover object-contain"/> */}
    
     {/* md:fixed md:z-90 md:bottom-0  */}
     <div class=" relative overflow-hidden h-1/2 md:h-1/2 scroll-smooth z-20">
       {/* <img src="bgimg.jpg" class="absolute h-full w-full object-cover"/> */}

       <video autoPlay loop muted playbackRate={2.0}  class=" absolute h-1/2 w-full object-fill md:h-full md:w-full md:object-cover">
    <source src="/vid.mp4" type="video/mp4" />
       </video>
 <div class="container mx-auto px-6 md:px-12 relative z-10 flex items-center py-8 xl:py-12">
        <div data-aos="fade-up" data-aos-duration="3000" class="lg:w-3/5 xl:w-2/5 flex flex-col items-start relative z-10">
            <span class="font-bold uppercase text-green-500 animate-bounce repeat-1 duration-1000">
                ____________________
            </span>
            <h1 class=" font-bold text-4xl md:text-3xl text-white leading-tight mt-4 ">
                Get The Best Deals In Furniture And Timber 
                <br/>
                {/* <Typed
            className="md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2"
            strings={["it's pH", "water level"]}
            typeSpeed={200}
            backSpeed={220}
            loop
          /> */}
            </h1>
            <a href="tel:+97577428435" class="block bg-green-600  hover:ring-4 border-green-500  py-3 px-4 rounded-lg  text-lg text-white font-bold uppercase mt-4 md:mt-10">
                Order Now
            </a>
        </div>
    </div>
   
   
   
</div>  


{slidesPerView?<div class="bg-gradient-to-b from-gray-50 to-white p-8 rounded-md shadow-lg ">

<Swiper
        slidesPerView={5}

        // spaceBetween={3}

        centeredSlides={false}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        // navigation={true}
        modules={[Autoplay, ]}
        className="mySwiper"
      >
        <SwiperSlide>  <div class="shadow-lg rounded-2xl w-48 h-52  p-4  ml-6 py-6 bg-white  items-center justify-center">
    
    <a href='/products'>
    
    <div class="flex flex-col items-center justify-center">
    {/* <div class="w-24 h-24 bg-yellow-300 rounded-full relative"> */}

        <img class="w-28 h-28   relative" src='https://img.freepik.com/premium-vector/stack-wood-logs_118813-3016.jpg?size=626&ext=jpg'>
        </img>
        {/* </div> */}
        <p class="text-gray-800 text-l font-medium mb-4 mt-4">
        Logs

        </p>
    
    </div>
    </a>
</div></SwiperSlide>


        <SwiperSlide>

        <div class="shadow-lg rounded-2xl w-48 h-52  p-4  ml-6 py-6 bg-white  items-center justify-center">
    
    <a href='/products'>
    
    <div class="flex flex-col items-center justify-center">
    {/* <div class="w-24 h-24 bg-yellow-300 rounded-full relative"> */}

        <img class="w-28 h-28   relative" src='https://img.freepik.com/free-vector/sawmill-timber-mill-lumberjack-isometric-composition-with-isolated-images-industrial-loaders-manipulator-vehicles-wood_1284-60910.jpg?w=900&t=st=1693337439~exp=1693338039~hmac=d8a7a2d650c0c32ac43dfcab9ab72c384546c6e13b62456424c4f861d8bf63d9'>
        </img>
        {/* </div> */}
        <p class="text-gray-800 text-l font-medium mb-4 mt-4">
        Sawn Timber

        </p>
    
    </div>
    </a>
</div>
    
        </SwiperSlide>


        <SwiperSlide>
        <div class="shadow-lg rounded-2xl w-48 h-52  p-4  ml-6 py-6 bg-white  items-center justify-center">
    
    <a href='/products'>
    
    <div class="flex flex-col items-center justify-center">
    {/* <div class="w-24 h-24 bg-yellow-300 rounded-full relative"> */}

        <img class="w-28 h-28   relative" src='https://img.freepik.com/free-vector/close-open-wooden-doors-with-chrome-handles_107791-16714.jpg?w=900&t=st=1693371872~exp=1693372472~hmac=73b37afeab962c46ce83c3bc84ca174a314690be325167c3d803c2cb4992930c'>
        </img>
        {/* </div> */}
        <p class="text-gray-800 text-l font-medium mb-4 mt-4 text-center ">
        Doors, Windows & Frames

        </p>
    
    </div>
    </a>
</div>
        </SwiperSlide>


        <SwiperSlide>
        <div class="shadow-lg rounded-2xl w-48 h-52  p-4  ml-6 py-6 bg-white  items-center justify-center">
    
    <a href='/products'>
    
    <div class="flex flex-col items-center justify-center">
    {/* <div class="w-24 h-24 bg-yellow-300 rounded-full relative"> */}

        <img class="w-24 h-28   relative" src='https://img.freepik.com/free-vector/oak-wood-texture-background_1409-3046.jpg?size=626&ext=jpg'>
        </img>
        {/* </div> */}
        <p class="text-gray-800 text-l font-medium mb-4 mt-4 text-center">
        Wall and Floor Paneling

        </p>
    
    </div>
    </a>
</div>
        </SwiperSlide>
  
        <SwiperSlide>

        <div class="shadow-lg rounded-2xl w-48 h-52  p-4  ml-6 py-6 bg-white  items-center justify-center">
    
    <a href='/products'>
    
    <div class="flex flex-col items-center justify-center">
    {/* <div class="w-24 h-24 bg-yellow-300 rounded-full relative"> */}

        <img class="w-28 h-28   relative" src='https://img.freepik.com/free-vector/living-room-furniture-objects-isolated-white-background_1308-51303.jpg?size=626&ext=jpg'>
        </img>
        {/* </div> */}
        <p class="text-gray-800 text-l font-medium mb-4 mt-4 text-center">
        Furniture 

        </p>
    
    </div>
    </a>
</div>
        </SwiperSlide>
        <SwiperSlide>
        <div class="shadow-lg rounded-2xl w-48 h-52  p-4  ml-6 py-6 bg-white  items-center justify-center">
    
    <a href='/products'>
    
    <div class="flex flex-col items-center justify-center">
    {/* <div class="w-24 h-24 bg-yellow-300 rounded-full relative"> */}

        <img class="w-28 h-28   relative" src='https://img.freepik.com/free-vector/modern-furniture-elements-realistic-set-four-wooden-shelves-with-green-houseplants-pots-vector-illustration_1284-81277.jpg?size=626&ext=jpg'>
        </img>
        {/* </div> */}
        <p class="text-gray-800 text-l font-medium mb-4 mt-4 text-center">
        Home Decore 

        </p>
    
    </div>
    </a>
</div>
        </SwiperSlide>
        <SwiperSlide>
        <div class="shadow-lg rounded-2xl w-48 h-52  p-4  ml-6 py-6 bg-white  items-center justify-center">
    
    <a href='/products'>
    
    <div class="flex flex-col items-center justify-center">
    {/* <div class="w-24 h-24 bg-yellow-300 rounded-full relative"> */}

        <img class="w-28 h-28   relative" src='https://img.freepik.com/free-vector/realistic-wooden-boxes-collection_1284-71959.jpg?size=626&ext=jpg'>
        </img>
        {/* </div> */}
        <p class="text-gray-800 text-l font-medium mb-4 mt-4 text-center">
        Wooden Fruit Boxes 

        </p>
    
    </div>
    </a>
</div>
        </SwiperSlide>

        <SwiperSlide>
        <div class="shadow-lg rounded-2xl w-48 h-52  p-4  ml-6 py-6 bg-white  items-center justify-center">
    
    <a href='/products'>
    
    <div class="flex flex-col items-center justify-center">
    {/* <div class="w-24 h-24 bg-yellow-300 rounded-full relative"> */}

        <img class="w-28 h-28   relative rounded-md" src='https://img.freepik.com/premium-photo/watercolor-camping-forest-fire-with-wooden-pieces_531950-288.jpg?size=626&ext=jpg'>
        </img>
        {/* </div> */}
        <p class="text-gray-800 text-l font-medium mb-4 mt-4 text-center">
        Fire wood 

        </p>
    
    </div>
    </a>
</div>    
        </SwiperSlide>

        <SwiperSlide>  <div class="shadow-lg rounded-2xl w-48 h-52  p-4  ml-6 py-6 bg-white  items-center justify-center">
    
    <a href='/products'>
    
    <div class="flex flex-col items-center justify-center">
    {/* <div class="w-24 h-24 bg-yellow-300 rounded-full relative"> */}

        <img class="w-28 h-28   relative" src='https://img.freepik.com/premium-vector/stack-wood-logs_118813-3016.jpg?size=626&ext=jpg'>
        </img>
        {/* </div> */}
        <p class="text-gray-800 text-l font-medium mb-4 mt-4">
        Logs

        </p>
    
    </div>
    </a>
</div></SwiperSlide>


        <SwiperSlide>

        <div class="shadow-lg rounded-2xl w-48 h-52  p-4  ml-6 py-6 bg-white  items-center justify-center">
    
    <a href='/products'>
    
    <div class="flex flex-col items-center justify-center">
    {/* <div class="w-24 h-24 bg-yellow-300 rounded-full relative"> */}

        <img class="w-28 h-28   relative" src='https://img.freepik.com/free-vector/sawmill-timber-mill-lumberjack-isometric-composition-with-isolated-images-industrial-loaders-manipulator-vehicles-wood_1284-60910.jpg?w=900&t=st=1693337439~exp=1693338039~hmac=d8a7a2d650c0c32ac43dfcab9ab72c384546c6e13b62456424c4f861d8bf63d9'>
        </img>
        {/* </div> */}
        <p class="text-gray-800 text-l font-medium mb-4 mt-4">
        Sawn Timber

        </p>
    
    </div>
    </a>
</div>
    
        </SwiperSlide>


        <SwiperSlide>
        <div class="shadow-lg rounded-2xl w-48 h-52  p-4  ml-6 py-6 bg-white  items-center justify-center">
    
    <a href='/products'>
    
    <div class="flex flex-col items-center justify-center">
    {/* <div class="w-24 h-24 bg-yellow-300 rounded-full relative"> */}

        <img class="w-28 h-28   relative" src='https://img.freepik.com/free-vector/close-open-wooden-doors-with-chrome-handles_107791-16714.jpg?w=900&t=st=1693371872~exp=1693372472~hmac=73b37afeab962c46ce83c3bc84ca174a314690be325167c3d803c2cb4992930c'>
        </img>
        {/* </div> */}
        <p class="text-gray-800 text-l font-medium mb-4 mt-4 text-center ">
        Doors, Windows & Frames

        </p>
    
    </div>
    </a>
</div>
        </SwiperSlide>


        <SwiperSlide>
        <div class="shadow-lg rounded-2xl w-48 h-52  p-4  ml-6 py-6 bg-white  items-center justify-center">
    
    <a href='/products'>
    
    <div class="flex flex-col items-center justify-center">
    {/* <div class="w-24 h-24 bg-yellow-300 rounded-full relative"> */}

        <img class="w-24 h-28   relative" src='https://img.freepik.com/free-vector/oak-wood-texture-background_1409-3046.jpg?size=626&ext=jpg'>
        </img>
        {/* </div> */}
        <p class="text-gray-800 text-l font-medium mb-4 mt-4 text-center">
        Wall and Floor Paneling

        </p>
    
    </div>
    </a>
</div>
        </SwiperSlide>
  
        <SwiperSlide>

        <div class="shadow-lg rounded-2xl w-48 h-52  p-4  ml-6 py-6 bg-white  items-center justify-center">
    
    <a href='/products'>
    
    <div class="flex flex-col items-center justify-center">
    {/* <div class="w-24 h-24 bg-yellow-300 rounded-full relative"> */}

        <img class="w-28 h-28   relative" src='https://img.freepik.com/free-vector/living-room-furniture-objects-isolated-white-background_1308-51303.jpg?size=626&ext=jpg'>
        </img>
        {/* </div> */}
        <p class="text-gray-800 text-l font-medium mb-4 mt-4 text-center">
        Furniture 

        </p>
    
    </div>
    </a>
</div>
        </SwiperSlide>
        <SwiperSlide>
        <div class="shadow-lg rounded-2xl w-48 h-52  p-4  ml-6 py-6 bg-white  items-center justify-center">
    
    <a href='/products'>
    
    <div class="flex flex-col items-center justify-center">
    {/* <div class="w-24 h-24 bg-yellow-300 rounded-full relative"> */}

        <img class="w-28 h-28   relative" src='https://img.freepik.com/free-vector/modern-furniture-elements-realistic-set-four-wooden-shelves-with-green-houseplants-pots-vector-illustration_1284-81277.jpg?size=626&ext=jpg'>
        </img>
        {/* </div> */}
        <p class="text-gray-800 text-l font-medium mb-4 mt-4 text-center">
        Home Decore 

        </p>
    
    </div>
    </a>
</div>
        </SwiperSlide>
        <SwiperSlide>
        <div class="shadow-lg rounded-2xl w-48 h-52  p-4  ml-6 py-6 bg-white  items-center justify-center">
    
    <a href='/products'>
    
    <div class="flex flex-col items-center justify-center">
    {/* <div class="w-24 h-24 bg-yellow-300 rounded-full relative"> */}

        <img class="w-28 h-28   relative" src='https://img.freepik.com/free-vector/realistic-wooden-boxes-collection_1284-71959.jpg?size=626&ext=jpg'>
        </img>
        {/* </div> */}
        <p class="text-gray-800 text-l font-medium mb-4 mt-4 text-center">
        Wooden Fruit Boxes 

        </p>
    
    </div>
    </a>
</div>
        </SwiperSlide>

        <SwiperSlide>
        <div class="shadow-lg rounded-2xl w-48 h-52  p-4  ml-6 py-6 bg-white  items-center justify-center">
    
    <a href='/products'>
    
    <div class="flex flex-col items-center justify-center">
    {/* <div class="w-24 h-24 bg-yellow-300 rounded-full relative"> */}

        <img class="w-28 h-28   relative rounded-md" src='https://img.freepik.com/premium-photo/watercolor-camping-forest-fire-with-wooden-pieces_531950-288.jpg?size=626&ext=jpg'>
        </img>
        {/* </div> */}
        <p class="text-gray-800 text-l font-medium mb-4 mt-4 text-center">
        Fire wood 

        </p>
    
    </div>
    </a>
</div>    
        </SwiperSlide>
      </Swiper>

      </div>
      
      :
      
<div data-aos="fade-down" data-aos-easing=" linear"
     data-aos-duration="500" class="flex flex-col bg-gradient-to-b from-gray-50 to-white p-8 rounded-md shadow-lg overflow-x-auto hide-scroll-bar  
     ">




<div class="flex overflow-x-scroll   hide-scroll-bar">
  <div class="flex flex-nowrap md:h-64 h-64 mt-6 " >
    

  <div class="shadow-lg rounded-2xl w-48 h-52  p-4  ml-6 py-6 bg-white  items-center justify-center">
    
    <a href='/products'>
    
    <div class="flex flex-col items-center justify-center">

        <img class="w-28 h-28   relative" src='https://img.freepik.com/premium-vector/stack-wood-logs_118813-3016.jpg?size=626&ext=jpg'>
        </img>
        <p class="text-gray-800 text-l font-medium mb-4 mt-4">
        Logs

        </p>
    
    </div>
    </a>
</div>


    <div class="shadow-lg rounded-2xl w-48 h-52  p-4  ml-6 py-6 bg-white  items-center justify-center">
    
    <a href='/products'>
    
    <div class="flex flex-col items-center justify-center">

        <img class="w-28 h-28   relative" src='https://img.freepik.com/free-vector/sawmill-timber-mill-lumberjack-isometric-composition-with-isolated-images-industrial-loaders-manipulator-vehicles-wood_1284-60910.jpg?w=900&t=st=1693337439~exp=1693338039~hmac=d8a7a2d650c0c32ac43dfcab9ab72c384546c6e13b62456424c4f861d8bf63d9'>
        </img>
        <p class="text-gray-800 text-l font-medium mb-4 mt-4">
        Sawn Timber

        </p>
    
    </div>
    </a>
</div>
    
    <div class="shadow-lg rounded-2xl w-48 h-52  p-4  ml-6 py-6 bg-white  items-center justify-center">
    
    <a href='/products'>
    
    <div class="flex flex-col items-center justify-center">

        <img class="w-28 h-28   relative" src='https://img.freepik.com/free-vector/close-open-wooden-doors-with-chrome-handles_107791-16714.jpg?w=900&t=st=1693371872~exp=1693372472~hmac=73b37afeab962c46ce83c3bc84ca174a314690be325167c3d803c2cb4992930c'>
        </img>
        <p class="text-gray-800 text-l font-medium mb-4 mt-4 text-center ">
        Doors, Windows & Frames

        </p>
    
    </div>
    </a>
</div>
    <div class="shadow-lg rounded-2xl w-48 h-52  p-4  ml-6 py-6 bg-white  items-center justify-center">
    
    <a href='/products'>
    
    <div class="flex flex-col items-center justify-center">

        <img class="w-24 h-28   relative" src='https://img.freepik.com/free-vector/oak-wood-texture-background_1409-3046.jpg?size=626&ext=jpg'>
        </img>
        <p class="text-gray-800 text-l font-medium mb-4 mt-4 text-center">
        Wall and Floor Paneling

        </p>
    
    </div>
    </a>
</div>
   
    <div class="shadow-lg rounded-2xl w-48 h-52  p-4  ml-6 py-6 bg-white  items-center justify-center">
    
    <a href='/products'>
    
    <div class="flex flex-col items-center justify-center">

        <img class="w-28 h-28   relative" src='https://img.freepik.com/free-vector/living-room-furniture-objects-isolated-white-background_1308-51303.jpg?size=626&ext=jpg'>
        </img>
        <p class="text-gray-800 text-l font-medium mb-4 mt-4 text-center">
        Furniture 

        </p>
    
    </div>
    </a>
</div>
    <div class="shadow-lg rounded-2xl w-48 h-52  p-4  ml-6 py-6 bg-white  items-center justify-center">
    
    <a href='/products'>
    
    <div class="flex flex-col items-center justify-center">

        <img class="w-28 h-28   relative" src='https://img.freepik.com/free-vector/modern-furniture-elements-realistic-set-four-wooden-shelves-with-green-houseplants-pots-vector-illustration_1284-81277.jpg?size=626&ext=jpg'>
        </img>
        <p class="text-gray-800 text-l font-medium mb-4 mt-4 text-center">
        Home Decore 

        </p>
    
    </div>
    </a>
</div>
    <div class="shadow-lg rounded-2xl w-48 h-52  p-4  ml-6 py-6 bg-white  items-center justify-center">
    
    <a href='/products'>
    
    <div class="flex flex-col items-center justify-center">

        <img class="w-28 h-28   relative" src='https://img.freepik.com/free-vector/realistic-wooden-boxes-collection_1284-71959.jpg?size=626&ext=jpg'>
        </img>
        <p class="text-gray-800 text-l font-medium mb-4 mt-4 text-center">
        Wooden Fruit Boxes 

        </p>
    
    </div>
    </a>
</div>
    <div class="shadow-lg rounded-2xl w-48 h-52  p-4  ml-6 py-6 bg-white  items-center justify-center">
    
    <a href='/products'>
    
    <div class="flex flex-col items-center justify-center">

        <img class="w-28 h-28   relative rounded-md" src='https://img.freepik.com/premium-photo/watercolor-camping-forest-fire-with-wooden-pieces_531950-288.jpg?size=626&ext=jpg'>
        </img>
        <p class="text-gray-800 text-l font-medium mb-4 mt-4 text-center">
        Fire wood 

        </p>
    
    </div>
    </a>
</div>


 


 
  </div>
</div>
</div>
 

      }



{/* Features */}







<div id="services"  class="w-full md:p-8 p-7 bg-white">
    <div class="flex flex-col md:flex-row md:items-end justify-between mb-12 header">
        <div class="title">
            <p class="mb-4 text-4xl font-bold text-gray-800">
                Explore Our Services
            </p>
          
        </div>
       
        </div>

        <div class="md:px-6 md:py-6   md:rounded-lg md:bg-gray-50 md:shadow-lg">

        <div class="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-4 place-items-center">

   


        <div class="relative m-auto overflow-hidden rounded-lg md:py-1 py-4 shadow-lg cursor-pointer h-90 w-60 md:w-80 transition ease-in-out delay-150 ">
       <a href="#" class="block w-full h-full">
           <img alt="blog photo" src="/sawmill.png" class="object-cover w-full max-h-40"/>
           <div class="w-full p-4 bg-white dark:bg-gray-800 ">
               <p class="font-medium text-indigo-500 text-md">
               </p>
               <p class="mb-2 text-xl font-medium text-gray-800 dark:text-white">
                   Sawmill
               </p>
               <p class="font-light text-gray-400 dark:text-gray-300 text-md z-20">
                With 4 + Sawmills, we deliver timber of your need. Preorder or walk in we are at your servie             </p>
           </div>
   
           <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -bottom-6 -right-6 md:-right-4"/>
   
           
       </a>
   </div>
   
   
   
   <div class="relative m-auto overflow-hidden rounded-lg md:py-1 py-4 shadow-lg cursor-pointer h-90 w-60 md:w-80 transition ease-in-out delay-150  ">
       <a href="#" class="block w-full h-full">
           <img alt="blog photo" src="/service.png" class="object-cover w-full max-h-40"/>
           <div class="w-full p-4 bg-white dark:bg-gray-800">
               <p class="font-medium text-indigo-500 text-md">
               </p>
               <p class="mb-2 text-xl font-medium text-gray-800 dark:text-white ">
                   Furniture Unit
               </p>
               <p class="font-light text-gray-700 dark:text-gray-300 text-md">
               Order any furniture of your choice, Our expert crafts man are at yout service to provide permium wooden furniture .
               </p>
           </div>
   
           <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-20 -bottom-6 -right-6 md:-right-4"/>
   
       </a>
   </div>
   
   
      
   <div class="relative transition ease-in-out delay-150   m-auto overflow-hidden rounded-lg shadow-lg md:py-1 py-4 cursor-pointer h-90 w-60 md:w-80">
       <a href="#" class="block w-full h-full">
           <img alt="blog photo" src="/service3.png" class="object-cover w-full max-h-40"/>
           <div class="w-full p-4 bg-white dark:bg-gray-800">
               <p class="font-medium text-indigo-500 text-md">
               </p>
               <p class="mb-2 text-xl font-medium text-gray-800 dark:text-white">
               Flooring and Paneling
               </p>
               <p class="font-light text-gray-400 dark:text-gray-300 text-md">
               Our wooden Flooring and paneling unit is the perfect way to add a touch of natural beauty and warmth to any room.         </p>
           </div>
           <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -bottom-6 -right-6 md:-right-4"/>
   
       </a>
    
   </div>
   
   
   <div class="relative transition ease-in-out delay-150   m-auto overflow-hidden rounded-lg shadow-lg md:py-1 py-4 cursor-pointer h-90 w-60 md:w-80">
       <a href="#" class="block w-full h-full">
       <img alt="blog photo" src="/service4.png" class="object-cover w-full max-h-40"/>
           <div class="w-full p-4 bg-white dark:bg-gray-800">
               <p class="font-medium text-indigo-500 text-md">
               </p>
               <p class="mb-2 text-xl font-medium text-gray-800 dark:text-white">
               Stairs and Railings
               </p>
               <p class="font-light text-gray-400 dark:text-gray-300 text-md">
               Looking to add a touch of elegance and sophistication to your home? Our Stairs and Railings range are for you          </p>
           </div>
           <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -bottom-6 -right-6 md:-right-4"/>
   
       </a>
    
   </div>
   
  
   
   <div class="relative transition ease-in-out delay-150   m-auto overflow-hidden rounded-lg shadow-lg md:py-1 py-4 cursor-pointer h-90 w-60 md:w-80">
       <a href="#" class="block w-full h-full">
           <img alt="blog photo" src="/service5.png" class="object-cover w-full max-h-40"/>
           <div class="w-full p-4 bg-white dark:bg-gray-800">
               <p class="font-medium text-indigo-500 text-md">
               </p>
               <p class="mb-2 text-xl font-medium text-gray-800 dark:text-white">
                   Crafting and Engraving 
               </p>
               <p class="font-light text-gray-400 dark:text-gray-300 text-md">
               Our wooden crafting and engraving production is perfect for those who love to create             </p>
           </div>
           <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -bottom-6 -right-6 md:-right-4"/>
   
       </a>
   </div>
   
   

          
        







        </div>
         <div class="mt-6  flex justify-center items-center">
 <button onClick={()=>history.push('/products')} type="button" class="py-2 md:w-1/3 w-full h-14 px-4 flex justify-center items-center border-green-300  bg-none hover:bg-green-500 focus:ring-red-500 focus:ring-offset-red-200 text-green-500 hover:text-white  transition ease-in duration-200 text-center text-base  border-2 border-green-500 font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
  
     Explore our Products
     <svg class="h-8 w-8 "  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <polyline points="13 17 18 12 13 7" />  <polyline points="6 17 11 12 6 7" /></svg>
 </button>
 </div>

         </div>




 </div>

        


<div id="about" data-aos="fade-up" data-aos-easing=" linear"
     data-aos-duration="500" class="bg-white flex md:flex-row flex-col ml-4 mr-4 mt-2 md:space-x-3 ">

 
        
        <div class="text-start md:w-2/3 mb-1 py-12 px-4 px-6 lg:py-16 lg:px-8 z-20 shadow-lg rounded-2xl bg-fit relative backdrop-brightness-50 overflow-hidden bg-gray-50	"
        //  style={{ 
        //     background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("https://rnb.scene7.com/is/image/roomandboard/MHH_Liv_ES1021_0523?size=900,900&scl=1") `,
        //     backgroundRepeat:"no-repeat",
        //     backgroundSize:"cover"
        //   }}
        >
        <h2 class="text-3xl font-extrabold text-black dark:text-white text-2xl">
            <span class="block">
               Bulk Furniture Orders
            </span>
            <span class="block text-green-500">
            Wide range of custom made furniture for retail or tender
            </span>
        </h2>
        <ul class="mt-10">
                <li>
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <div class="flex items-center justify-center w-12 h-12 text-white bg-green-500 rounded-md">
                                <svg width="20" height="20" fill="currentColor" class="w-6 h-6" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                                    </path>
                                </svg>
                            </div>
                        </div>
                        <div class="ml-4">
                            <h5 class="text-lg font-bold leading-6 text-gray-900 dark:text-white">
                                Large order of Furniture
                            </h5>
                            <p class="mt-2 text-base leading-6 text-gray-500 dark:text-gray-700">
                                Know everything about your business in a single glance with your new dashboard.
                            </p>
                        </div>
                    </div>
                </li>
                <li class="mt-10">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <div class="flex items-center justify-center w-12 h-12 text-white bg-green-500 rounded-md">
                                <svg width="20" height="20" fill="currentColor" class="w-6 h-6" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                                    </path>
                                </svg>
                            </div>
                        </div>
                        <div class="ml-4">
                            <h5 class="text-lg font-bold leading-6 text-gray-900 dark:text-white">
                                Products for your Construction
                            </h5>
                            <p class="mt-2 text-base leading-6 text-gray-700 dark:text-gray-300">
                                Construction materials such as Doors, Door Frames, Windows, Window Frame and much more.
                            </p>
                        </div>
                    </div>
                </li>
                <li class="mt-10">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <div class="flex items-center justify-center w-12 h-12 text-white bg-green-500 rounded-md">
                                <svg width="20" height="20" fill="currentColor" class="w-6 h-6" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                                    </path>
                                </svg>
                            </div>
                        </div>
                        <div class="ml-4">
                            <h5 class="text-lg font-bold leading-6 text-gray-900 dark:text-white">
                                Tender Items 
                            </h5>
                            <p class="mt-2 text-base leading-6 text-gray-700 dark:text-gray-300">
                                Bulk Order Tender Items.
                            </p>
                        </div>
                    </div>
                </li>
            </ul>
      



             <div class="w-1/2 px-4 pb-4 ml-auto text-gray-500 md:w-1/3 absolute  right-0 bottom-0 " >
            <div class=" mt-12  rounded-md shadow">
                <a href="tel:+97577428435" class="w-24 h-24 ">

                {/* <svg class="text-green-400"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <polyline points="12 16 16 12 12 8" />  <line x1="8" y1="12" x2="16" y2="12" /></svg> */}

                <svg class="absolute w-24 h-24 rounded-full  -bottom-6 -right-6 md:-right-5 text-green-400"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <polyline points="12 16 16 12 12 8" />  <line x1="8" y1="12" x2="16" y2="12" /></svg>
                </a>
                
            </div>
        </div>
       
    </div>  



        <div class=" md:w-1/3 py-12 mb-1  px-4 px-6 lg:py-16 lg:px-8  z-20 shadow-lg rounded-2xl bg-fit bg-no-repeat relative overflow-hidden"
         style={{ 
            background: ` linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("https://img.freepik.com/premium-photo/stack-wooden-planks-pile-bricks_931878-4725.jpg?size=626&ext=jpg")`,
            backgroundRepeat:"no-repeat",
            backgroundSize:"cover"
          }}
        >
        <h2 class="text-3xl font-extrabold text-white dark:text-white text-2xl">
            <span class="block">
                Sawmill Unit
            </span>
            <span class="block text-green-500">
            Crafting excellence with every cut
            </span>
        </h2>
        <p class="text-xl mt-4 text-gray-400 ">
        Our Unit consisting of  is not just about efficiency and quality, but also about sustainability and responsibility. We are proud to be a leading provider of woodworking services that meet the highest standards of excellence.        </p>
        <div class="w-1/2 px-4 pb-4 ml-auto text-gray-500 md:w-1/3 absolute  right-0 bottom-0 ">
            <div class="mt-2  shadow">
                <a href="tel:+97577428435" class="bg-green-400">

                <svg class="absolute w-24 h-24 rounded-full  -bottom-6 -right-6 md:-right-5 text-green-400"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <polyline points="12 16 16 12 12 8" />  <line x1="8" y1="12" x2="16" y2="12" /></svg>

                   {/* <button type="button" class="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-300 py-4 px-6  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-full ">
                </button>  */}
                </a>
                
            </div>
        </div>
    </div>  
</div>















   


<div id="about" data-aos="fade-up" data-aos-duration="800" class="p-6 m-6 bg-white dark:bg-gray-800 flex flex-col md:flex-row overflow-hidden shadow-lg rounded-2xl "
>


    <div class="text-center md:w-full py-12 px-4 px-6 lg:py-16 lg:px-8 z-20 ">
        <h2 class="text-3xl font-extrabold text-gray-800 dark:text-white text-2xl">
            <span class="block">
                Who Are We?
            </span>
            {/* <span class="block text-indigo-500">
            Crafting excellence with every cut: your one-stop woodworking solution
            </span> */}
        </h2>
        <p class="text-xl  mt-4 text-gray-600">
        Green Gold Wood Works is a family-owned and operated wood-based industry located in the heart of Tsirang. We have been in business for more than 10 years, and have built a reputation for excellence in the Tsirang.      
        Our team is made up of skilled craftsmen and women who take great pride in their work. We use only the highest quality wood and materials to ensure that our products are durable and long-lasting. Our attention to detail and commitment to customer satisfaction is what sets us apart from the competition.
  
        
          </p>
        <div class="lg:mt-0 lg:flex-shrink-0">
            <div class="mt-12 inline-flex rounded-md shadow">
                <a href="tel:+97577428435">

                   <button type="button" class="py-4 px-6  bg-green-500 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                    Contact Us
                </button> 
                </a>
                
            </div>
        </div>
    </div>

    {/* <img src="about.png" class="  h-80 w-1/3 md:ml-14 mt-12"/> */}
    {/* <img src="/left.png" alt="btc logo" class="absolute  h-full    -right-24 md:-right-6"/>
    <img src="/right.png" alt="btc logo" class="absolute w-46 h-full    -left-24 md:-left-6"/> */}

</div>



{/* <p data-aos="fade-up" class="text-3xl text-center m-8">
                Visit Us
            </p> */}
         
<div data-aos="fade-up" class="flex flex-wrap w-full overflow-auto ">
    {/* <div class="flex flex-col w-full md:w-1/2">
       
        <div class="grid h-1/2 place-items-center  ">

        <div class="flex  content-center mb-4">
        <svg class="h-8 w-8 text-red-500 text-center animate-bounce repeat-1 duration-1000"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
      <p class="text-2xl text-center">
            Tsholingkhar, Tsirang
        </p>
    </div>
        <div class="flex  content-center mb-4">
        <svg class="h-8 w-8 text-green-500 text-center animate-bounce repeat-1 duration-1000"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /></svg>
      <p class="text-2xl text-center">
            +97577428435
        </p>
    </div>

                </div>
            </div> */}
            <div class="w-full shadow-2xl">
                
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14218.510163281082!2d90.0945033!3d27.0103295!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd436e08f64fe05f!2sGreen%20Gold%20Wood%20Works!5e0!3m2!1sen!2sbt!4v1672082500208!5m2!1sen!2sbt"              
              height="350"
              class="object-contain w-full"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            />             </div>
        </div>







<a href="tel:+97577428435">


<button class="fixed z-90 bottom-10 right-8 bg-green-600 w-10 h-10 rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl hover:bg-green-700 hover:drop-shadow-2xl hover:animate-bounce duration-300"> 
<svg class="h-8 w-8 text-white"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /></svg>
</button>
</a>


<footer class=" px-3 py-8 bg-white dark:bg-gray-800 text-2 text-gray-500 dark:text-gray-200 transition-colors duration-200">
    <div class="flex flex-col">
        <div class="md:hidden mt-7 mx-auto w-11 h-px rounded-full">
        </div>
        <div class="mt-4 md:mt-0 flex flex-col md:flex-row">
            <nav class="flex-1 flex flex-col items-center justify-center md:items-end md:border-r border-gray-100 md:pr-5">
                <a aria-current="page" href="#" class="hover:text-gray-700 dark:hover:text-white">
                
                <img
                  className="h-20 w-20"
                  src="/logo.png"
                  alt="Workflow"
                />
                </a>
                <a aria-current="page" href="#" class="hover:text-gray-700 dark:hover:text-white  text-center">
                Damphu,Tsirang

                </a>
                <a aria-current="page" href="#" class="hover:text-gray-700 dark:hover:text-white">
                    
                </a>
            </nav>
            <div class="md:hidden mt-4 mx-auto w-11 h-px rounded-full">
            </div>
            <div class="mt-4 md:mt-0 flex-1 flex items-center justify-center md:border-r border-gray-100">
                <a class="hover:text-primary-gray-20" href="">
                    <span class="sr-only">
                        View on Facebook
                    </span>
                    <svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" /></svg>
                </a>
                <a class="ml-4 hover:text-primary-gray-20" href="#">
                    <span class="sr-only">
                        
                    </span>
                    <svg class="h-8 w-8 text-purple-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />  <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" /></svg>
                </a>
            </div>
            <div class="md:hidden mt-4 mx-auto w-11 h-px rounded-full ">
            </div>
            <div class="mt-7 md:mt-0 flex-1 flex flex-col items-center justify-center md:items-start md:pl-5">
                <span class="">
                    © {new Date().getFullYear()}
                </span>
                <span class="mt-7 md:mt-1">
                    Created by :
                    <a class=" hover:text-primary-gray-20" href="">
                        Green Gold Wood Works
                    </a>
                </span>
            </div>
        </div>
    </div>
</footer>



</>



        
    )
}


export default Landing