
import React, { useState,createContext,useContext,useEffect,useReducer } from 'react';
import {BrowserRouter,Link,Route, Switch, useHistory} from 'react-router-dom';
import {UserContex} from '../App'
import NavBar from './nav'
import Header from './Header';
import AOS from "aos"
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import "aos/dist/aos.css"

import { Autoplay, Navigation, Pagination } from 'swiper/modules';

const Blogs = ()=>{


    const history = useHistory()
  

    const  [Employee, setEmployeeData]= useState()
    


        console.log(Employee)

    //console.log(state.role)
    useEffect(  ()=>{
        AOS.init();
        AOS.refresh();
      
      },[])


      const [slidesPerView, setSlidesPerView] = useState(false); // Default number of slides

      // Update slidesPerView based on screen width
      const updateSlidesPerView = () => {
        if (window.innerWidth < 768) {
          setSlidesPerView(false);
        } else if (window.innerWidth < 1024) {
          setSlidesPerView(false);
        } else {
          setSlidesPerView(true);
        }
      };

      useEffect(() => {
        // Initial setup
        updateSlidesPerView();
    
        // Update slidesPerView when the window is resized
        window.addEventListener('resize', updateSlidesPerView);
    
        // Clean up the event listener
        return () => {
          window.removeEventListener('resize', updateSlidesPerView);
        };
      }, []);
    
      


    return(
            
<>




<Header></Header>
   

















<div class="w-full p-12 bg-green-50 bg-no-repeat"

style={{background:"url(/back.png)",
        backgroundSize:"contain",
        backgroundRepeat:"repeat-y"
}}  
>
<h1 class=" text-center font-bold text-4xl sm:text-6xl text-black leading-tight mt-4  ">Our Services</h1>

<img   src="/under.png" class="mx-auto"></img>


    <div class="flex md:flex-row flex-col  items-end justify-between mb-12 header">
        <div class="title">
            <p class="mb-4 text-4xl font-bold text-gray-800">
                Lastest articles
            </p>
            {/* <p class="text-2xl font-light text-gray-400">
                All article are verified by experts and valdiate by the CTO
            </p> */}
        </div>
        <div class="text-end">
            <form class="flex flex-col justify-center w-3/4 max-w-sm space-y-3 md:flex-row md:w-full md:space-x-3 md:space-y-0">
                <div class=" relative ">
                    <input type="text" id="&quot;form-subscribe-Search" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-gray-50 text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Enter a title"/>
                    </div>
                    <button class="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200" type="submit">
                        Search
                    </button>
                </form>
            </div>
        </div>
        <div class="grid grid-cols-1 gap-12 md:grid-cols-2 xl:grid-cols-3">
            <div class="relative m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
                <a href="#" class="block w-full h-full">
                    <img alt="blog photo" src="/images/blog/1.jpg" class="object-cover w-full max-h-40"/>
                    <div class="w-full p-4 bg-white dark:bg-gray-800">
                        <p class="font-medium text-indigo-500 text-md">
                            Video
                        </p>
                        <p class="mb-2 text-xl font-medium text-gray-800 dark:text-white">
                            Work at home
                        </p>
                        <p class="font-light text-gray-400 dark:text-gray-300 text-md">
                            Work at home, remote, is the new age of the job, every person can work at home....
                        </p>
                       
                    </div>
                </a>
                <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -bottom-6 -right-6 md:-right-4"/>

            </div>
            <div class="relative m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
                <a href="#" class="block w-full h-full">
                    <img alt="blog photo" src="/images/blog/1.jpg" class="object-cover w-full max-h-40"/>
                    <div class="w-full p-4 bg-white dark:bg-gray-800">
                        <p class="font-medium text-indigo-500 text-md">
                            Video
                        </p>
                        <p class="mb-2 text-xl font-medium text-gray-800 dark:text-white">
                            Work at home
                        </p>
                        <p class="font-light text-gray-400 dark:text-gray-300 text-md">
                            Work at home, remote, is the new age of the job, every person can work at home....
                        </p>
                       
                    </div>
                </a>
                <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -bottom-6 -right-6 md:-right-4"/>

            </div>
            <div class="relative m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
                <a href="#" class="block w-full h-full">
                    <img alt="blog photo" src="/images/blog/1.jpg" class="object-cover w-full max-h-40"/>
                    <div class="w-full p-4 bg-white dark:bg-gray-800">
                        <p class="font-medium text-indigo-500 text-md">
                            Video
                        </p>
                        <p class="mb-2 text-xl font-medium text-gray-800 dark:text-white">
                            Work at home
                        </p>
                        <p class="font-light text-gray-400 dark:text-gray-300 text-md">
                            Work at home, remote, is the new age of the job, every person can work at home....
                        </p>
                       
                    </div>
                </a>
                <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -bottom-6 -right-6 md:-right-4"/>

            </div>
            <div class="relative m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
                <a href="#" class="block w-full h-full">
                    <img alt="blog photo" src="/images/blog/1.jpg" class="object-cover w-full max-h-40"/>
                    <div class="w-full p-4 bg-white dark:bg-gray-800">
                        <p class="font-medium text-indigo-500 text-md">
                            Video
                        </p>
                        <p class="mb-2 text-xl font-medium text-gray-800 dark:text-white">
                            Work at home
                        </p>
                        <p class="font-light text-gray-400 dark:text-gray-300 text-md">
                            Work at home, remote, is the new age of the job, every person can work at home....
                        </p>
                       
                    </div>
                </a>
                <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -bottom-6 -right-6 md:-right-4"/>

            </div>
            <div class="relative m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
                <a href="#" class="block w-full h-full">
                    <img alt="blog photo" src="/images/blog/1.jpg" class="object-cover w-full max-h-40"/>
                    <div class="w-full p-4 bg-white dark:bg-gray-800">
                        <p class="font-medium text-indigo-500 text-md">
                            Video
                        </p>
                        <p class="mb-2 text-xl font-medium text-gray-800 dark:text-white">
                            Work at home
                        </p>
                        <p class="font-light text-gray-400 dark:text-gray-300 text-md">
                            Work at home, remote, is the new age of the job, every person can work at home....
                        </p>
                       
                    </div>
                </a>
                <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -bottom-6 -right-6 md:-right-4"/>

            </div>
            <div class="relative m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
                <a href="#" class="block w-full h-full">
                    <img alt="blog photo" src="/images/blog/1.jpg" class="object-cover w-full max-h-40"/>
                    <div class="w-full p-4 bg-white dark:bg-gray-800">
                        <p class="font-medium text-indigo-500 text-md">
                            Video
                        </p>
                        <p class="mb-2 text-xl font-medium text-gray-800 dark:text-white">
                            Work at home
                        </p>
                        <p class="font-light text-gray-400 dark:text-gray-300 text-md">
                            Work at home, remote, is the new age of the job, every person can work at home....
                        </p>
                       
                    </div>
                </a>
                <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -bottom-6 -right-6 md:-right-4"/>

            </div>
            <div class="relative m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
                <a href="#" class="block w-full h-full">
                    <img alt="blog photo" src="/images/blog/1.jpg" class="object-cover w-full max-h-40"/>
                    <div class="w-full p-4 bg-white dark:bg-gray-800">
                        <p class="font-medium text-indigo-500 text-md">
                            Video
                        </p>
                        <p class="mb-2 text-xl font-medium text-gray-800 dark:text-white">
                            Work at home
                        </p>
                        <p class="font-light text-gray-400 dark:text-gray-300 text-md">
                            Work at home, remote, is the new age of the job, every person can work at home....
                        </p>
                       
                    </div>
                </a>
                <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -bottom-6 -right-6 md:-right-4"/>

            </div>
            <div class="relative m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
                <a href="#" class="block w-full h-full">
                    <img alt="blog photo" src="/images/blog/1.jpg" class="object-cover w-full max-h-40"/>
                    <div class="w-full p-4 bg-white dark:bg-gray-800">
                        <p class="font-medium text-indigo-500 text-md">
                            Video
                        </p>
                        <p class="mb-2 text-xl font-medium text-gray-800 dark:text-white">
                            Work at home
                        </p>
                        <p class="font-light text-gray-400 dark:text-gray-300 text-md">
                            Work at home, remote, is the new age of the job, every person can work at home....
                        </p>
                       
                    </div>
                </a>
                <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -bottom-6 -right-6 md:-right-4"/>

            </div>
            <div class="relative m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
                <a href="#" class="block w-full h-full">
                    <img alt="blog photo" src="/images/blog/1.jpg" class="object-cover w-full max-h-40"/>
                    <div class="w-full p-4 bg-white dark:bg-gray-800">
                        <p class="font-medium text-indigo-500 text-md">
                            Video
                        </p>
                        <p class="mb-2 text-xl font-medium text-gray-800 dark:text-white">
                            Work at home
                        </p>
                        <p class="font-light text-gray-400 dark:text-gray-300 text-md">
                            Work at home, remote, is the new age of the job, every person can work at home....
                        </p>
                       
                    </div>
                </a>
                <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -bottom-6 -right-6 md:-right-4"/>

            </div>
            <div class="relative m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
                <a href="#" class="block w-full h-full">
                    <img alt="blog photo" src="/images/blog/1.jpg" class="object-cover w-full max-h-40"/>
                    <div class="w-full p-4 bg-white dark:bg-gray-800">
                        <p class="font-medium text-indigo-500 text-md">
                            Video
                        </p>
                        <p class="mb-2 text-xl font-medium text-gray-800 dark:text-white">
                            Work at home
                        </p>
                        <p class="font-light text-gray-400 dark:text-gray-300 text-md">
                            Work at home, remote, is the new age of the job, every person can work at home....
                        </p>
                       
                    </div>
                </a>
                <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -bottom-6 -right-6 md:-right-4"/>

            </div>
            <div class="relative m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
                <a href="#" class="block w-full h-full">
                    <img alt="blog photo" src="/images/blog/1.jpg" class="object-cover w-full max-h-40"/>
                    <div class="w-full p-4 bg-white dark:bg-gray-800">
                        <p class="font-medium text-indigo-500 text-md">
                            Video
                        </p>
                        <p class="mb-2 text-xl font-medium text-gray-800 dark:text-white">
                            Work at home
                        </p>
                        <p class="font-light text-gray-400 dark:text-gray-300 text-md">
                            Work at home, remote, is the new age of the job, every person can work at home....
                        </p>
                       
                    </div>
                </a>
                <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -bottom-6 -right-6 md:-right-4"/>

            </div>
            <div class="relative m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
                <a href="#" class="block w-full h-full">
                    <img alt="blog photo" src="/images/blog/1.jpg" class="object-cover w-full max-h-40"/>
                    <div class="w-full p-4 bg-white dark:bg-gray-800">
                        <p class="font-medium text-indigo-500 text-md">
                            Video
                        </p>
                        <p class="mb-2 text-xl font-medium text-gray-800 dark:text-white">
                            Work at home
                        </p>
                        <p class="font-light text-gray-400 dark:text-gray-300 text-md">
                            Work at home, remote, is the new age of the job, every person can work at home....
                        </p>
                       
                    </div>
                </a>
                <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -bottom-6 -right-6 md:-right-4"/>

            </div>
            <div class="relative m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
                <a href="#" class="block w-full h-full">
                    <img alt="blog photo" src="/images/blog/1.jpg" class="object-cover w-full max-h-40"/>
                    <div class="w-full p-4 bg-white dark:bg-gray-800">
                        <p class="font-medium text-indigo-500 text-md">
                            Video
                        </p>
                        <p class="mb-2 text-xl font-medium text-gray-800 dark:text-white">
                            Work at home
                        </p>
                        <p class="font-light text-gray-400 dark:text-gray-300 text-md">
                            Work at home, remote, is the new age of the job, every person can work at home....
                        </p>
                       
                    </div>
                </a>
                <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -bottom-6 -right-6 md:-right-4"/>

            </div>
           
        </div>
        
    </div>





<footer class=" px-3 py-8 bg-black dark:bg-gray-800 text-2 text-gray-500 dark:text-gray-200 transition-colors duration-200">
    <div class="flex flex-col">


        <div class="md:hidden mt-7 mx-auto w-11 h-px rounded-full">
        </div>
        <div class="mt-4 md:mt-0 flex flex-col md:flex-row">
            <nav class="flex-1 flex flex-col items-center justify-center md:items-end md:border-r border-gray-100 md:pr-5">
                <a aria-current="page" href="#" class="hover:text-gray-700 dark:hover:text-white">
                
                <img
                  className="h-20 w-20"
                  src="/logo.png"
                  alt="Workflow"
                />
                </a>
                <a aria-current="page" href="#" class="hover:text-gray-700 dark:hover:text-white  text-center">
                Damphu,Tsirang

                </a>
                <a aria-current="page" href="#" class="hover:text-gray-700 dark:hover:text-white">
                    
                </a>
            </nav>
            <div class="md:hidden mt-4 mx-auto w-11 h-px rounded-full">
            </div>
            <div class="mt-4 md:mt-0 flex-1 flex items-center justify-center md:border-r border-gray-100">
                <a class="hover:text-primary-gray-20" href="">
                    <span class="sr-only">
                        View on Facebook
                    </span>
                    <svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" /></svg>
                </a>
                <a class="ml-4 hover:text-primary-gray-20" href="#">
                    <span class="sr-only">
                        
                    </span>
                    <svg class="h-8 w-8 text-purple-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />  <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" /></svg>
                </a>
            </div>
            <div class="md:hidden mt-4 mx-auto w-11 h-px rounded-full ">
            </div>
            <div class="mt-7 md:mt-0 flex-1 flex flex-col items-center justify-center md:items-start md:pl-5">
                <span class="">
                    © {new Date().getFullYear()}
                </span>
                <span class="mt-7 md:mt-1">
                    Created by :
                    <a class=" hover:text-primary-gray-20" href="">
                        Green Gold Wood Works
                    </a>
                </span>
            </div>
        </div>
    </div>
</footer>



</>



        
    )
}


export default Blogs