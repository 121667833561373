
import React, { useState,createContext,useContext,useEffect,useReducer } from 'react';
import {BrowserRouter,Link,Route, Switch, useHistory} from 'react-router-dom';
import {UserContex} from '../App'
import NavBar from './nav'
import Header from './Header';
import { Carousel } from 'react-responsive-carousel';

const Products = ()=>{


    const history = useHistory()
  
    const  [salesData, setSalesData]= useState()
    const  [salesDataToday, setSalesDataToday]= useState()
    const  [stockData, setStockData]= useState()
    const  [saleDataMonth, setStockDataMonth]= useState()
    const  [creditData, setCreditData]= useState([])
    const  [creditDataToday, setCreditDataToday]= useState([])
    const  [ExpenseData, setExpenseData]= useState()
    const  [Employee, setEmployeeData]= useState()
    const  [ExpenseDataMonth, setExpenseDataMonth]= useState()
    const  [data, setData]= useState([])
    const [search,setSearch] = useState("")

    const [date,setDate] = useState(new Date())

    const {state, dispatch}= useContext(UserContex)


        console.log(Employee)
var StockAmount = 0;

    //console.log(state.role)
    useEffect(  ()=>{

    fetch('/getStock',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.stocks)
      
  })

  },[])


    const fetchPost=(query)=>{


        setSearch(query)
    
        fetch('/search-stock',{
    
          method:"post",
          headers:{
            Authorization: "Bearer " +localStorage.getItem("jwt"),
    
            "Content-Type":"application/json"
    
          },
          body:JSON.stringify({
            query
          })
    
        }).then(res=> res.json())
          .then(results=>{
            
            setData(results.stock)
          })
    
    
      }
    


    return(
            
<main class="bg-white dark:bg-gray-800 h-screen overflow-auto relative">
    <div class="flex items-start justify-between">
       
        <div class="flex flex-col w-full ">

            

            <Header></Header>


            
{/* 
            <div>
      <p>Microphone: {listening ? 'on' : 'off'}</p>
      <button onClick={SpeechRecognition.startListening}>Start</button>
      <button onClick={SpeechRecognition.stopListening}>Stop</button>
      <button onClick={resetTranscript}>Reset</button>
      <p>{transcript}</p>
    </div> 
    
    
    */}


         <div class=" h-50 sm:h-96  w-full   bg-cover bg-center  bg-no-repeat" 
         
         style={{ 
      backgroundImage: `url("/prodbg.png")` 
    }}
    >

                <div class=" mx-8 sm:mx-20 my-24 sm:my-40 ">

            <div className="w-full  object-center  h-10 p-3 bg-gray-100 border-gray-50 rounded-full  outline-none  border-none flex focus:outline-none justify-between items-center relative">
 <input type="text" name="search" id="search" placeholder={'Search'} 
        className=" w-full bg-gray-100 border-none rounded-full    focus:outline-none focus:border-white active:outline-none"
        
        value= {search} onChange={(e)=>fetchPost(e.target.value)}
        />
 <button type="submit" className="ml-1 outline-none border-none focus:border-none  active:outline-none">
   <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        viewBox="0 0 24 24" className="w-6 h-6">
     <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
   </svg>
 </button>
 <button type="submit" className="ml-1 outline-none border-none focus:border-none  active:outline-none"
// onClick={()=>{setSearch(transcript); startListen()}}
>

</button></div>
            </div>
            </div>




















        </div>
     

    </div>



       
         
    <div className="grid  grid-cols-2  md:grid-cols-4  md:gap-4      ">





{data? data.map(Item=>{

return(
  <div class="w-48 sm:w-64 m-auto  bg-white shadow-lg rounded-2xl relative overflow-hidden">
    <div >
    <Link to={`/singleproduct/${Item._id}`}>

    <Carousel  showArrows={true} showThumbs={false} >


{Item.pic ? Item.pic.map(pic=>{
return(
<div className='max-h-48'>
<img src={pic} alt="medicine"  />

</div>
)
}):<></> }  
</Carousel>

</Link>


    </div>

  <div class="p-4 m-3 bg-white rounded-lg">
      <p class="text-xl font-bold text-black ">
      {Item.name}
      </p>
      {/* <p class="text-xs text-gray-50">
          {Item.description}
      </p> */}
          {/* <div class="flex flex-col items-center justify-between text-gray-900 md:flex-row">
                  
                    <button class="px-6 py-2 uppercase transition duration-200 ease-in border-2 border-gray-900 rounded-full hover:bg-gray-800 hover:text-white focus:outline-none">
                        Get a Quote
                    </button>
                </div> */}
  </div>
  <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -bottom-6 -right-6 md:-right-4"/>

</div>

)
}):<></>}




</div>








    









</main>


        
    )
}


export default Products