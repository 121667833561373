import React, {useContext, useRef, useEffect, useState} from 'react'
import {useParams,Link, useHistory} from 'react-router-dom'
import DatePicker from 'react-datepicker'
import Select from "react-select";

import NavBar from './nav';
import { Document, Page, Text, View, StyleSheet , PDFViewer,PDFDownloadLink,Image} from '@react-pdf/renderer';


import {UserContex} from '../App'

const Customer = ()=>{


  const history = useHistory()
  const {state, dispatch}= useContext(UserContex)

  const  [data, setData]= useState([])
  const  [salesData, setSalesData]= useState([])
  const  [salesDataSawmill, setSalesDataSawmill]= useState([])
  const  [creditData, setCreditData]= useState([])
  const  [creditDataSawmill, setCreditDataSawmill]= useState([])
 // const  [salesData, setSalesData]= useState([])
  const  [singleSalesData, setSingleSalesData]= useState([])
  const  [singleSalesDataSawmill, setSingleSalesDataSawmill]= useState([])
  const [search,setSearch] = useState("")
  const [searchCredit,setSearchCredit] = useState("")

  const [addSales,setAddSales]= useState(false)
  const [showsawmillsingle,setShowSawmillSingle]= useState(false)
  const[show,setShow] = useState(false)
  const[showsawmillsale,setShowSawmill] = useState(false)
  const[showcredit,setShowCredit] = useState(false)
  const[showcreditsawmill,setShowCreditSawmill] = useState(false)
  const[progress,setProgress]= useState(true)

  const[name,setName]= useState("")
  const[l,setL]= useState()
  const[b,setB]= useState()
  const[h,setH]= useState()
  const[totalcft,setTotalCFT]= useState(0)


  const[stockName,setStockName]= useState([])
  const[timberstockName,setTimberStockName]= useState([])
  const[quantity,setQuantity]= useState()
  const[amount,setAmount]= useState(0)
  const[paid,setPaid]= useState()
  const[rate,setRate]= useState()


  const [soldItem,setSoldItem]= useState([])
  const [soldItemSawmill,setSoldItemSawmill]= useState([])
  const [creditItem,setCreditItem]= useState([])
  const [creditItemSawmill,setCreditItemSawmill]= useState([])
  const [soldItemDisplay,setSoldItemDisplay]= useState([])
  const [soldItemDisplaySawmill,setSoldItemDisplaySawmill]= useState([])
  const [creditItemDisplay,setCreditItemDisplay]= useState([])
  const [creditItemDisplaySawmill,setCreditItemDisplaySawmill]= useState([])

  const [addStock,setAddStock]= useState(false)

  const [date,setDate] = useState(new Date())

  const [options,setOptions] = useState({});
  const [timberoptions,setTimberOptions] = useState({});
  const fileName = 'Bill'

  const [TotalCredit,setTotal]= useState()
  // const [sawmillsaletotal,setSawmillsaleTotal]= useState(0)

  var sumcft=0;
  var sumamount=0;
  var sumsinglecft=0;
  var sawmillsaletotal = 0
    
  const {customerid} =useParams()


  useEffect(  ()=>{

    fetch(`/customer/${customerid}`,{
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{

      setData(result.customer)
      setSalesData(result.sales)

      setCreditData(result.credits)

      setSalesDataSawmill(result.sawmill)

      setCreditDataSawmill(result.sawmillcredit)
      setProgress(false)

      let sum = 0
      result.credits.map(sales=> sales.creditItem.map(pro=>{
    sum += pro.product.rate*pro.qty
      }) )

      setTotal(sum)



  })

    fetch(`/getStock`,{
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{

    const options = result.stocks.map(d => (
      
      
      
      {

        value : d._id,
        label : d.name,
        rate: d.rate
      
      
      
    }))

      setOptions(options)
  })



    fetch(`/gettimberprice`,{
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{

    const timberoptions = result.stocks.map(d => (
      
      
      
      {

        value : d._id,
        label : d.name,
        rate: d.rate
      
      
      
    }))

    setTimberOptions(timberoptions)
  })





  },[])


  const postCreditFinal = ()=>{

    fetch('/addCredit',{

      method:"post",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
        creditItem:creditItem,
        creditor:customerid,
        amount:amount,
        date:date
        
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }
    

      else{

        //console.log(data.sold)
          // setSalesData(data.sold)  

          history.go(`/singlerecord/${customerid}`)
         // window.location.reload(false);


      }
    })
    .catch(err=>{
      console.log(err)
    })

  }
  
  const postCreditSawmillFinal = ()=>{

    fetch('/addSawmillCredit',{

      method:"post",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
        creditItem:creditItemSawmill,
        creditor:customerid,
        amount:amount,
        date:date
        
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }
    

      else{

        //console.log(data.sold)
          // setSalesData(data.sold)  

          history.go(`/singlerecord/${customerid}`)
         // window.location.reload(false);


      }
    })
    .catch(err=>{
      console.log(err)
    })

  }

  const deleteCredit = (postId)=>{

    fetch(`/deleteCredit/${postId}`,{
        method: "delete",
        headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")
        },
        body: JSON.stringify({
          date:date
          
        })
    }).then(res=>res.json())
    .then(result=>{
      window.location.reload(false);

    }).catch(err=>{
        console.log(err)
    })
}
  const deleteSawmillCredit = (postId)=>{

    fetch(`/deleteSawmillCredit/${postId}`,{
        method: "delete",
        headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")
        },
        body: JSON.stringify({
          date:date
          
        })
    }).then(res=>res.json())
    .then(result=>{
      window.location.reload(false);

    }).catch(err=>{
        console.log(err)
    })
}



  const deleteSales = (postId)=>{

    fetch(`/deleteSawmillSale/${postId}`,{
        method: "delete",
        headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")
        }
    }).then(res=>res.json())
    .then(result=>{
      window.location.reload(false);

    }).catch(err=>{
        console.log(err)
    })
}
  const deleteFurnitureSales = (postId)=>{

    fetch(`/deletesales/${postId}`,{
        method: "delete",
        headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")
        }
    }).then(res=>res.json())
    .then(result=>{
      window.location.reload(false);

    }).catch(err=>{
        console.log(err)
    })
}




  const updatePaid = ()=>{

    fetch('/updatePaid',{

      method:"put",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")


      },
      body: JSON.stringify({
        
        id: customerid,
        paid:paid,

        
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        window.location.reload(false);
      }
    })
   
  }

  const clearPaid = ()=>{

    fetch('/clearpaid',{

      method:"put",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")


      },
      body: JSON.stringify({
        
        id: customerid,
        paid:paid,

        
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        window.location.reload(false);
      }
    })
   
  }

  const fetchCredit=(query)=>{


    setSearchCredit(query)

    fetch('/search-credit',{

      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),
        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query,
        customer:customerid
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setCreditData(results.credit)
      })


  }

  const postSales = ()=>{

    

    setSoldItem(item=>[...item, {product:stockName.value,rate:stockName.rate,qty:quantity}])
    setSoldItemDisplay(item=>[...item, { product:stockName.label,rate:stockName.rate,qty:quantity,amt:stockName.rate*quantity}])
    setAmount(prevCount => prevCount +(stockName.rate*quantity))
    

      setStockName([])
      setQuantity("")


  }


  const postSalesSawmill = ()=>{

    

    setSoldItemSawmill(item=>[...item, {product:timberstockName.label,rate:timberstockName.rate,l:l,b:b,h:h,qty:quantity}])
    setSoldItemDisplaySawmill(item=>[...item, { product:timberstockName.label,rate:timberstockName.rate,l:l,b:b,h:h,qty:quantity,amt:( timberstockName.rate*(l*b*h*quantity/144)).toFixed(2) }])

      //setStockName([])

      // console.log("DATA QUTHH",timberstockName.rate,quantity)
      setAmount(prevCount => prevCount +(timberstockName.rate*(l*b*h*quantity/144)))
      // console.log("AMOUNT",amount)
      setQuantity("")
      setL("")
      // setB("")
      // setH("")


  }



  const postCredit = ()=>{

    

    setCreditItem(item=>[...item, {product:stockName.value,rate:stockName.rate,qty:quantity}])
    setCreditItemDisplay(item=>[...item, { product:stockName.label,rate:stockName.rate,qty:quantity,amt:stockName.rate*quantity}])

    setAmount(prevCount => prevCount +(stockName.rate*quantity))

      setStockName([])
      setQuantity("")
     


  }


  const postCreditSawmill = ()=>{

    


    setCreditItemSawmill(item=>[...item, {product:timberstockName.label,rate:timberstockName.rate,l:l,b:b,h:h,qty:quantity}])
    setCreditItemDisplaySawmill(item=>[...item, { product:timberstockName.label,rate:timberstockName.rate,l:l,b:b,h:h,qty:quantity,amt:(timberstockName.rate*(l*b*h*quantity/144)).toFixed(2)}])
    setAmount(prevCount => prevCount +(timberstockName.rate*(l*b*h*quantity/144)))


      setStockName([])
      setQuantity("")
      setL("")
      // setB("")
      // setH("")


  }


  const postBill = ()=>{

fetch('/addSales',{

      method:"post",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({

        soldItem:soldItem,
        customer:customerid,
        amount:amount,
        date
               
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }
    

      else{

        //console.log(data.sold)
          // setSalesData(data.sold)  

         // window.location.reload(false);

         history.go("/allSales")


      }
    })
    .catch(err=>{
      console.log(err)
    })

  }
  
  const postSaleSawmill = ()=>{

fetch('/addSawmillSales',{

      method:"post",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({

        soldItem:soldItemSawmill,
        customer:customerid,
        amount:amount,
        date
               
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }
    

      else{

        //console.log(data.sold)
          // setSalesData(data.sold)  

         // window.location.reload(false);

         history.go("/allSales")


      }
    })
    .catch(err=>{
      console.log(err)
    })

  }
  
  const fetchPostSearch=(query)=>{


    setSearch(query)

    fetch('/search-sale-customer',{

      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),
        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query, customer: customerid
      })

    }).then(res=> res.json())
      .then(results=>{
        
        
        setSalesData(results.sales)
      })


  }

  const removeProduct = (index,amt) => {
    
    
    setSoldItemDisplay([
      ...soldItemDisplay.slice(0, index),
      ...soldItemDisplay.slice(index + 1, soldItemDisplay.length)
    ]);


    setSoldItem([
      ...soldItem.slice(0, index),
      ...soldItem.slice(index + 1, soldItem.length)
    ]);

    setAmount(prevCount => prevCount - amt)


  }
  const removeProductSawmill = (index,amt) => {
    
    
    setSoldItemDisplaySawmill([
      ...soldItemDisplaySawmill.slice(0, index),
      ...soldItemDisplaySawmill.slice(index + 1, soldItemDisplaySawmill.length)
    ]);


    setSoldItemSawmill([
      ...soldItemSawmill.slice(0, index),
      ...soldItemSawmill.slice(index + 1, soldItemSawmill.length)
    ]);
    setAmount(prevCount => prevCount - amt)


  }

  const removeProductCredit = (index,amt) => {
    
    
    setCreditItemDisplay([
      ...creditItemDisplay.slice(0, index),
      ...creditItemDisplay.slice(index + 1, creditItemDisplay.length)
    ]);


    setCreditItem([
      ...creditItem.slice(0, index),
      ...creditItem.slice(index + 1, creditItem.length)
    ]);

    setAmount(prevCount => prevCount - amt)

  }

  const removeProductCreditSawmill = (index,amt) => {
    
    
    setCreditItemDisplaySawmill([
      ...creditItemDisplaySawmill.slice(0, index),
      ...creditItemDisplaySawmill.slice(index + 1, creditItemDisplaySawmill.length)
    ]);


    setCreditItemSawmill([
      ...creditItemSawmill.slice(0, index),
      ...creditItemSawmill.slice(index + 1, creditItemSawmill.length)
    ]);

    setAmount(prevCount => prevCount - amt)

  }

  const singleSale = (Itemid)=>{

  fetch('/getSingleSale',{
    method:"post",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),
      "Content-Type":"application/json"

    },
    body:JSON.stringify({
      id:Itemid
    })
}).then(res=>res.json())
.then(result=>{


  
setSingleSalesData(result.sales)

//console.log(singleSalesData[0].soldItem)
})

}
  
 const singleSaleSawmill = (Itemid)=>{

  fetch('/getSingleSawmillSale',{
    method:"post",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),
      "Content-Type":"application/json"

    },
    body:JSON.stringify({
      id:Itemid
    })
}).then(res=>res.json())
.then(result=>{


  
setSingleSalesDataSawmill(result.sales)

//console.log(singleSalesData[0].soldItem)
})

}

  const singleCredit = (Itemid)=>{

  fetch('/getSingleCredit',{
    method:"post",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),
      "Content-Type":"application/json"

    },
    body:JSON.stringify({
      id:Itemid
    })
}).then(res=>res.json())
.then(result=>{


  
setSingleSalesData(result.credits)

//console.log(singleSalesData[0].soldItem)
})

}
  
  const singleCreditSawmill = (Itemid)=>{

  fetch('/getSingleSawmillCredit',{
    method:"post",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),
      "Content-Type":"application/json"

    },
    body:JSON.stringify({
      id:Itemid
    })
}).then(res=>res.json())
.then(result=>{


  
  
  setSingleSalesDataSawmill(result.credits)

//console.log(singleSalesData[0].soldItem)
})

}
  

const PDFComponent = (da) => (

  <Document>
  <Page size="A4" style={styles.page}>
    <View style={styles.header}>
    <Image style={styles.logo} src="/logo.png"
 />

      <Text style={styles.address}>Green Gold Wood Works</Text>
      <Text style={{fontSize:10, marginBottom: 3}}>Tsirang</Text>
      <Text style={{fontSize:10,marginBottom: 3}}>TPN: GAB66324</Text>
      <Text style={{fontSize:10,marginBottom: 3}}>Contact:77428435 / 77413093</Text>

    </View>

    <View style={styles.invoiceDetails}>
      <View style={styles.itemList}>
        <View style={styles.itemRow}>
          <Text style={styles.label}>Item</Text>
          <Text style={styles.label}>Quantity</Text>
          <Text style={styles.label}>Amount</Text>
        </View>
        {singleSalesData.map((item, index) => (
          <View key={index} style={styles.itemRow}>
            <Text style={styles.itemDescription}>{item.product.name}</Text>
            <Text style={styles.itemDescription}>{item.qty}</Text>
            <Text style={styles.itemDescription}>{item.qty*item.product.rate}</Text>
          </View>
        ))}
      </View>
     
      <View style={styles.totalRow}>
        <Text style={styles.totalLabel}>Total:</Text>
        {/* <Text style={styles.totalValue}>{invoice.total}</Text> */}
      </View>
    </View>
  </Page>
</Document>
  
);



const PDFComponentSawmill = (da) => (

  <Document>
  <Page size="A4" style={styles.page}>
    <View style={styles.header}>
    <Image style={styles.logo} src="/logo.png"
 />

      <Text style={styles.address}>Green Gold Wood Works</Text>
      <Text style={{fontSize:10, marginBottom: 3}}>Tsirang</Text>
      <Text style={{fontSize:10,marginBottom: 3}}>TPN: GAB66324</Text>
      <Text style={{fontSize:10,marginBottom: 3}}>Contact:77428435 / 77413093</Text>
      <Text style={{fontSize:10,marginBottom: 3}}>Challan</Text>


    </View>

    <View>
    <Text style={{fontSize:10,marginBottom: 3}}>Name:{data.name}</Text>
    <Text style={{fontSize:10,marginBottom: 3}}>Address:{data.address}</Text>
    <Text style={{fontSize:10,marginBottom: 3}}>CID:{data.cid}</Text>
    <Text style={{fontSize:10,marginBottom: 3}}>Contact No:{data.phone}</Text>

    </View>
    <View style={styles.horizontalLine} />


    <View style={styles.invoiceDetails}>
      <View style={styles.itemList}>
        <View style={styles.itemRow}>
          <Text style={styles.label}>Item</Text>
          <Text style={styles.label}>Length</Text>
          <Text style={styles.label}>Breadth</Text>
          <Text style={styles.label}>Thickness</Text>
          <Text style={styles.label}>Quantity</Text>
          <Text style={styles.label}>Cft</Text>
        </View>
        <View style={styles.horizontalLine} />

        {singleSalesDataSawmill.map((item, index) => (
          <View key={index} style={styles.itemRow}>
            <Text style={styles.itemDescription}>{item.product}</Text>
            <Text style={styles.itemDescription}>{item.l}</Text>
            <Text style={styles.itemDescription}>{item.b}</Text>
            <Text style={styles.itemDescription}>{item.h}</Text>
            <Text style={styles.itemDescription}>{item.qty}</Text>
            <Text style={styles.itemDescription}>{ (item.l*item.b*item.h*item.qty/144).toFixed(2)}</Text>
          </View>
        ))}


<View style={styles.horizontalLine} />


      </View>
     
      <View style={styles.totalRow}>
        <Text style={styles.totalLabel}>Total:{sumsinglecft.toFixed(2)}</Text>
        {/* <Text style={styles.totalValue}>{invoice.total}</Text> */}
      </View>
      <View style={styles.horizontalLine} />
      <Text style={styles.labelfooter}>Drivers Name.</Text>
          <Text style={styles.labelfooter}>Vehicle No.</Text>
          <Text style={styles.labelfooter}>Phone No.</Text>
    </View>
  </Page>
</Document>
  
);

const SawmillBill = (da) => (

  <Document>
  <Page size="A4" style={styles.page}>
    <View style={styles.header}>
    <Image style={styles.logo} src="/logo.png"
 />

      <Text style={styles.address}>Green Gold Wood Works</Text>
      <Text style={{fontSize:10, marginBottom: 3}}>Tsirang</Text>
      <Text style={{fontSize:10,marginBottom: 3}}>TPN: GAB66324</Text>
      <Text style={{fontSize:10,marginBottom: 3}}>Contact:77428435 / 77413093</Text>
      <Text style={{fontSize:10,marginBottom: 3}}>Bill</Text>


    </View>

    <View>
    <Text style={{fontSize:10,marginBottom: 3}}>Name:{data.name}</Text>
    <Text style={{fontSize:10,marginBottom: 3}}>Address:{data.address}</Text>

    </View>
    <View style={styles.horizontalLine} />


    <View style={styles.invoiceDetails}>
      <View style={styles.itemList}>
        <View style={styles.itemRow}>
          <Text style={styles.label}>Item</Text>
          <Text style={styles.label}>Quantity</Text>
          <Text style={styles.label}>Price</Text>
          <Text style={styles.label}>Total</Text>
        </View>
        <View style={styles.horizontalLine} />

        {singleSalesDataSawmill.map((item, index) => (
          <View key={index} style={styles.itemRow}>
            <Text style={styles.itemDescription}>{item.product}</Text>
          
            <Text style={styles.itemDescription}>{(item.l*item.b*item.h*item.qty/144).toFixed(2)} Cft</Text>
            <Text style={styles.itemDescription}>{item.rate}</Text>
            <Text style={styles.itemDescription}>{ ((item.l*item.b*item.h*item.qty/144)*item.rate).toFixed(2)}</Text>
          </View>
        ))}


<View style={styles.horizontalLine} />


      </View>
     
      <View style={styles.totalRow}>
        <Text style={styles.totalLabel}>Grand Total:{sawmillsaletotal.toFixed(2)}</Text>
        {/* <Text style={styles.totalValue}>{invoice.total}</Text> */}
      </View>
      <View style={styles.horizontalLine} />

 
    </View>
  </Page>
</Document>
  
);


const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    padding: 30,
  },
  header: {
    marginBottom: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  address: {
    fontSize: 12,
    marginBottom: 10,
  },
  invoiceDetails: {
    marginBottom: 20,
  },
  label: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  labelfooter: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 8,
    marginTop: 8,

  },
  value: {
    fontSize: 12,
  },
  itemList: {
    marginTop: 2,
  },
  itemRow: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginBottom: 8,
    marginLeft:8
  },
  itemDescription: {
    width: '8%',
    textAlign:'center',
    fontSize:12

  },
  itemQuantity: {
    width: '15%',
    textAlign: 'left',
  },
  itemAmount: {
    width: '25%',
    textAlign: 'right',
  },
  totalRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
  totalLabel: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  totalValue: {
    fontSize: 14,
    marginLeft: 10,
  },
  logo: {
    width: 80, 
    height: 80, 
    marginBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',  },


  horizontalLine: {
    borderTop: 1,
    borderColor: 'black',
    marginVertical: 4,
    width: '100%',
  },
});

    


return(

    <div>


 <script src="https://cdn.jsdelivr.net/gh/alpinejs/alpine@v2.x.x/dist/alpine.min.js" defer></script>

 <div  className="flex h-screen bg-white">

 <div className=" "></div>

        <link rel="stylesheet" href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css" />
  





        <div className="flex-1 flex flex-col overflow-hidden ">

            


            <NavBar/>
   



            <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white">
  <div className="container mx-auto px-6 py-8">
    <h3 className="text-gray-400 text-3xl font-medium">Customer</h3>

    

<div className="flex flex-row ...">



{progress?
               
               <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
            :<>
         <div class="shadow-lg rounded-xl bg-gray-200 w-full md:w-64 p-6 bg-white dark:bg-gray-500 relative overflow-hidden">
    <p class="text-black text-xl">
        {data.name}
    </p>
    <div class="flex items-center my-4 text-blue-500 rounded justify-between">
        <span class="rounded-lg p-2 bg-white">
        <svg class="h-8 w-8 text-gray-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="9" cy="7" r="4" />  <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />  <path d="M16 3.13a4 4 0 0 1 0 7.75" />  <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" /></svg>        </span>
        <div class="flex flex-col w-full ml-2 items-start justify-evenly">
            <p class="text-black text-lg">
            {data.phone}
            </p>
            <p class="text-gray-700 text-sm">
                {data.address}
            </p>
        </div>
    </div>

</div>
   
            </>}
   




  
</div>
    
   
    
    <br></br>






      {/* SALES */}





<br/>

<div>Sales</div>

<div className="w-full h-10 pl-3 pr-2 bg-gray-100 border-gray-50 rounded-full  outline-none  border-none flex focus:outline-none justify-between items-center relative">
  <input type="text" name="search" id="search" placeholder="Search Sales (yyyy-mm-dd)" 
         className=" w-full bg-gray-100 border-none rounded-full    focus:outline-none focus:border-white active:outline-none"
         value= {search} onChange={(e)=>fetchPostSearch(e.target.value)}
         />
  <button type="submit" className="ml-1 outline-none border-none focus:border-none  active:outline-none">
    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
         viewBox="0 0 24 24" className="w-6 h-6">
      <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
    </svg>
  </button>
</div>

<br></br>



<div class="flex flex-row space-x-4 ">


<button type="button" class="py-4 space-x-4 px-6 flex justify-center items-center  bg-green-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg  "

onClick={()=>{setShow(!show);setShowSawmill(false)}}

>
<svg class="h-8 w-8 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
</svg>

    Furniture Sale
</button>
<button type="button" class="py-4 space-x-4 px-6 flex justify-center items-center  bg-green-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg  "

onClick={()=>{setShowSawmill(!showsawmillsale);setShow(false)}}

>
<svg class="h-8 w-8 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
</svg>

    Sawmill Sale
</button>


</div>

<br/>
{/* <div className="flex ">
      <div className="grid  grid-cols-2 md:grid-cols-6 space-x-1 space-y-1">

    
      
            { salesData? salesData.map(item=>{

              return(
                <div className="   bg-white w-full flex items-center p-2 rounded-xl shadow-md border transition duration-500 transform hover:-translate-y-1 hover:scale-111 ease-in-out">
                <div className="flex-grow p-5   ">
                  
                     <div className="font-semibold text-gray-700">
                    {item.name}
                  </div>
                  <div className="text-sm text-gray-500">
                    Quantity: {item.quantity}
                  </div>
                  <div className="text-sm text-gray-500">
                    Amount: {item.amount}
                  </div>
                </div>
              </div>

              )

            }):<>No Sales</>


            }
            
           
   
   
   
     
          
        
      
      </div>
     
    </div>
  */}


 
{show? 
<>


 

<h2>Add New Sale</h2>

<div class="flex md:flex-row flex-col">

  <div>
  
  
  
  
  
  <>


<div class="md:w-80  flex justify-center items-center">
    <div class="w-full md:p-4">
        <div class="card flex flex-col justify-center  bg-white rounded-lg shadow-2xl">
           
           



            
        <div className=" overflow-hidden sm:rounded-md">
          <div className="md:px-4 px-2 md:py-6  py-3 bg-white">
            <div className="grid gap-3 md:gap-6">
              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">Name</label>

                <Select 
            options={options}
            value={stockName}
            onChange={setStockName}
            />
              
              </div>
              
         
             
          
              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">quantity</label>
               <input type="number"  className="rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                
                value={quantity}
                onChange={(e)=>setQuantity(e.target.value)} 
                ></input>
              </div>
              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Amount</label>
               <input type="number"  className="rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                value={quantity*stockName.rate}
                ></input>
              </div>



          
              
            </div>
              
       
          </div>
          
          
        </div>


            <button
              className="bg-blue-400 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none m-3 "
              type="button"
              style={{ transition: "all .15s ease" }}
              onClick={() => {setAddSales(false); postSales() }}
            >
              Add Item
            </button>
  
           
        </div>
    </div>
</div>


   
         
           
          
            
   
  </>
  

  </div>








<br></br>
  
  <div class="grid  grid-cols-2 md:grid-cols-6 space-x-1 space-y-1">
  

  { soldItemDisplay? soldItemDisplay.map((item,index)=>{


return(
  <div className="   bg-white w-full flex items-center p-2 rounded-xl  shadow-md border transition duration-500 transform hover:-translate-y-1 hover:scale-111 ease-in-out"
  
  

       

  >
  <div className="flex-grow p-5   ">
    
       <div className="font-semibold text-gray-700">
      {item.product}
    </div>

    <div style={{cursor:"pointer"}} className="absolute top-1 right-1 text-red-600"
                  
                 onClick={()=>{if(window.confirm('Are you sure you want to delete?')) removeProduct(index,item.amt)}}
            
                  >
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            
                  ></path>  
                  </svg></div>
    <div className="text-sm text-gray-500 flex flex-row">
    <p>  Qty: {item.qty} </p> 

    </div>
    <div className="text-sm text-gray-500">
      Amount: {item.rate}
    </div>
  </div>








</div>

)

}):<>No Sales</>


}


  


<h1 class="p-2">Total Amount {amount}</h1>

<div className="py-1">
               <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Sold Date</label>
               <DatePicker className="rounded-lg w-full
        bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" selected={date} onChange={date => setDate(date)} />

            </div>
<div className="py-1">
               <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Amount</label>
               <input type="number"  className="rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                value={amount}
                onChange={(e)=>setAmount(e.target.value)}                 ></input>
              </div>
            </div>
  <br/>

{soldItem.length>0 ? <button type="button" class="py-2 px-4 flex justify-center items-center  bg-green-600 hover:bg-green-500 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "

onClick={()=>postBill()}

>
<svg class="h-8 w-8 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
</svg>

    Submit
</button>:<></>}



  </div>

  


</div>
<br/>


</>:<></>    }
 
{showsawmillsale? 
  <>


 

<h2>Add New Sawmill Sale</h2>

<div class="flex md:flex-row flex-col">

  <div>
  
  
  
  
  
  <>


<div class="md:w-80  flex justify-center items-center">
    <div class="w-full md:p-4">
        <div class="card flex flex-col justify-center  bg-white rounded-lg shadow-2xl">
           
           



            
        <div className=" overflow-hidden sm:rounded-md">
          <div className="md:px-4 px-2 md:py-6  py-3 bg-white">
            <div className="grid gap-3 md:gap-6">
              {/* <div className="col-span-6 sm:col-span-4">
                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">Name</label>

            <input type="text"  className="rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                
                value={name}
                onChange={(e)=>setName(e.target.value)} 
                ></input>
              
              </div>
               */}
           <div className="col-span-6 sm:col-span-4">
                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">Species</label>

                <Select 
            options={timberoptions}
            value={timberstockName}
            onChange={setTimberStockName}
            />
              
              </div>
           
           <div className="grid grid-cols-2 gap-4">

             <div className="mr-2">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Width</label>
               <input type="number"  className="rounded-lg w-32 p-2
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                value={b}
                onChange={(e)=>setB(e.target.value)}
                ></input>
              </div>
             <div className="ml-8">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Thickness</label>
               <input type="number"  className="rounded-lg w-32 p-2
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                value={h}
                onChange={(e)=>setH(e.target.value)}
                ></input>
              </div>
              </div>

              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Length</label>
               <input type="number"  className="rounded-lg w-full p-2
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                value={l}
                onChange={(e)=>setL(e.target.value)}
                ></input>
              </div>
          
              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Quantity</label>
               <input type="number"  className="rounded-lg w-full p-2
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                
                value={quantity}
                onChange={(e)=>setQuantity(e.target.value)} 
                ></input>
              </div>
              
              {/* <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Rate</label>
               <input type="number"  className="rounded-lg w-full p-2
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                
                value={rate}
                onChange={(e)=>setRate(e.target.value)} 
                ></input>
              </div>
               */}


          
              
            </div>
              
       
          </div>
          
          
        </div>


            <button
              className="bg-blue-400 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none m-3 "
              type="button"
              style={{ transition: "all .15s ease" }}
              onClick={() => { postSalesSawmill() }}
            >
              Add Sawmill Credit Item
            </button>
  
           
        </div>
    </div>
</div>


   
         
           
          
            
   
  </>
  

  </div>








<br></br>
  
  <div class="grid grid-cols-1 gap-4 md:grid-cols-1 xl:grid-cols-4">
  

  { soldItemDisplaySawmill? soldItemDisplaySawmill.map((item,index)=>{


              var cft= parseFloat(((item.l*item.b*item.h*item.qty)/144).toFixed(4))
              sumcft+= cft
              // sumamount+=item.amt
              // setAmount()

return(
  <div className="   bg-white w-full flex items-center p-2 rounded-xl  shadow-md border transition duration-500 transform hover:-translate-y-1 hover:scale-111 ease-in-out"
  
  

       

  >
  <div className="flex-grow p-5   ">
    
       <div className="font-semibold text-gray-700">
      {item.product}
    </div>
       <div className="font-semibold text-gray-700">
      Nu. {item.amt}
    </div>

    <div style={{cursor:"pointer"}} className="absolute top-1 right-1 text-red-600"
                  
                 onClick={()=>{if(window.confirm(`Are you sure you want to delete?`)) removeProductSawmill(index,item.amt)}}
            
                  >
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            
                  ></path>  
                  </svg></div>
   
    <div className="text-sm text-gray-500 flex flex-row">
    <p>  Breadth: {item.b} </p> 

    </div>
    <div className="text-sm text-gray-500 flex flex-row">
    <p>  Thickness: {item.h} </p> 

    </div>
    <div className="text-sm text-gray-500 flex flex-row">
    <p>  Lenght: {item.l} </p> 

    </div>
    <div className="text-sm text-gray-500 flex flex-row">
    <p>  Qty: {item.qty} </p> 

    </div>
    <div className="text-sm text-gray-500 flex flex-row">
    <p>  Cft: {cft} </p> 

    </div>
   
  </div>








</div>

)



}):<>No Sales</>

}




<div class="relative w-full p-4 overflow-hidden bg-white shadow-lg rounded-xl md:w-80 dark:bg-gray-800">
  
    <div class="flex items-center justify-between p-3 mb-2 bg-green-100 rounded">
        <span class="p-2 bg-white rounded-lg">
            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1520 1216q0-40-28-68l-208-208q-28-28-68-28-42 0-72 32 3 3 19 18.5t21.5 21.5 15 19 13 25.5 3.5 27.5q0 40-28 68t-68 28q-15 0-27.5-3.5t-25.5-13-19-15-21.5-21.5-18.5-19q-33 31-33 73 0 40 28 68l206 207q27 27 68 27 40 0 68-26l147-146q28-28 28-67zm-703-705q0-40-28-68l-206-207q-28-28-68-28-39 0-68 27l-147 146q-28 28-28 67 0 40 28 68l208 208q27 27 68 27 42 0 72-31-3-3-19-18.5t-21.5-21.5-15-19-13-25.5-3.5-27.5q0-40 28-68t68-28q15 0 27.5 3.5t25.5 13 19 15 21.5 21.5 18.5 19q33-31 33-73zm895 705q0 120-85 203l-147 146q-83 83-203 83-121 0-204-85l-206-207q-83-83-83-203 0-123 88-209l-88-88q-86 88-208 88-120 0-204-84l-208-208q-84-84-84-204t85-203l147-146q83-83 203-83 121 0 204 85l206 207q83 83 83 203 0 123-88 209l88 88q86-88 208-88 120 0 204 84l208 208q84 84 84 204z">
                </path>
            </svg>
        </span>
        <div class="flex items-center justify-between w-full ml-2">
            <p>
            Total Cft           </p>
            <p>
            {sumcft.toFixed(2)}              </p>
        </div>
    </div>
    <div class="flex items-center justify-between p-3 mb-2 bg-purple-100 rounded">
        <span class="p-2 bg-white rounded-lg">
            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1596 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1600q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528v-1024h-416q-40 0-68-28t-28-68v-416h-768v1536h1280zm-128-448v320h-1024v-192l192-192 128 128 384-384zm-832-192q-80 0-136-56t-56-136 56-136 136-56 136 56 56 136-56 136-136 56z">
                </path>
            </svg>
        </span>
        <div class="flex items-center justify-between w-full ml-2">
            <p>
            Total Amount 
            </p>
            <p>
            {amount}            </p>
        </div>
    </div>
    <div class="flex items-center justify-between p-3 mb-2 bg-yellow-100 rounded">
        <span class="p-2 bg-white rounded-lg">
            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M960 256q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm832 928v352q0 22-20 30-8 2-12 2-12 0-23-9l-93-93q-119 143-318.5 226.5t-429.5 83.5-429.5-83.5-318.5-226.5l-93 93q-9 9-23 9-4 0-12-2-20-8-20-30v-352q0-14 9-23t23-9h352q22 0 30 20 8 19-7 35l-100 100q67 91 189.5 153.5t271.5 82.5v-647h-192q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h192v-163q-58-34-93-92.5t-35-128.5q0-106 75-181t181-75 181 75 75 181q0 70-35 128.5t-93 92.5v163h192q26 0 45 19t19 45v128q0 26-19 45t-45 19h-192v647q149-20 271.5-82.5t189.5-153.5l-100-100q-15-16-7-35 8-20 30-20h352q14 0 23 9t9 23z">
                </path>
            </svg>
        </span>
        <div class="flex items-center justify-between w-full ml-2">
            <p>
            <div className="py-1">
               <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Sold Date</label>
               <DatePicker className="rounded-lg w-full
        bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" selected={date} onChange={date => setDate(date)} />

            </div>            </p>
           
        </div>
    </div>
    <div class="flex items-center justify-between p-3 mb-2 bg-yellow-100 rounded">
        <span class="p-2 bg-white rounded-lg">
            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M960 256q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm832 928v352q0 22-20 30-8 2-12 2-12 0-23-9l-93-93q-119 143-318.5 226.5t-429.5 83.5-429.5-83.5-318.5-226.5l-93 93q-9 9-23 9-4 0-12-2-20-8-20-30v-352q0-14 9-23t23-9h352q22 0 30 20 8 19-7 35l-100 100q67 91 189.5 153.5t271.5 82.5v-647h-192q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h192v-163q-58-34-93-92.5t-35-128.5q0-106 75-181t181-75 181 75 75 181q0 70-35 128.5t-93 92.5v163h192q26 0 45 19t19 45v128q0 26-19 45t-45 19h-192v647q149-20 271.5-82.5t189.5-153.5l-100-100q-15-16-7-35 8-20 30-20h352q14 0 23 9t9 23z">
                </path>
            </svg>
        </span>
        <div class="flex items-center justify-between w-full ml-2">
            <p>
            <div className="py-1">
               <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Amount</label>
               <input type="number"  className="rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                value={amount}
                onChange={(e)=>setAmount(e.target.value)}                 ></input>
              </div>
            </div>            </p>
          
        </div>
    
    </div>
    {soldItemSawmill.length>0 ? <button type="button" class="py-2 px-4 flex justify-center items-center  bg-green-600 hover:bg-green-500 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "

onClick={()=>{postSaleSawmill();sumcft=0}}

>
<svg class="h-8 w-8 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
</svg>

    Submit Sawmill Sale
</button>:<></>}

        
</div>

  
{/* <h1 class="p-2">Total Cft {sumcft}</h1> */}
{/* <h1 class="p-2">Total Amount {amount}</h1> */}

{/* <div className="py-1">
               <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Sold Date</label>
               <DatePicker className="rounded-lg w-full
        bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" selected={date} onChange={date => setDate(date)} />

            </div> */}
{/* <div className="py-1">
               <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Amount</label>
               <input type="number"  className="rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                value={amount}
                onChange={(e)=>setAmount(e.target.value)}                 ></input>
              </div>
            </div> */}

  <br/>



  </div>

  


</div>
<br/>


</>
:<></>    }






  
    





<div className="flex ">
      <div className="grid grid-cols-2 gap-12 md:grid-cols-1 xl:grid-cols-4 ">

      
      
            { salesData? salesData.map((item,index)=>{

              //console.log(item)
              let sum =0

              return(
                <div className="   bg-white w-fit  flex items-center p-2 cursor-pointer rounded-xl shadow-md border transition duration-500 transform hover:-translate-y-1 hover:scale-111 ease-in-out"
                
                >
                  <div style={{cursor:"pointer"}} className="absolute top-1 right-1 text-red-600"
                  
                  onClick={()=>{if(window.confirm('Are you sure you want to delete?')) deleteFurnitureSales(item._id)}}
             
                   >
                     <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
             
                   ></path>  
                   </svg></div>

                <div className="flex-grow p-5   "
                                onClick={()=>{singleSale(item._id); setAddStock(true)}}

                >
                  
                     <div className="font-semibold text-gray-700">
                   Total Amount {item.amount}
                   
                   {/* {item.soldItem.map(pro=>{
                               
                              sum+=pro.product.rate*pro.qty
                                
                             })} {sum}  */}

                             
                  </div>
                
                  <div className="text-sm text-gray-500">
                    Date: {item.date}
                  </div>

                </div>
              </div>

              )

            }):<>No Sales</>


            }
                
      
      </div>



     










      {
addStock? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto my-6  relative w-auto my-6 mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-5 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setAddStock(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          {/*body*/}
          <div className="relative p-6 flex-auto">
           
          
          <div className="flex ">
      <div className="grid grid-cols-2 gap-12 md:grid-cols-1 xl:grid-cols-4 ">

    
      
            { singleSalesData? singleSalesData.map((item,index)=>{



    // setData(item=>[...item, {product:item.product.name,rate:item.product.rate,qty:item.qty}])


             return(
 
                

                
              
                  <div className="   bg-white w-fit  flex items-center p-2 cursor-pointer rounded-xl shadow-md border transition duration-500 transform hover:-translate-y-1 hover:scale-111 ease-in-out"
                  
                  onClick={()=>{ setAddStock(true)}}
                  >
                    
                  <div className="flex-grow p-5   ">
                    
                       <div className="font-semibold text-gray-700">
                     Name: {item.product.name}  
                    </div>
                    <div className="text-sm text-gray-500">
                      Quantity: {item.qty}
                    </div>
                    <div className="text-sm text-gray-500">
                      Amount: {item.qty*item.product.rate}
                    </div>
  
                    {/* <div className="text-sm text-gray-500">
                      Amount: {item.soldItem}
                    </div> */}
                  </div>
                </div>
  
                )


            
            }):<>No Sales</>


            }

<button
              className="p-1  rounded-full bg-red-500 text-white text-3 "
              onClick={() => setAddStock(false)}
            ></button>
            
            {/* <ExportCSV   csvData={singleSalesData} fileName={fileName} /> */}

          </div>
          
         
        </div>
      </div>
    </div>
    </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>

  </>
)


:null

}




     
    </div>





<>Sawmill Sales</>

    <div className="grid grid-cols-2 gap-6 md:grid-cols-1 xl:grid-cols-4">


{salesDataSawmill?salesDataSawmill.map((item)=>{

let sum =0


return(
<div className="   bg-white w-fit flex items-center p-2 cursor-pointer rounded-xl shadow-md border transition duration-500 transform hover:-translate-y-1 hover:scale-111 ease-in-out"

>
<div className="flex-grow p-5   ">
<div style={{cursor:"pointer"}} className="absolute top-1 right-1 text-red-600"
                  
                  onClick={()=>{if(window.confirm('Are you sure you want to delete?')) deleteSales(item._id)}}
             
                   >
                     <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
             
                   ></path>  
                   </svg></div>

<div className="font-semibold text-gray-700" 
onClick={()=>{singleSaleSawmill(item._id); setShowSawmillSingle(true)}}

>
Total: Nu. {item.amount}

  {item.soldItem.map(pro=>{
     
    sum+=((pro.l*pro.b*pro.h*pro.qty)/144)
      
   })} {sum.toFixed(4)} Cft

   
</div>

<div className="text-sm text-gray-500">
Date: {item.date}
</div>

</div>
</div>

)


}):<></>}



</div>
 






<br></br>

<h1>Credits</h1>

    <div className="w-full h-10 pl-3 pr-2 bg-gray-100 border-gray-50 rounded-full  outline-none  border-none flex focus:outline-none justify-between items-center relative">
  <input type="text" name="search" id="search" placeholder="Search Credits (yyyy-mm-dd)" 
         className=" w-full bg-gray-100 border-none rounded-full    focus:outline-none focus:border-white active:outline-none"
         value= {searchCredit} onChange={(e)=>fetchCredit(e.target.value)}
         />
  <button type="submit" className="ml-1 outline-none border-none focus:border-none  active:outline-none">
    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
         viewBox="0 0 24 24" className="w-6 h-6">
      <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
    </svg>
  </button>
</div>

 
<br/>



{/* <div class="flex space-x-4">credits

  <ExportCSV   csvData={creditData} fileName={fileName} />



</div> */}



<br/>


<div class="flex flex-row space-x-4 ">
<button type="button" class=" py-4 space-x-4 px-6 flex justify-center items-center  bg-green-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "

onClick={()=>{setShowCredit(!showcredit);setShowCreditSawmill(false)}}
>
<svg class="h-8 w-8 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
</svg>

    Furniture Credit
</button>


<button type="button" class=" py-4 space-x-4 px-6 flex justify-center items-center  bg-green-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "

onClick={()=>{setShowCreditSawmill(!showcreditsawmill);setShowCredit(false)}}
>
<svg class="h-8 w-8 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
</svg>

    Sawmill Credit
</button>

</div>





<br/>

 
{showcredit? 
<>


 

<h2>Add New Furniture Credit</h2>

<div class="flex md:flex-row flex-col">

  <div>
  
  
  
  
  
  <>


<div class="md:w-80  flex justify-center items-center">
    <div class="w-full md:p-4">
        <div class="card flex flex-col justify-center  bg-white rounded-lg shadow-2xl">
           
           



            
        <div className=" overflow-hidden sm:rounded-md">
          <div className="md:px-4 px-2 md:py-6  py-3 bg-white">
            <div className="grid gap-3 md:gap-6">
              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">Name</label>

                <Select 
            options={options}
            value={stockName}
            onChange={setStockName}
            />
              
              </div>
              
         
             
          
              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">quantity</label>
               <input type="number"  className="rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                
                value={quantity}
                onChange={(e)=>setQuantity(e.target.value)} 
                ></input>
              </div>
              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Amount</label>
               <input type="number"  className="rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                value={quantity*stockName.rate}
                ></input>
              </div>



          
              
            </div>
              
       
          </div>
          
          
        </div>


            <button
              className="bg-blue-400 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none m-3 "
              type="button"
              style={{ transition: "all .15s ease" }}
              onClick={() => { postCredit() }}
            >
              Add Credit Item
            </button>
  
           
        </div>
    </div>
</div>


   
         
           
          
            
   
  </>
  

  </div>








<br></br>
  
  <div class="grid  grid-cols-2 md:grid-cols-6 space-x-1 space-y-1">
  

  { creditItemDisplay? creditItemDisplay.map((item,index)=>{


return(
  <div className="   bg-white w-full flex items-center p-2 rounded-xl  shadow-md border transition duration-500 transform hover:-translate-y-1 hover:scale-111 ease-in-out"
  
  

       

  >
  <div className="flex-grow p-5   ">
    
       <div className="font-semibold text-gray-700">
      {item.product}
    </div>

    <div style={{cursor:"pointer"}} className="absolute top-1 right-1 text-red-600"
                  
                 onClick={()=>{if(window.confirm('Are you sure you want to delete?')) removeProductCredit(index, item.amt)}}
            
                  >
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            
                  ></path>  
                  </svg></div>
    <div className="text-sm text-gray-500 flex flex-row">
    <p>  Qty: {item.qty} </p> 

    </div>
    <div className="text-sm text-gray-500">
      Amount: {item.rate}
    </div>
  </div>








</div>

)

}):<>No Sales</>


}


  



  <br/>

{creditItem.length>0 ?<>



  <div class="relative w-full p-4 overflow-hidden bg-white shadow-lg rounded-xl md:w-80 dark:bg-gray-800">
  
  <div class="flex items-center justify-between p-3 mb-2 bg-green-100 rounded">
      <span class="p-2 bg-white rounded-lg">
          <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
              <path d="M1520 1216q0-40-28-68l-208-208q-28-28-68-28-42 0-72 32 3 3 19 18.5t21.5 21.5 15 19 13 25.5 3.5 27.5q0 40-28 68t-68 28q-15 0-27.5-3.5t-25.5-13-19-15-21.5-21.5-18.5-19q-33 31-33 73 0 40 28 68l206 207q27 27 68 27 40 0 68-26l147-146q28-28 28-67zm-703-705q0-40-28-68l-206-207q-28-28-68-28-39 0-68 27l-147 146q-28 28-28 67 0 40 28 68l208 208q27 27 68 27 42 0 72-31-3-3-19-18.5t-21.5-21.5-15-19-13-25.5-3.5-27.5q0-40 28-68t68-28q15 0 27.5 3.5t25.5 13 19 15 21.5 21.5 18.5 19q33-31 33-73zm895 705q0 120-85 203l-147 146q-83 83-203 83-121 0-204-85l-206-207q-83-83-83-203 0-123 88-209l-88-88q-86 88-208 88-120 0-204-84l-208-208q-84-84-84-204t85-203l147-146q83-83 203-83 121 0 204 85l206 207q83 83 83 203 0 123-88 209l88 88q86-88 208-88 120 0 204 84l208 208q84 84 84 204z">
              </path>
          </svg>
      </span>
      <div class="flex items-center justify-between w-full ml-2">
          <p>
          Total Cft           </p>
          <p>
          {sumcft.toFixed(2)}              </p>
      </div>
  </div>
  <div class="flex items-center justify-between p-3 mb-2 bg-purple-100 rounded">
      <span class="p-2 bg-white rounded-lg">
          <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
              <path d="M1596 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1600q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528v-1024h-416q-40 0-68-28t-28-68v-416h-768v1536h1280zm-128-448v320h-1024v-192l192-192 128 128 384-384zm-832-192q-80 0-136-56t-56-136 56-136 136-56 136 56 56 136-56 136-136 56z">
              </path>
          </svg>
      </span>
      <div class="flex items-center justify-between w-full ml-2">
          <p>
          Total Amount 
          </p>
          <p>
          {amount}            </p>
      </div>
  </div>
  <div class="flex items-center justify-between p-3 mb-2 bg-yellow-100 rounded">
      <span class="p-2 bg-white rounded-lg">
          <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
              <path d="M960 256q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm832 928v352q0 22-20 30-8 2-12 2-12 0-23-9l-93-93q-119 143-318.5 226.5t-429.5 83.5-429.5-83.5-318.5-226.5l-93 93q-9 9-23 9-4 0-12-2-20-8-20-30v-352q0-14 9-23t23-9h352q22 0 30 20 8 19-7 35l-100 100q67 91 189.5 153.5t271.5 82.5v-647h-192q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h192v-163q-58-34-93-92.5t-35-128.5q0-106 75-181t181-75 181 75 75 181q0 70-35 128.5t-93 92.5v163h192q26 0 45 19t19 45v128q0 26-19 45t-45 19h-192v647q149-20 271.5-82.5t189.5-153.5l-100-100q-15-16-7-35 8-20 30-20h352q14 0 23 9t9 23z">
              </path>
          </svg>
      </span>
      <div class="flex items-center justify-between w-full ml-2">
          <p>
          <div className="py-1">
             <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Sold Date</label>
             <DatePicker className="rounded-lg w-full
      bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" selected={date} onChange={date => setDate(date)} />

          </div>            </p>
         
      </div>
  </div>
  <div class="flex items-center justify-between p-3 mb-2 bg-yellow-100 rounded">
      <span class="p-2 bg-white rounded-lg">
          <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
              <path d="M960 256q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm832 928v352q0 22-20 30-8 2-12 2-12 0-23-9l-93-93q-119 143-318.5 226.5t-429.5 83.5-429.5-83.5-318.5-226.5l-93 93q-9 9-23 9-4 0-12-2-20-8-20-30v-352q0-14 9-23t23-9h352q22 0 30 20 8 19-7 35l-100 100q67 91 189.5 153.5t271.5 82.5v-647h-192q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h192v-163q-58-34-93-92.5t-35-128.5q0-106 75-181t181-75 181 75 75 181q0 70-35 128.5t-93 92.5v163h192q26 0 45 19t19 45v128q0 26-19 45t-45 19h-192v647q149-20 271.5-82.5t189.5-153.5l-100-100q-15-16-7-35 8-20 30-20h352q14 0 23 9t9 23z">
              </path>
          </svg>
      </span>
      <div class="flex items-center justify-between w-full ml-2">
          <p>
          <div className="py-1">
             <div className="col-span-6 sm:col-span-4">
              <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Amount</label>
             <input type="number"  className="rounded-lg w-full
              bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
              value={amount}
              onChange={(e)=>setAmount(e.target.value)}                 ></input>
            </div>
          </div>            </p>
        
      </div>
  
  </div>
  <button type="button" class="py-2 px-4 flex justify-center items-center  bg-green-600 hover:bg-green-500 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "

onClick={()=>postCreditFinal()}

>
<svg class="h-8 w-8 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
</svg>

    Submit Credit
</button>

      
</div>

{/* <button type="button" class="py-2 px-4 flex justify-center items-center  bg-green-600 hover:bg-green-500 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "

onClick={()=>postCreditFinal()}

>
<svg class="h-8 w-8 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
</svg>

    Submit Credit
</button> */}

</>






:<></>}



  </div>

  


</div>
<br/>


</>:<></>    }






{showcreditsawmill? 
<>


 

<h2>Add New Sawmill Credit</h2>

<div class="flex md:flex-row flex-col">

  <div>
  
  
  
  
  
  <>


<div class="md:w-80  flex justify-center items-center">
    <div class="w-full md:p-4">
        <div class="card flex flex-col justify-center  bg-white rounded-lg shadow-2xl">
           
           

      
        <div className=" overflow-hidden sm:rounded-md">
          <div className="md:px-4 px-2 md:py-6  py-3 bg-white">
            <div className="grid gap-3 md:gap-6">
              {/* <div className="col-span-6 sm:col-span-4">
                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">Name</label>

            <input type="text"  className="rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                
                value={name}
                onChange={(e)=>setName(e.target.value)} 
                ></input>
              
              </div>
               */}
           <div className="col-span-6 sm:col-span-4">
                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">Species</label>

                <Select 
            options={timberoptions}
            value={timberstockName}
            onChange={setTimberStockName}
            />
              
              </div>
           
           <div className="grid grid-cols-2 gap-4">

             <div className="mr-2">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Width</label>
               <input type="number"  className="rounded-lg w-32 p-2
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                value={b}
                onChange={(e)=>setB(e.target.value)}
                ></input>
              </div>
             <div className="ml-8">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Thickness</label>
               <input type="number"  className="rounded-lg w-32 p-2
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                value={h}
                onChange={(e)=>setH(e.target.value)}
                ></input>
              </div>
              </div>

              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Length</label>
               <input type="number"  className="rounded-lg w-full p-2
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                value={l}
                onChange={(e)=>setL(e.target.value)}
                ></input>
              </div>
          
              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Quantity</label>
               <input type="number"  className="rounded-lg w-full p-2
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                
                value={quantity}
                onChange={(e)=>setQuantity(e.target.value)} 
                ></input>
              </div>
              
              {/* <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Rate</label>
               <input type="number"  className="rounded-lg w-full p-2
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                
                value={rate}
                onChange={(e)=>setRate(e.target.value)} 
                ></input>
              </div>
               */}


          
              
            </div>
              
       
          </div>
          
          
        </div>


{/*             
        <div className=" overflow-hidden sm:rounded-md">
          <div className="md:px-4 px-2 md:py-6  py-3 bg-white">
            <div className="grid gap-3 md:gap-6">
          
<div className="col-span-6 sm:col-span-4">
                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">Name</label>

                <Select 
            options={timberoptions}
            value={timberstockName}
            onChange={setTimberStockName}
            />
              
              </div>
         
             <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Width</label>
               <input type="number"  className="rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                value={b}
                onChange={(e)=>setB(e.target.value)}
                ></input>
              </div>
             <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Thickness</label>
               <input type="number"  className="rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                value={h}
                onChange={(e)=>setH(e.target.value)}
                ></input>
              </div>

              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Length</label>
               <input type="number"  className="rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                value={l}
                onChange={(e)=>setL(e.target.value)}
                ></input>
              </div>
          
              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">quantity</label>
               <input type="number"  className="rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                
                value={quantity}
                onChange={(e)=>setQuantity(e.target.value)} 
                ></input>
              </div>
              


          
              
            </div>
              
       
          </div>
          
          
        </div> */}


            <button
              className="bg-blue-400 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none m-3 "
              type="button"
              style={{ transition: "all .15s ease" }}
              onClick={() => { postCreditSawmill() }}
            >
              Add Sawmill Credit Item
            </button>
  
           
        </div>
    </div>
</div>


   
         
           
          
            
   
  </>
  

  </div>








<br></br>
  
  <div class="grid  grid-cols-2 md:grid-cols-6 space-x-1 space-y-1">
  

  { creditItemDisplaySawmill? creditItemDisplaySawmill.map((item,index)=>{


          var cft= parseFloat(((item.l*item.b*item.h*item.qty)/144).toFixed(4))
              sumcft+= cft
              // console.log("SALE",sawmillsaletotal)
              // setSawmillsaleTotal((prevSum) => prevSum + (cft*item.rate));

return(

  <>
  <div className="   bg-white w-full flex items-center p-2 rounded-xl  shadow-md border transition duration-500 transform hover:-translate-y-1 hover:scale-111 ease-in-out"
  
  

       

  >
  <div className="flex-grow p-5   ">
    
       <div className="font-semibold text-gray-700">
      {item.product}
    </div>

    <div style={{cursor:"pointer"}} className="absolute top-1 right-1 text-red-600"
                  
                 onClick={()=>{if(window.confirm(`Are you sure you want to delete?`)) removeProductCreditSawmill(index,item.amt)}}
            
                  >
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            
                  ></path>  
                  </svg></div>
   
    <div className="text-sm text-gray-500 flex flex-row">
    <p>  Breadth: {item.b} </p> 

    </div>
    <div className="text-sm text-gray-500 flex flex-row">
    <p>  Thickness: {item.h} </p> 

    </div>
    <div className="text-sm text-gray-500 flex flex-row">
    <p>  Lenght: {item.l} </p> 

    </div>
    <div className="text-sm text-gray-500 flex flex-row">
    <p>  Qty: {item.qty} </p> 

    </div>

    <div className="text-sm text-gray-500 flex flex-row">
    <p>  Cft: {cft} </p> 

    </div>
   
  </div>










</div>


</>
)

}):<>No Sales</>


}


  


<div class="relative w-full p-4 overflow-hidden bg-white shadow-lg rounded-xl md:w-80 dark:bg-gray-800">
  
    <div class="flex items-center justify-between p-3 mb-2 bg-green-100 rounded">
        <span class="p-2 bg-white rounded-lg">
            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1520 1216q0-40-28-68l-208-208q-28-28-68-28-42 0-72 32 3 3 19 18.5t21.5 21.5 15 19 13 25.5 3.5 27.5q0 40-28 68t-68 28q-15 0-27.5-3.5t-25.5-13-19-15-21.5-21.5-18.5-19q-33 31-33 73 0 40 28 68l206 207q27 27 68 27 40 0 68-26l147-146q28-28 28-67zm-703-705q0-40-28-68l-206-207q-28-28-68-28-39 0-68 27l-147 146q-28 28-28 67 0 40 28 68l208 208q27 27 68 27 42 0 72-31-3-3-19-18.5t-21.5-21.5-15-19-13-25.5-3.5-27.5q0-40 28-68t68-28q15 0 27.5 3.5t25.5 13 19 15 21.5 21.5 18.5 19q33-31 33-73zm895 705q0 120-85 203l-147 146q-83 83-203 83-121 0-204-85l-206-207q-83-83-83-203 0-123 88-209l-88-88q-86 88-208 88-120 0-204-84l-208-208q-84-84-84-204t85-203l147-146q83-83 203-83 121 0 204 85l206 207q83 83 83 203 0 123-88 209l88 88q86-88 208-88 120 0 204 84l208 208q84 84 84 204z">
                </path>
            </svg>
        </span>
        <div class="flex items-center justify-between w-full ml-2">
            <p>
            Total Cft           </p>
            <p>
            {sumcft.toFixed(2)}              </p>
        </div>
    </div>
    <div class="flex items-center justify-between p-3 mb-2 bg-purple-100 rounded">
        <span class="p-2 bg-white rounded-lg">
            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1596 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1600q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528v-1024h-416q-40 0-68-28t-28-68v-416h-768v1536h1280zm-128-448v320h-1024v-192l192-192 128 128 384-384zm-832-192q-80 0-136-56t-56-136 56-136 136-56 136 56 56 136-56 136-136 56z">
                </path>
            </svg>
        </span>
        <div class="flex items-center justify-between w-full ml-2">
            <p>
            Total Amount 
            </p>
            <p>
            {amount}            </p>
        </div>
    </div>
    <div class="flex items-center justify-between p-3 mb-2 bg-yellow-100 rounded">
        <span class="p-2 bg-white rounded-lg">
            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M960 256q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm832 928v352q0 22-20 30-8 2-12 2-12 0-23-9l-93-93q-119 143-318.5 226.5t-429.5 83.5-429.5-83.5-318.5-226.5l-93 93q-9 9-23 9-4 0-12-2-20-8-20-30v-352q0-14 9-23t23-9h352q22 0 30 20 8 19-7 35l-100 100q67 91 189.5 153.5t271.5 82.5v-647h-192q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h192v-163q-58-34-93-92.5t-35-128.5q0-106 75-181t181-75 181 75 75 181q0 70-35 128.5t-93 92.5v163h192q26 0 45 19t19 45v128q0 26-19 45t-45 19h-192v647q149-20 271.5-82.5t189.5-153.5l-100-100q-15-16-7-35 8-20 30-20h352q14 0 23 9t9 23z">
                </path>
            </svg>
        </span>
        <div class="flex items-center justify-between w-full ml-2">
            <p>
            <div className="py-1">
               <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Sold Date</label>
               <DatePicker className="rounded-lg w-full
        bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" selected={date} onChange={date => setDate(date)} />

            </div>            </p>
           
        </div>
    </div>
    <div class="flex items-center justify-between p-3 mb-2 bg-yellow-100 rounded">
        <span class="p-2 bg-white rounded-lg">
            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M960 256q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm832 928v352q0 22-20 30-8 2-12 2-12 0-23-9l-93-93q-119 143-318.5 226.5t-429.5 83.5-429.5-83.5-318.5-226.5l-93 93q-9 9-23 9-4 0-12-2-20-8-20-30v-352q0-14 9-23t23-9h352q22 0 30 20 8 19-7 35l-100 100q67 91 189.5 153.5t271.5 82.5v-647h-192q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h192v-163q-58-34-93-92.5t-35-128.5q0-106 75-181t181-75 181 75 75 181q0 70-35 128.5t-93 92.5v163h192q26 0 45 19t19 45v128q0 26-19 45t-45 19h-192v647q149-20 271.5-82.5t189.5-153.5l-100-100q-15-16-7-35 8-20 30-20h352q14 0 23 9t9 23z">
                </path>
            </svg>
        </span>
        <div class="flex items-center justify-between w-full ml-2">
            <p>
            <div className="py-1">
               <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Amount</label>
               <input type="number"  className="rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                value={amount}
                onChange={(e)=>setAmount(e.target.value)}                 ></input>
              </div>
            </div>            </p>
          
        </div>
    
    </div>
    {creditItemSawmill.length>0 ? <button type="button" class="py-2 px-4 flex justify-center items-center  bg-green-600 hover:bg-green-500 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "

onClick={()=>{postCreditSawmillFinal();sumcft=0}}

>
<svg class="h-8 w-8 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
</svg>

    Submit Sawmill Credit 
</button>:<></>}

        
</div>

  





{/* 

<h1 class="p-2">Total {sumcft}</h1>


  <br/>

{creditItemSawmill.length>0 ? <button type="button" class="py-2 px-4 flex justify-center items-center  bg-green-600 hover:bg-green-500 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "

onClick={()=>{postCreditSawmillFinal();sumcft=0}}

>
<svg class="h-8 w-8 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
</svg>

    Submit Sawmill Credit 
</button>:<></>} */}



  </div>

  


</div>
<br/>


</>:<></>    }

<br/>





<div className="flex ">
      <div className="grid  grid-cols-2 md:grid-cols-6 space-x-1 space-y-1">

    
      
            { creditData? creditData.map((item,index)=>{

              //console.log(item)
              let sum =0

              return(
                <div className="   bg-white w-full flex items-center p-2 cursor-pointer rounded-xl shadow-md border transition duration-500 transform hover:-translate-y-1 hover:scale-111 ease-in-out"
                
                >
               <div style={{cursor:"pointer"}} className="absolute top-1 right-1 text-red-600"
                  
                  onClick={()=>{if(window.confirm('Are you sure you want to delete?')) deleteCredit(item._id)}}
             
                   >
                     <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
             
                   ></path>  
                   </svg></div>



                    <div className="flex-grow p-5   "
                    
                    onClick={()=>{singleCredit(item._id); setAddStock(true)}}

                    >
                  
                     <div className="font-semibold text-gray-700">
                   Total Amount {item.amount}
{/*                    
                    {item.creditItem.map(pro=>{
                               
                              sum+=pro.product.rate*pro.qty
                                
                             })} {sum}  */}

                             
                  </div>
                  
                
                  <div className="text-sm text-gray-500">
                    Date: {item.date}
                  </div>

                </div>
              </div>

              )

            }):<>No Sales</>


            }
            
           
   

{/* PRINT FURNITURE SALE */}
            {
addStock? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto my-6  relative w-auto my-6 mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-5 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setAddStock(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          
          
         

          
          {/*body*/}
          <div className="relative p-6 flex-auto">
           
          
          <div className="flex ">
      <div className="grid  grid-cols-2 md:grid-cols-3 space-x-1 space-y-1">

    
      
            { singleSalesData? singleSalesData.map((item,index)=>{



    // setData(item=>[...item, {product:item.product.name,rate:item.product.rate,qty:item.qty}])


             return(
 
                

                
              
                  <div className="   bg-white w-full flex items-center p-2 cursor-pointer rounded-xl shadow-md border transition duration-500 transform hover:-translate-y-1 hover:scale-111 ease-in-out"
                  
                  onClick={()=>{ setAddStock(true)}}
                  >
                    
                  <div className="flex-grow p-5   ">
                    
                       <div className="font-semibold text-gray-700">
                     Name: {item.product.name}  
                    </div>
                    <div className="text-sm text-gray-500">
                      Quantity: {item.qty}
                    </div>
                    <div className="text-sm text-gray-500">
                      Amount: {item.qty*item.product.rate}
                    </div>
  
                    {/* <div className="text-sm text-gray-500">
                      Amount: {item.soldItem}
                    </div> */}
                  </div>
                </div>
  
                )


            
            }):<>No Sales</>


            }
            
            {/* <ExportCSV   csvData={singleSalesData} fileName={fileName} /> */}

          </div>
          <div >
          <PDFDownloadLink document={<PDFComponent test={"TESt sf"}/>} fileName="bill.pdf">
      {({ blob, url, loading, error }) =>
        loading ? 'Loading document...' : <><svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />  <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />  <rect x="7" y="13" width="10" height="8" rx="2" /></svg>
        Print</>
      }
    </PDFDownloadLink>
            
            </div>
         
        </div>
        
      </div>
    </div>
    </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>

  </>
)


:null

}
   
            {
showsawmillsingle? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto my-6  relative w-auto my-6 mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-5 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setShowSawmillSingle(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          {/*body*/}
          <div className="relative p-6 flex-auto">
           
          
          <div className="flex ">
      <div className="grid  grid-cols-2 md:grid-cols-3 space-x-1 space-y-1">

    
      
            { singleSalesDataSawmill? singleSalesDataSawmill.map((item,index)=>{



    // setData(item=>[...item, {product:item.product.name,rate:item.product.rate,qty:item.qty}])
            var calsumsinglecft=(item.l*item.b*item.h*item.qty/144)
            sumsinglecft=calsumsinglecft+sumsinglecft
            // sawmillsaletotal+=(calsumsinglecft*item.rate)
            // setSawmillsaleTotal((prevSum) => prevSum + (sumsinglecft*item.rate));
            sawmillsaletotal += (calsumsinglecft*item.rate)

            // console.log("SINGLE SUM",sumsinglecft)
              // var  sum=0;
             return(
 
                

                
              
                  <div className="   bg-white w-full flex items-center p-2 cursor-pointer rounded-xl shadow-md border transition duration-500 transform hover:-translate-y-1 hover:scale-111 ease-in-out"
                  
                  onClick={()=>{ setAddStock(true)}}
                  >
                    
                  <div className="flex-grow p-5   ">
                    
                       <div className="font-semibold text-gray-700">
                     Name: {item.product}  
                    </div>
                    <div className="text-sm text-gray-500">
                      Length: {item.l}
                    </div>
                    <div className="text-sm text-gray-500">
                      Breath: {item.b}
                    </div>
                    <div className="text-sm text-gray-500">
                      Height: {item.h}
                    </div>
                    <div className="text-sm text-gray-500">
                      Quantity: {item.qty}
                    </div>
                    <div className="text-sm text-gray-500">
                      CFT: { (item.l*item.b*item.h*item.qty/144).toFixed(4)}
                    </div>
                  
                    {/* <div className="text-sm text-gray-500">
                      Amount: {item.soldItem}
                    </div> */}
                    
                  </div>
                </div>
  
                )


            
            }):<>No Sales</>


            }
            
            {/* <ExportCSV   csvData={singleSalesData} fileName={fileName} /> */}
            <div className="flex rounded-full items-start  p-5 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3  mr-4"
              onClick={() => setShowSawmillSingle(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
         
<div >
          <PDFDownloadLink document={<PDFComponentSawmill test={"TESt sf"}/>} fileName="challan.pdf">
      {({ blob, url, loading, error }) =>
        loading ? 'Loading challan...' : <><svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />  <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />  <rect x="7" y="13" width="10" height="8" rx="2" /></svg>
        Print Challan</>
      }
    </PDFDownloadLink>
            
            </div>
<div className="ml-4" >
          <PDFDownloadLink document={<SawmillBill test={"TESt sf"}/>} fileName="bill.pdf">
      {({ blob, url, loading, error }) =>
        loading ? 'Loading bill...' : <><svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />  <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />  <rect x="7" y="13" width="10" height="8" rx="2" /></svg>
        Print Bill</>
      }
    </PDFDownloadLink>
            
            </div>
          </div>

          </div>
       
         
        </div>
      </div>
    </div>
    </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>

  </>
)


:null

}
   
   
     
          
        
      
      </div>


    <>Sawmill Credits</>



    <div className="grid  grid-cols-2 md:grid-cols-6 space-x-1 space-y-1">


{creditDataSawmill?creditDataSawmill.map((item)=>{

let sum =0


return(
<div className="   bg-white w-full flex items-center p-2 cursor-pointer rounded-xl shadow-md border transition duration-500 transform hover:-translate-y-1 hover:scale-111 ease-in-out"

>

<div style={{cursor:"pointer"}} className="absolute top-1 right-1 text-red-600"
                  
                  onClick={()=>{if(window.confirm('Are you sure you want to delete?')) deleteSawmillCredit(item._id)}}
             
                   >
                     <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
             
                   ></path>  
                   </svg></div>

<div className="flex-grow p-5   "
onClick={()=>{singleCreditSawmill(item._id); setShowSawmillSingle(true)}}

>

<div className="font-semibold text-gray-700">
Total Amount {item.amount}

{/* {item.creditItem.map(pro=>{
     
    sum+=((pro.l*pro.b*pro.h*pro.qty)/144)
      
   })} {sum.toFixed(4)}  */}

   
</div>

<div className="text-sm text-gray-500">
Date: {item.date}
</div>

</div>
</div>

)


}):<></>}



</div>







     
    </div>
 





<br></br>



  

    <h2>Total Credit: Nu.  {TotalCredit}</h2>     

    <h2> Amount Paid : Nu. { data.paid ? data.paid : 0 }</h2> 
    <h2> Payable Amount : Nu. { TotalCredit- data.paid }</h2>
    <button class="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-red-600 rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200" type="submit"
        
        onClick={()=>{if(window.confirm('Are you sure you want to clear?')) clearPaid()}}                           

        
        >
            Clear Paid
        </button>




    <br/>


    <div>
      
      

   

    <form class="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center">
    <div class=" relative ">
        <input type="number" id="&quot;form-subscribe-Subscribe" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Paid Amount"
      value= {paid}
      onChange = {(e)=>setPaid(e.target.value)}
        />
        </div>
        <button class="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200" type="submit"
        
        onClick={()=>{if(window.confirm('Are you it is paid?')) updatePaid()}}                           

        
        >
            Paid
        </button>
      
    </form>

       
        
    </div>



    












 
   
  </div>
</main>
</div>

    </div>
  </div>  
);


  
}



export default Customer