import React, {useContext, useRef, useEffect, useState} from 'react'

import {Link, useHistory} from 'react-router-dom'
import {UserContex} from '../App'
import NavBar from './nav'
// import { Carousel } from 'react-responsive-carousel';
import DatePicker from 'react-datepicker'


import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

require('react-datepicker/dist/react-datepicker.css')

const Orders=()=>{
  const history = useHistory()
  const {state, dispatch}= useContext(UserContex)

  const  [data, setData]= useState([])

  const [search,setSearch] = useState("")

  const [sort,setSort]= useState(false)


  const [showOrders,setShowOrders]= useState("Order Placed")
  const [showDispatched,setShowDispatched]= useState(false)
  const [NoPrescription,setNoPrescription]= useState(false)

  const[name,setName]= useState("")
  const[email,setEmail]= useState("")
  const[phone,setPhone]= useState("")
  const[description,setDescription]= useState("")
  const[address,setAddress]= useState("")
  const [date,setDate] = useState(new Date())
  const [showCalender,setShowCalender] = useState(false)
  const[image, setImage] = useState("")
  const[advance, setAdvance] = useState()
  const [addOrder,setAddOrder]= useState(false)

    
  const[newOrders,setNewOrders]= useState()
  const[verifiedOrders,setVerifiedOrders]= useState()
  const[dispatchedOrders,setDispatchedOrders]= useState()
  const [total,setTotal] = useState()  
  const[selected,setSelected]= useState("")
  const [showStaus, setShowStatus] = useState("")


  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];

var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const d = new Date();


  useEffect(  ()=>{



    fetch('/getOrders',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.orders)

      var placed = 0
      var verified = 0
      var dispatched = 0
      result.orders.map(order=>{

        if(order.status==="Order Placed") placed+=1
        if(order.status==="Verified") verified+=1
        if(order.status==="Dispatched") dispatched+=1


      })

      setNewOrders(placed)
      setVerifiedOrders(verified)
      setDispatchedOrders(dispatched)
  })
  
  

  },[])



  const postStockUpdate = (id,newStatus)=>{

    fetch('/updateOrderStatus',{
  
      method:"put",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")
  
      },
      body: JSON.stringify({
      id:id,
      newStatus:newStatus
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }
  
      else{
         history.go('/orders')

      }
    })
    .catch(err=>{
      console.log(err)
    })
  
  }
  


  const postOrder = ()=>{

    fetch('/addOrder',{

      method:"post",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
        name:name,
        email:email,
        phone:phone,
        description:description,
        address:address,
        advance:advance,
        date:date
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        window.location.reload(false);
      }
    })
    .catch(err=>{
      console.log(err)
    })
    history.push('/orders')

  }

// const singleStock = (Itemid)=>{

//   console.log(Itemid)
// fetch('/getSingleStock',{
//   method:"post",
//   headers:{
//     Authorization: "Bearer " +localStorage.getItem("jwt"),
//     "Content-Type":"application/json"

//   },
//   body:JSON.stringify({
//     id:Itemid
//   })
// }).then(res=>res.json())
// .then(result=>{


// // setDataEdit(result.stock)
// console.log(result)
// setidEdit(result.stock._id)
// setNameEdit(result.stock.name)
// setQuantityEdit(result.stock.description)
// setRateEdit(result.stock.price)
// setUrl(result.stock.pic)
        
// })

// }











const fetchPost=(query)=>{


  setSearch(query)

  fetch('/search-orders',{

    method:"post",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),

      "Content-Type":"application/json"

    },
    body:JSON.stringify({
      query
    })

  }).then(res=> res.json())
    .then(results=>{
      
      setData(results.orders)
    })


}




const  deleteorder = async (id)=>{

  const order = await fetch('/deleteOrder',{

    method:'delete',
    headers:{
      Authorization:"Bearer "+localStorage.getItem("jwt"),
      "Content-Type":"application/json"
    },
    body:JSON.stringify({
      id
    })


  })

  if (order) history.go('/orders')


  console.log(order)

}





const searchdate=(query)=>{

  setSelected(query)


  fetch('/searchdateorders',{

    method:"post",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),

      "Content-Type":"application/json"

    },
    body:JSON.stringify({
     date: query
    })

  }).then(res=> res.json())
    .then(results=>{
      
      setData(results.order)

      
    })


}





return(

    <div>



       
 <script src="https://cdn.jsdelivr.net/gh/alpinejs/alpine@v2.x.x/dist/alpine.min.js" defer></script>

<div  className="flex h-screen bg-white">

<div className=" "></div>


       <link rel="stylesheet" href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css" />

        <div className="flex-1 flex flex-col overflow-hidden ">

          


        <NavBar/>



            <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-50">


            <div>


<script src="https://cdn.jsdelivr.net/gh/alpinejs/alpine@v2.x.x/dist/alpine.min.js" defer></script>

<div  className="flex h-screen bg-white">

<div className=" "></div>

       <link rel="stylesheet" href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css" />
 






       <div className="flex-1 flex flex-col overflow-hidden ">

           


           <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white">
 <div className="container mx-auto px-6 py-8">
   <h3 className="text-gray-400 text-3xl font-medium">Orders</h3>


<div className="flex md:flex-row flex-col ...">


<div class="shadow-lg rounded-xl bg-gray-200 w-full md:w-64 p-6 bg-white dark:bg-gray-500 relative overflow-hidden">
   <p class="text-black text-xl">
       Orders
   </p>
   <div class="flex items-center my-4 text-blue-500 rounded justify-between">
       <span class="rounded-lg p-2 bg-white">
       <svg class="h-8 w-8 text-gray-400"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M14.5 10c-.83 0-1.5-.67-1.5-1.5v-5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5z" />  <path d="M20.5 10H19V8.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />  <path d="M9.5 14c.83 0 1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5S8 21.33 8 20.5v-5c0-.83.67-1.5 1.5-1.5z" />  <path d="M3.5 14H5v1.5c0 .83-.67 1.5-1.5 1.5S2 16.33 2 15.5 2.67 14 3.5 14z" />  <path d="M14 14.5c0-.83.67-1.5 1.5-1.5h5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-5c-.83 0-1.5-.67-1.5-1.5z" />  <path d="M15.5 19H14v1.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z" />  <path d="M10 9.5C10 8.67 9.33 8 8.5 8h-5C2.67 8 2 8.67 2 9.5S2.67 11 3.5 11h5c.83 0 1.5-.67 1.5-1.5z" />  <path d="M8.5 5H10V3.5C10 2.67 9.33 2 8.5 2S7 2.67 7 3.5 7.67 5 8.5 5z" /></svg>        </span>
       <div class="flex flex-col w-full ml-2 items-start justify-evenly">
           <p class="text-black text-lg">
               {data.length ? data.length:0}
           </p>
           <p class="text-gray-700 text-sm">
               Total Orders
           </p>
       </div>
   </div>
  
</div>




<div class="relative w-full  md:ml-4 md:mt-0 mt-4 p-4 overflow-hidden bg-white shadow-lg rounded-xl md:w-60 dark:bg-gray-800">
  
  <div class="grid grid-cols-3 gap-4">
      <div class="flex flex-col items-center cursor-pointer"
      
      onClick={()=>{setShowOrders("Order Placed");setShowDispatched(false)}}
      >
          <div class="relative">
          <svg class="h-12 w-12 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 5H7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2V7a2 2 0 0 0 -2 -2h-2" />  <rect x="9" y="3" width="6" height="4" rx="2" />  <line x1="9" y1="12" x2="9.01" y2="12" />  <line x1="13" y1="12" x2="15" y2="12" />  <line x1="9" y1="16" x2="9.01" y2="16" />  <line x1="13" y1="16" x2="15" y2="16" /></svg>
          <button type="button" class="absolute bottom-0 right-0 w-6 h-6  -mx-1 -my-1 text-white bg-blue-600 rounded-full fill-current">
  <span class="text-xs">
      {newOrders}
  </span>
</button>
          </div>
          <span class="mt-2 text-xs text-gray-600 dark:text-gray-400">
              New
          </span>
      </div>


      <div class="flex flex-col items-center cursor-pointer"
      
      onClick={()=>{setShowOrders("Verified");setShowDispatched(false)}}

      >
          <div class="relative">
          <svg class="h-12 w-12 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"/>
</svg>

<button type="button" class="absolute bottom-0 right-0 w-6 h-6  -mx-1 -my-1 text-white bg-blue-600 rounded-full fill-current">
  <span class="text-xs">
      {verifiedOrders}
  </span>
</button>
          </div>
          <span class="mt-2 text-xs text-gray-600 dark:text-gray-400">
              Verified
          </span>
      </div>


      
      <div class="flex flex-col items-center cursor-pointer"
      
      onClick={()=>{setShowOrders("Dispatched");setShowDispatched(!showDispatched)}}

      >
          <div class="relative">
          <svg class="h-12 w-12 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  
          
          <circle cx="7" cy="17" r="2" />  <circle cx="17" cy="17" r="2" /> 
          
           <path d="M5 17h-2v-4m-1 -8h11v12m-4 0h6m4 0h2v-6h-8m0 -5h5l3 5" />  <line x1="3" y1="9" x2="7" y2="9" /></svg>

           
<button type="button" class="absolute bottom-0 right-0 w-6 h-6  -mx-1 -my-1 text-white bg-blue-600 rounded-full fill-current">
  <span class="text-xs">
      {dispatchedOrders}
  </span>
</button>


              
          </div>
          <span class="mt-2 text-xs text-gray-600 dark:text-gray-400">
               Dispatched 
          </span>
      </div>
      
     
  </div>
  <div class="mt-4">
        <button type="button" class="py-2 px-4  bg-black hover:bg-gray-400 focus:ring-gray-400 focus:ring-offset-gray-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md "
        
        onClick={()=>setAddOrder(true)}
        >
            Add Orders +
        </button>
    </div>
</div>


{/* <div class="relative p-4 md:ml-4 overflow-hidden text-gray-700 bg-white shadow-lg rounded-xl w-42 md:w-42 mt-2 md:mt-0 h-32 dark:bg-gray-800 dark:text-gray-100"
onClick={()=>setShowDispatched(!showDispatched)}
>
    <a href="#" class="block w-full h-full">
        <div class="w-full">
            <p class="mb-1 text-2xl font-light text-gray-700 dark:text-white">
                Dispatched History
            </p>
          
           
        </div>
    </a>
</div> */}
</div>


<br></br>



   
  
   
   <br></br>

  
   <h1>{showOrders}</h1>


   <div className="w-full h-10 m-2 pl-3 pr-2 bg-gray-100 border-gray-50 rounded-full  outline-none  border-none flex focus:outline-none justify-between items-center relative">
 <input type="text" name="search" id="search" placeholder="Search" 
        className=" w-full bg-gray-100 border-none rounded-full    focus:outline-none focus:border-white active:outline-none"
        value= {search} onChange={(e)=>fetchPost(e.target.value)}
        />
 <button type="submit" className="ml-1 outline-none border-none focus:border-none  active:outline-none">
   <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        viewBox="0 0 24 24" className="w-6 h-6">
     <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
   </svg>
 </button>
</div>



{showDispatched?
<>


<div class="flex flex-row">


<div>

<span class={`px-4 py-2 m-2 flex w-48 items-center text-base rounded-full text-blue-500   ${showCalender?"bg-blue-200":"border border-indigo-300 undefined"} cursor-pointer`}
onClick={()=>setShowCalender(!showCalender)}
>
<svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="5" width="16" height="16" rx="2" />  <line x1="16" y1="3" x2="16" y2="7" />  <line x1="8" y1="3" x2="8" y2="7" />  <line x1="4" y1="11" x2="20" y2="11" />  <rect x="8" y="15" width="2" height="2" /></svg>
    Search by Date
</span>

</div>

   

</div>
{showCalender?<>

  <FullCalendar
    
    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
    headerToolbar={{
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay',
    }}
    initialView='dayGridMonth'
    editable={true}
    selectable={true}
    selectMirror={true}
    dayMaxEvents={true}
    height={400}
    // weekends={weekendsVisible}
    // events={data} 
    // select={handleDateSelect}
    // eventContent={renderEventContent}
    //  eventClick={handleEventClick}
    // eventsSet={handleEvents}

    dateClick={(info)=>{

        searchdate(info.dateStr)

        // console.log("INFOOO",info.dateStr)
    }}
    

  />

</>:<></>}





           <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 my-4">
                   
                   
                   
                                
                        {data ? data.map(items=>{

                          var amount=0


                          if(items.status===showOrders){

                            

                        return(

                        <>


                        <div class="shadow-lg rounded-2xl  dark:bg-gray-800 cursor-pointer" 

                            >
                                
        <div class="w-full bg-white shadow-lg rounded-2xl dark:bg-gray-700">
            <div class="flex items-center justify-between p-4">
              
                <p class="font-bold text-black text-md dark:text-white">
                  
                    {items.name}
                </p>
                
                  <button class="p-1 mr-4 text-sm "
                  
                  onClick={()=>{if(window.confirm('Are sure you want to DELETE it?')) deleteorder(items._id)}}
                  >
                  <svg class="h-6 w-6 text-red-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="4" y1="7" x2="20" y2="7" />  <line x1="10" y1="11" x2="10" y2="17" />  <line x1="14" y1="11" x2="14" y2="17" />  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
                </button>
                
            </div>

          
            <div class="flex flex-col items-center justify-between px-4 py-2 text-gray-600 bg-blue-100 border-l-4 border-blue-500 dark:bg-gray-800">
            <a href={`tel:${items.phone}`}>

        <p class="flex items-center text-xs dark:text-white">
        <svg class="h-6 w-6 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /></svg>
                {items.phone}
            </p>
            </a>
                <p class="flex items-center text-xs dark:text-white">
                <svg class="h-6 w-6 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="3" y="5" width="18" height="14" rx="2" />  <polyline points="3 7 12 13 21 7" /></svg>
                    {items.email}
                </p>
                <p class="flex items-center text-xs dark:text-white">
                <svg class="h-6 w-6 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
        </svg>

                    {items.address}
                </p>
                
            </div>

            <div class="flex items-center justify-between px-4 py-2 text-gray-600 border-b-2 border-gray-100">
                  <p class="flex items-center text-s dark:text-white">
                      
                      {items.description}
                  </p>
                  <div class="flex items-center">
                      <span class="ml-2 mr-2 text-s text-gray-400 md:ml-4">
                         
                      </span>
                  </div>
              </div>

          

            {/* {items.orderItem? items.orderItem.map(prod=>{

                  amount += (prod.qty*prod.product.rate)

                return(
                  <div class="flex items-center justify-between px-4 py-2 text-gray-600 border-b-2 border-gray-100">
                  <p class="flex items-center text-s dark:text-white">
                      
                      {prod.product.name}
                  </p>
                  <div class="flex items-center">
                      <span class="ml-2 mr-2 text-s text-gray-400 md:ml-4">
                          {prod.qty} Nos. 
                      </span>
                  Nu.{prod.product.rate}
                  </div>
              </div>
                )

            }):<></>} */}

            {/* {items.prescription?
            
            
        <Carousel showArrows={true}  showThumbs={false}>
         
         
{ items.prescription.map(pic=>{
 return(

<div 
 onClick={()=>{
  console.log("IMAGE",pic)
 }} 
>

   <img  src={pic} alt="medicine" class="max-h-28 " 
  
   ></img>
  

    </div>



   

 )
}) }         



</Carousel>
            
            // items.prescription.map(pic=>{

            //   return(
            //     <img src={pic}></img>
            //   )

            // })

            
              
          :<></>} */}

        {/* <div class="flex items-center justify-between p-4 border-b-2 border-gray-100">
                <p class="font-bold text-black text-md dark:text-white">
                    Total: Nu.{amount}
                </p>
              
            </div> */}

          

        
        </div>





          <button type="button" class="py-2 px-4 flex justify-center items-center  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "

        onClick={()=>{if(window.confirm('Are sure the Order is Ready ?')) postStockUpdate(items._id,showOrders=="Order Placed"?"Verified":"Dispatched" )}}

        >
        <svg class="h-6 w-6 text-white"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 5H7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2V7a2 2 0 0 0 -2 -2h-2" />  <rect x="9" y="3" width="6" height="4" rx="2" />  <path d="M9 14l2 2l4 -4" /></svg>
            {items.status}
        </button>

       

      

                            </div>


                        
                        

                            


                            </>  )

                          


    }



                }):null }

                                      
                               
                                   </div>
                   
</>

:<>





          
 

           <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 my-4">
                   
                   
                   
                                
                        {data ? data.map(items=>{

                          var amount=0


                          if(items.status===showOrders){

                            

                        return(

                        <>


                        <div class="shadow-lg rounded-2xl  dark:bg-gray-800 cursor-pointer" 

                            >
                                
        <div class="w-full bg-white shadow-lg rounded-2xl dark:bg-gray-700">
            <div class="flex items-center justify-between p-4">
              
                <p class="font-bold text-black text-md dark:text-white">
                  
                    {items.name}
                </p>
                
                  <button class="p-1 mr-4 text-sm "
                  
                  onClick={()=>{if(window.confirm('Are sure you want to DELETE it?')) deleteorder(items._id)}}
                  >
                  <svg class="h-6 w-6 text-red-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="4" y1="7" x2="20" y2="7" />  <line x1="10" y1="11" x2="10" y2="17" />  <line x1="14" y1="11" x2="14" y2="17" />  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
                </button>
                
            </div>

          
            <div class="flex flex-col items-center justify-between px-4 py-2 text-gray-600 bg-blue-100 border-l-4 border-blue-500 dark:bg-gray-800">
            <a href={`tel:${items.phone}`}>

        <p class="flex items-center text-xs dark:text-white">
        <svg class="h-6 w-6 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /></svg>
                {items.phone}
            </p>
            </a>
                <p class="flex items-center text-xs dark:text-white">
                <svg class="h-6 w-6 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="3" y="5" width="18" height="14" rx="2" />  <polyline points="3 7 12 13 21 7" /></svg>
                    {items.email}
                </p>
                <p class="flex items-center text-xs dark:text-white">
                <svg class="h-6 w-6 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
        </svg>

                    {items.address}
                </p>
                
                <p class="flex items-center text-xs dark:text-white">
           Advance: Nu. 

                    {items.advance}
                </p>
                
            </div>

            <div class="flex items-center justify-between px-4 py-2 text-gray-600 border-b-2 border-gray-100">
                  <p class="flex items-center text-s dark:text-white">
                      
                      {items.description}
                  </p>
                  <div class="flex items-center">
                      <span class="ml-2 mr-2 text-s text-gray-400 md:ml-4">
                         
                      </span>
                  </div>
              </div>

          
{/* 
            {items.orderItem? items.orderItem.map(prod=>{

                  amount += (prod.qty*prod.product.rate)

                return(
                  <div class="flex items-center justify-between px-4 py-2 text-gray-600 border-b-2 border-gray-100">
                  <p class="flex items-center text-s dark:text-white">
                      
                      {prod.product.name}
                  </p>
                  <div class="flex items-center">
                      <span class="ml-2 mr-2 text-s text-gray-400 md:ml-4">
                          {prod.qty} Nos. 
                      </span>
                  Nu.{prod.product.rate}
                  </div>
              </div>
                )

            }):<></>} */}

            {/* {items.prescription?
            
            
        <Carousel showArrows={true}  showThumbs={false}>
         
         
{ items.prescription.map(pic=>{
 return(

<div 
 onClick={()=>{
  console.log("IMAGE",pic)
 }} 
>

   <img  src={pic} alt="medicine" class="max-h-28 " 
  
   ></img>
  

    </div>



   

 )
}) }         



</Carousel>
            
            // items.prescription.map(pic=>{

            //   return(
            //     <img src={pic}></img>
            //   )

            // })

            
              
          :<></>} */}

        {/* <div class="flex items-center justify-between p-4 border-b-2 border-gray-100">
                <p class="font-bold text-black text-md dark:text-white">
                    Total: Nu.{amount}
                </p>
              
            </div> */}

          

        
        </div>





          <button type="button" class="py-2 px-4 flex justify-center items-center  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "

        onClick={()=>{if(window.confirm('Are sure it is Under Process ?')) postStockUpdate(items._id,showOrders=="Order Placed"?"Verified":"Dispatched" )}}

        >
        <svg class="h-6 w-6 text-white"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 5H7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2V7a2 2 0 0 0 -2 -2h-2" />  <rect x="9" y="3" width="6" height="4" rx="2" />  <path d="M9 14l2 2l4 -4" /></svg>
            {items.status}
        </button>

       

      

                            </div>


                        
                        

                            


                            </>  )

                          }



                }):null }

                                      
                               
                                   </div>
                   
                   
</>}




              









 
  
 </div>


 {
addOrder? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto my-6  relative w-auto my-6 mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-5 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setAddOrder(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          {/*body*/}
          <div className="relative p-6 flex-auto">
           
          <form >



            
        <div className=" overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 bg-white sm:p-6">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">Name</label>
                <input type="text" 
                name="first_name"
                 id="first_name" 
                 autoComplete="given-name"
                className="rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                value={name}
                onChange={(e)=>setName(e.target.value)}  
                  />
              </div>
              
                
          
              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Phone No</label>
               <input type="text"  className="rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                value={phone}
                onChange={(e)=>setPhone(e.target.value)} 
                ></input>
              </div>
            
              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Email</label>
               <input type="text"  className="rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                value={email}
                onChange={(e)=>setEmail(e.target.value)} 
                ></input>
              </div>
            
              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Address</label>
               <input type="text"  className="rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
               
                value={address}
                onChange={(e)=>setAddress(e.target.value)} 
                ></input>
              </div>
            
              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Description</label>
               <textarea type="text"  className="flex-1 w-full px-4 py-2 text-base
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                value={description}
                onChange={(e)=>setDescription(e.target.value)} 
                ></textarea>


              </div>
            
              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Advance</label>
                <input type="number"  className="rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
               
                value={advance}
                onChange={(e)=>setAdvance(e.target.value)} 
                ></input>


              </div>
            

            

          
          <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Date</label>
                <DatePicker className="rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" selected={date} onChange={date => setDate(date)} />

              </div>
            </div>
              
       
          </div>
          
          
        </div>


      </form>
          </div>
          
          <div className="flex items-center  justify-end p-6 ">
            <button
              className="bg-red-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
              type="button"
              style={{ transition: "all .15s ease"  }}
              onClick={() => setAddOrder(false)}
            >
              Cancel
            </button>
            <button
              className="bg-blue-400 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
              type="button"
              style={{ transition: "all .15s ease" }}
              onClick={() => {setAddOrder(false);postOrder()}}
            >
              Add Stock 
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)


:null

}
</main>
</div>

   </div>
 </div>  

</main>
</div>
</div>

    </div>
);


}


export default Orders