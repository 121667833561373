

import React, { useState,createContext,useContext,useEffect,useReducer } from 'react';
import {BrowserRouter,Route, Switch, useHistory} from 'react-router-dom';
import './App.css';
import Login from './components/Login'
import Home from './components/Home'
import Inventory from './components/Inventory';
import Credit from './components/Credit';
import Sales from './components/Sales';
import AllSales from './components/AllSales';
import Customer from './components/Customer';
import Expense from './components/Expense';
import Orders from './components/Orders'
import Employee from './components/Employee'
import {reducer,initialState} from './reducers/userReducer'
import EmployeeLogin from './components/EmployeeLogin';
import Reset from './components/Reset';
import NewPassword from './components/NewPassword';
import Landing from './components/Landing';
import Logging from './components/Logging';
import Sawmillstocks from './components/Sawmillstocks';
import EmployeeProfile from './components/EmployeeProfile';
import Firewood from './components/Firewood';
import FirewoodSales from './components/FirewoodSales';
import Material from './components/Material';
import Products from './components/Products';
import SingleProduct from './components/SingleProduct';
import Blogs from './components/Blogs';
import TimberPrice from './components/TimberPrice';
import Report from './components/Report';



export const UserContex = createContext()

const Routing = ()=>{

  
  const {state, dispatch} = useContext(UserContex)
  const history = useHistory()

  useEffect(()=>{

    const user = JSON.parse( localStorage.getItem("user"))

    if(user){
      dispatch({type:"USER",payload:user})
     
    }else{

      if(history.location.pathname.startsWith('/loginemployee')) { history.push('/loginemployee')
    }
     else if(history.location.pathname.startsWith('/login')) { history.push('/login')
    }
       else if(history.location.pathname.startsWith('/products')) { history.push('/products')}
       else if(history.location.pathname.startsWith('/blog')) { history.push('/blog')}

      else if(history.location.pathname.startsWith('/reset')) { history.push('/reset')
    }
    else{
           history.push('/')

    }
    }

  },[])

  return(

    <Switch>
     
      
        <Route path='/home'>
          <Landing />
        </Route>
        <Route path='/logging'>
          <Logging />
        </Route>
        <Route path='/loginemployee'>
          <EmployeeLogin />
        </Route>
        <Route path='/dashboard'>
          <Home />
        </Route>
        <Route path='/inventory'>
          <Inventory />
        </Route>
        <Route path='/report'>
          <Report />
        </Route>
        <Route path='/timberprice'>
          <TimberPrice />
        </Route>
        {/* <Route path='/credit'>
          <Credit />
        </Route> */}
        <Route path='/sales'>
          <Sales />
        </Route>
        <Route path='/sawmillstocks'>
          <Sawmillstocks />
        </Route>
        {/* <Route path='/orders'>
          <Orders />
        </Route> */}
        <Route path='/allSales'>
          <AllSales />
        </Route>
        <Route path='/expenses'>
          <Expense />
        </Route>
        <Route path='/employee'>
          <Employee />
        </Route>
        <Route path='/singlerecord/:customerid'>
          <Customer />
        </Route>
        <Route path='/profile/:employeeid'>
          <EmployeeProfile/>
        </Route>
        <Route path='/firewood'>
          <Firewood/>
        </Route>
        <Route path='/orders'>
          <Orders/>
        </Route>
        <Route path='/material'>
        <Material/>
        </Route>
      
        <Route path='/firewoodsales'>
          <FirewoodSales/>
        </Route>

        <Route exact path='/reset'>
          <Reset />
        </Route>
        <Route path='/reset/:token'>
          <NewPassword/>
        </Route>
        

    </Switch>
  )
}

function App() {

  const [state, dispatch] = useReducer(reducer,initialState)

  return (


    <UserContex.Provider value={{state,dispatch}}>

        <BrowserRouter>
        
          <Route exact path="/">
            <Landing />
          </Route>
          <Route exact path='/products'>
        <Products/>
        </Route>
          <Route exact path='/blog'>
        <Blogs/>
        </Route>
        <Route path='/singleproduct/:productid'>
          <SingleProduct/>
        </Route>
          <Route exact path="/login">
            <Login />
          </Route>
         
          
        <Routing/>

        </BrowserRouter>
    </UserContex.Provider>

  );
}

export default App;
