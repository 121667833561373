import React,{ useEffect, useState,useContext } from "react";
import {Link, useHistory,useParams} from 'react-router-dom'
import DatePicker from 'react-datepicker'

import {UserContex} from '../App'
// import { ToastContainer, toast } from 'react-toastify';
// import { Carousel } from 'react-responsive-carousel';

import 'react-toastify/dist/ReactToastify.css';
import NavBar from "./nav";

const EmployeeProfile = ()=>{
  const {state, dispatch}= useContext(UserContex)

  const history = useHistory();
   const [name, setName] = useState("")
   const [email, setEmail] = useState("")
   const [newAddress, setNewAddress] = useState("")
   const [registration, setRegistration] = useState("")
   const [address,setAddress] = useState([])
   const [department,setDepartment] = useState("")
   const [orderHistory,setOrderHistory] = useState(true) 
   const [prescriptions,setShowPrescriptions] = useState(false) 
   const [edit,setEdit] = useState(false) 
   const [overtime,setOvertime] = useState(false) 
   const [showovertime,setShowOvertime] = useState(false) 
   const [showattendence,setShowAttendence] = useState(false) 
   const [editovertime,setEditOvertime] = useState(false) 
   const [editattendence,setEditAttendence] = useState(false) 
   const [editid,setEditId] = useState("") 
   const [editAmount,setEditAmount] = useState(false) 
   const [editHour,setEditHour] = useState(false) 
   const [showimage,setShowImage] = useState(false) 
   const [zoomimage,setZoomImage] = useState("") 
   const [orderHistoryData,setOrderHistoryData] = useState([]) 
   const [data,setData] = useState() 
   const [amount,setAmount] = useState() 
   const [hour,setHour] = useState() 
   const [date,setDate] = useState(new Date())
   const [status,setStatus] = useState() 
   const [addattendance,setAddAttendence] = useState(false)
   const [salary,setSalary] = useState(0)
   const [payable,setPayable] = useState(0)
   const [present,setPresent] = useState(0)
   const [absent,setAbsent] = useState(0)
   const [halfDay,setHalfDay] = useState(0)
   const [advance,setAdvance] = useState(0)
   const [attendenceThisMonth,setAttendenceThisMonth] = useState(0)
  //  console.log("STATE",state)
  const  [month, setMonth]= useState(new Date)
  const  [endDate, setEndDate]= useState(new Date)

  const {employeeid} = useParams()

   const monthNames = ["January", "February", "March", "April", "May", "June",
   "July", "August", "September", "October", "November", "December"
 ];
 
 var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
 
//  var date = new Date();
 
   


// console.log("ADD",address)

 useEffect(  ()=>{

  


    fetch('/getSingleEmployee',{
      method:"post",
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt"),
          "Content-Type":"application/json"
  
          
      },
          body: JSON.stringify({
      
        id:employeeid
    })
     
  }).then(res=>res.json())
  .then(result=>{
    //   setOrderHistoryData(result.orders)
      setData(result.employee)
      setSalary(result.employee.salary)
      var att = 0
      var abs = 0
      var half = 0
      var adv=0
      result.employee.attendence.map(attend=>{
        const nd = new Date(attend.date)

        if(nd.getMonth()==date.getMonth()&&nd.getFullYear()==date.getFullYear()){
          console.log("MONTH for ", nd)
          if(attend.status==1){
            att+=attend.status

          }
          if(attend.status==0){
            abs+=1

          }
          if(attend.status==0.5){
            half+=0.5

          }


        }




      })


      result.employee.advance.map(advance=>{

        const nd = new Date(advance.date)

        if(nd.getMonth()==date.getMonth()&&nd.getFullYear()==date.getFullYear()){

          adv+=advance.amount
        }
      })


      setAbsent(abs)
      setHalfDay(half)
      setAttendenceThisMonth(att)
      setAdvance(adv)

    
  })


  

},[])


// const addAddress = (newaddress)=>{

//         // console.log(newaddress)
//       setAddress(old=>[...old, newaddress])

// }

const updateProfile =()=>{

  fetch("/addAdvance",{
    method:"put",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"
    },
    body: JSON.stringify({
      
        amount:amount,
        date:date,
        id:employeeid
    })
    
}).then(res=>res.json())
.then(data=> {
   
    if(data.error){
    //   toast.error(data.error)

    }
    else{
//    toast.success("Updated")
  //  history.go('/cart')
  window.location.reload()

  setEdit(false)
    }
})
.catch(err=>{
    console.log(err)
})

}
const addOvertime =()=>{

  fetch("/addovertime",{
    method:"put",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"
    },
    body: JSON.stringify({
      
        hour:hour,
        date:date,
        id:employeeid
    })
    
}).then(res=>res.json())
.then(data=> {
   
    if(data.error){
    //   toast.error(data.error)

    }
    else{
//    toast.success("Updated")
  //  history.go('/cart')
  window.location.reload();

  setEdit(false)
    }
})
.catch(err=>{
    console.log(err)
})

}


const addNewAttencence =()=>{

  fetch("/addattendance",{
    method:"put",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"
    },
    body: JSON.stringify({
      
        status:status,
        date:date,
        id:employeeid
    })
    
}).then(res=>res.json())
.then(data=> {
   
    if(data.error){
    //   toast.error(data.error)

    }
    else{
//    toast.success("Updated")
  //  history.go('/cart')
  window.location.reload();

  setEdit(false)
    }
})
.catch(err=>{
    console.log(err)
})

}



const updateAdvance =()=>{

  fetch("/editAdvance",{
    method:"put",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"
    },
    body: JSON.stringify({
      
        amount:amount,
        date:date,
        id:employeeid,
        advanceId: editid
    })
    
}).then(res=>res.json())
.then(data=> {
   
    if(data.error){
    //   toast.error(data.error)

    }
    else{
//    toast.success("Updated")
  //  history.go('/cart')
  window.location.reload();

  setEditId(false)
    }
})
.catch(err=>{
    console.log(err)
})

}



const updateOvertime =()=>{

  fetch("/editOvertime",{
    method:"put",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"
    },
    body: JSON.stringify({
      
        hour:hour,
        date:date,
        id:employeeid,
        overtimeId: editid
    })
    
}).then(res=>res.json())
.then(data=> {
   
    if(data.error){
    //   toast.error(data.error)

    }
    else{
//    toast.success("Updated")
  //  history.go('/cart')
  window.location.reload();

  setEditId(false)
    }
})
.catch(err=>{
    console.log(err)
})

}


const updateAttendence =()=>{

  fetch("/editattendence",{
    method:"put",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"
    },
    body: JSON.stringify({
      
        status:status,
        date:date,
        id:employeeid,
        attendenceId: editid
    })
    
}).then(res=>res.json())
.then(data=> {
   
    if(data.error){
    //   toast.error(data.error)

    }
    else{
//    toast.success("Updated")
  //  history.go('/cart')
  window.location.reload();

  setEditId(false)
    }
})
.catch(err=>{
    console.log(err)
})

}

// console.log("DATA",data[0].address)
const searchMonth=()=>{

  const query = new Date(month)
  const end = new Date(endDate)

  var att = 0
  var abs = 0
  var half = 0
  var adv=0
  data.attendence.map(attend=>{
    const nd = new Date(attend.date)

    if(nd>=query && nd<=end){

      console.log("START",query)
      console.log("END",end)

      if(attend.status==1){
        att+=attend.status


      }
      if(attend.status==0){
        abs+=1
        console.log("ABS",abs)


      }
      if(attend.status==0.5){
        half+=0.5
        console.log("HALF",half)


      }


    }




  })


  data.advance.map(advance=>{

    const nd = new Date(advance.date)

    if(nd>=query && nd<=end){

      adv+=advance.amount
    }
  })


  setAbsent(abs)
  setHalfDay(half)
  setAttendenceThisMonth(att)
  setAdvance(adv)


}


    return(
<>
<NavBar></NavBar>
<main className="profile-page">
        <section className="relative block" style={{ height: "500px" }}>
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1573883431205-98b5f10aaedb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80')"
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>
        <section className="relative py-16 bg-gray-300">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="px-6">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                    <div className="relative">
                      <img
                        alt="..."
                        src={data?data.pic:""}
                        className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16"
                        style={{ maxWidth: "150px" }}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                    {/* <div className="py-6 px-3 mt-32 sm:mt-0">
                      <button
                        className="bg-pink-500 active:bg-pink-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1"
                        type="button"
                        style={{ transition: "all .15s ease" }}
                      >
                        Connect
                      </button>
                    </div> */}
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-1">
                    <div className="flex justify-start py-4 lg:pt-4 pt-8">
                      <div 
                      onClick={()=>history.push('/employee')}
                      className="mr-4 p-3 text-center cursor-pointer">
                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                        <svg class="h-8 w-8 text-blue-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <polyline points="12 8 8 12 12 16" />  <line x1="16" y1="12" x2="8" y2="12" /></svg>
                        </span>
                        {/* <span className="text-sm text-gray-500">Back</span> */}
                      </div>
                      {/* <div className="mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                          10
                        </span>
                        <span className="text-sm text-gray-500">Consultations</span>
                      </div> */}
                      {/* <div className="lg:mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                          89
                        </span>
                        <span className="text-sm text-gray-500">Comments</span>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-gray-800 mb-2">
                    {data?data.name:""}
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase">
                    <i className="fas fa-map-marker-alt mr-2 text-lg text-gray-500"></i>{" "}
                    {data?data.email:""}
                  </div>
                  <div className="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase">
                    <i className="fas fa-map-marker-alt mr-2 text-lg text-gray-500"></i>{" "}
                    {data?data.phone:""}
                  </div>
                 








                  <div className="mb-2 text-gray-700 md:flex flex-row  ">
                  <div class=" relative w-full item-center  m-auto   p-2 overflow-hidden bg-white shadow-lg rounded-xl md:w-80 ">
   
    


      
<div class="relative w-full px-4 py-6 bg-white shadow-lg dark:bg-gray-800">
<div className="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase">
                    <i className="fas fa-map-marker-alt mr-2 text-lg text-gray-500"></i>{" "}
                    Salary Nu. {salary}
                  </div>
                 
<p class="my-4 text-2xl font-bold text-left text-gray-800 dark:text-white">
        <DatePicker 
                // dateFormat="DDDD MMMM yyyy" // Display only month and year
                // showMonthYearPicker

className="rounded-lg w-11/12 pl-6
        bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" selected={month} onChange={month => {setMonth(month)}} />
        <DatePicker 
                // dateFormat="DDDD MMMM yyyy" // Display only month and year
                // showMonthYearPicker

className="rounded-lg w-11/12 pl-6
        bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" selected={endDate} onChange={endDate => {setEndDate(endDate)}} />


        </p>

<button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
onClick={()=>{searchMonth()}}
>
  
    Valider
</button>

    <div class="flex items-end my-6 space-x-2"> Nu.
        <p class={`text-5xl font-bold  dark:text-white ${(((attendenceThisMonth+halfDay)*salary/30)-advance)>=0?'text-green-500':"text-red-500"}`}>
            {(((attendenceThisMonth+halfDay)*salary/30)-advance).toFixed(0)}
        </p>
       
    </div>
    <div class="dark:text-white">
        <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
            <p>
                Advance
            </p>
            <div class="flex items-end text-xs">
               Nu. {advance} 
            </div>
        </div>
        <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
            <p>
                Present
            </p>
            <div class="flex items-end text-xs">
                {attendenceThisMonth} Days
            </div>
        </div>
        <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
            <p>
                Absent
            </p>
            <div class="flex items-end text-xs">
                {absent} Days
            </div>
        </div>
        <div class="flex items-center justify-between space-x-12 text-sm md:space-x-24">
            <p>
                Half Day
            </p>
            <div class="flex items-end text-xs">
                {halfDay} Days
            </div>
        </div>
    </div>
</div>


    


   
</div>
         
                     
         </div>

                  {/* <div className="mb-2 text-gray-700 md:flex flex-row  ">
                  <div class=" relative w-full item-center  m-auto   p-2 overflow-hidden bg-white shadow-lg rounded-xl md:w-80 dark:bg-gray-800">
    <div class="flex items-center justify-between  w-full mb-3">
        <p class="text-xl font-medium items-center justify-between mt-2 text-gray-800 dark:text-white">
            Attendance
        </p>

        <button class="flex items-center text-gray-800 border-0 hover:text-black dark:text-gray-50 dark:hover:text-white focus:outline-none"
        onClick={()=>{
          setAddAttendence(true)
        }}
        >
            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z">
                </path>
            </svg>
        </button>
    </div>
    <div class="flex items-center justify-between  w-full mb-3">
        <p class="text-xl font-medium items-center justify-between mt-2 text-gray-800 dark:text-white">
            Advance Payment
        </p>

        <button class="flex items-center text-gray-800 border-0 hover:text-black dark:text-gray-50 dark:hover:text-white focus:outline-none"
        onClick={()=>{
          setEdit(true)
        }}
        >
            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z">
                </path>
            </svg>
        </button>
    </div>
    <div class="flex items-center justify-between  w-full mb-3">
        <p class="text-xl font-medium items-center justify-between mt-2 text-gray-800 dark:text-white">
            Overtime 
        </p>

        <button class="flex items-center text-gray-800 border-0 hover:text-black dark:text-gray-50 dark:hover:text-white focus:outline-none"
        onClick={()=>{
          setOvertime(true)
        }}
        >
            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z">
                </path>
            </svg>
        </button>
    </div>

      

    


   
</div>
         
                    <i className="fas fa-briefcase m-2 text-lg text-gray-500"></i>
                     
         </div>

 */}





                  <div class=" ">

<div
  class=""
>
  <div
    class="grid md:grid-cols-4 grid-cols-2  place-items-center justify-items-center  gap-4 md:m-8 mt-4 mb-4 "
  >
   

    <div class="  shadow-lg rounded-2xl w-36 bg-blue-100">
   
    <div class="flex flex-col justify-start cursor-pointer"
         onClick={()=>{setShowPrescriptions(false);setOrderHistory(!orderHistory);setShowOvertime(false);setShowAttendence(false)}}

    >
        <p class="my-4 pl-3 text-2xl font-bold text-left text-gray-800 dark:text-white">
            Advance
        </p>
       


    </div>
    <button type="button" class=" px-1 flex justify-center items-center  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
    
    onClick={()=>{
      setEdit(true)
    }}
    >
    
    Add Advance
    <svg class="h-8 w-8 pl-2 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

</button>
</div>

    <div class="  shadow-lg rounded-2xl w-36 bg-blue-100">
   
    <div class="flex flex-col justify-start cursor-pointer"
         onClick={()=>{setShowPrescriptions(false);setOrderHistory(false);setShowOvertime(!overtime);setShowAttendence(false)}}

    >
        <p class="my-4 pl-3 text-2xl font-bold text-left text-gray-800 dark:text-white">
            Overtime
        </p>
       


    </div>
    <button type="button" class=" px-1 flex justify-center items-center  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
     onClick={()=>{
      setOvertime(true)
    }}
    >
    
    Add Overtime
    <svg class="h-8 w-8 pl-2 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

</button>
</div>

    <div class="  shadow-lg rounded-2xl w-36 bg-blue-100">
   
    <div class="flex flex-col justify-start cursor-pointer"
         onClick={()=>{setShowPrescriptions(false);setOrderHistory(false);setShowOvertime(false);setShowAttendence(!showattendence)}}

    >
        <p class="my-4 pl-3 text-2xl font-bold text-left text-gray-800 dark:text-white">
            Attendence 
        </p>
       


    </div>
    <button type="button" class=" px-1 flex justify-center items-center  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
     onClick={()=>{
      setAddAttendence(true)
    }}
    >
    
    Add Attendence
    <svg class="h-8 w-8 pl-2 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

</button>
</div>


    <div class="  shadow-lg rounded-2xl w-36 bg-blue-100">
   
    <div class="flex flex-col justify-start cursor-pointer"
         onClick={()=>{setShowPrescriptions(!prescriptions);setOrderHistory(false);setShowOvertime(false);setShowAttendence(false)}}

    >
        <p class="my-4 pl-3 text-2xl font-bold text-left text-gray-800 dark:text-white">
            Materials Taken 
        </p>
       


    </div>
   
</div>


  
   

    
   
   
    

   
  </div>
</div>
</div>
 
                </div>


  {orderHistory?
            
<div class="container max-w-4xl mx-auto sm:px-8">
    <div class="">
        <div class="px-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
            <div class="inline-block min-w-full overflow-hidden rounded-lg shadow">
                <table class="min-w-full leading-normal">
                    <thead>
                        <tr>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Amount
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Date
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Action
                            </th>
                            {/* <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Price
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Status
                            </th> */}
                            
                        </tr>
                    </thead>
                    <tbody>

                        {data?data.advance.map(item=>{


                            var nd = new Date(item.date)

                            if(nd.getMonth()==date.getMonth()){
                                return(
                              <>
                               <tr>
                            <td class="px-5 py-5 text-sm bg-white border-b border-gray-200 h-8">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0">

                                       Nu. {item.amount}
                                    </div>
                                    <div class="ml-3">
                                        
                                    </div>
                                </div>
                            </td>
                            <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                <p class="text-gray-900 whitespace-no-wrap">
                                    
<div class="flex items-center">
    
   
        {item.date?item.date.substring(0,10):""}
    
  
   
    
</div>
                                </p>
                            </td>
                            <td class="px-5 py-5 text-sm bg-white border-b border-gray-200 "
                            
                            >
                                <p class="text-gray-900 whitespace-no-wrap">
                                    
<div class="flex items-center">
    
   
<svg
                            onClick={()=>{setEditId(item._id);setAmount(item.amount);setDate(new Date(item.date));setEditAmount(true)}}

 class="h-6 w-6 text-red-500 cursor-pointer"   width="24"  height="24"  viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>    
  
   
    
</div>
                                </p>
                            </td>
                            
                         
                          
                        </tr>

                              </>
                            )

                            }

                          
                       

                        }):<></>}

                       
                       
                       
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>:<></>
  }
  {showovertime?
            
<div class="container max-w-4xl mx-auto sm:px-8">
    <div class="">
        <div class="px-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
            <div class="inline-block min-w-full overflow-hidden rounded-lg shadow">
                <table class="min-w-full leading-normal">
                    <thead>
                        <tr>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Hours
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Date
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Action
                            </th>
                            {/* <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Price
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Status
                            </th> */}
                            
                        </tr>
                    </thead>
                    <tbody>

                        {data.overtime?data.overtime.map(item=>{

                      var nd = new Date(item.date)

                        if(nd.getMonth()==date.getMonth()){
                                    return(
                                            <>
                                            <tr>
                                          <td class="px-5 py-5 text-sm bg-white border-b border-gray-200 h-8">
                                              <div class="flex items-center">
                                                  <div class="flex-shrink-0">

                                                    Hrs. {item?item.hour:"N/A"}
                                                  </div>
                                                  <div class="ml-3">
                                                      
                                                  </div>
                                              </div>
                                          </td>
                                          <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                              <p class="text-gray-900 whitespace-no-wrap">
                                                  
              <div class="flex items-center">
                  
                
                      {item.date.substring(0,10)}
                  
                
                
                  
              </div>
                                              </p>
                                          </td>
                                          <td class="px-5 py-5 text-sm bg-white border-b border-gray-200 "
                                          
                                          >
                                              <p class="text-gray-900 whitespace-no-wrap">
                                                  
              <div class="flex items-center">
                  
                
              <svg
                                          onClick={()=>{setEditId(item._id);setHour(item.hour);setDate(new Date(item.date));setEditOvertime(true)}}

              class="h-6 w-6 text-red-500 cursor-pointer"   width="24"  height="24"  viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>    
                
                
                  
              </div>
                                              </p>
                                          </td>
                                          
                                      
                                        
                                      </tr>

                                            </>
                                          )
}
                           

                       

                        }):<></>}

                       
                       
                       
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>:<></>
  }
  {showattendence?
            
<div class="container max-w-4xl mx-auto sm:px-8">
    <div class="">
        <div class="px-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
            <div class="inline-block min-w-full overflow-hidden rounded-lg shadow">
                <table class="min-w-full leading-normal">
                    <thead>
                        <tr>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Status
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Date
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Action
                            </th>
                            {/* <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Price
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Status
                            </th> */}
                            
                        </tr>
                    </thead>
                    <tbody>

                        {data?data.attendence.map(item=>{



                    var nd = new Date(item.date)

                    if(nd.getMonth()==date.getMonth()){

                      return(
                        <>
                         <tr>
                      <td class="px-5 py-5 text-sm bg-white border-b border-gray-200 h-8">
                          <div class="flex items-center">
                              <div class="flex-shrink-0">

                                  {item.status==1?"Present":item.status==0?"Absent":"Half Day"}
                              </div>
                              <div class="ml-3">
                                  
                              </div>
                          </div>
                      </td>
                      <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                          <p class="text-gray-900 whitespace-no-wrap">
                              
<div class="flex items-center">


  {item.date.substring(0,10)}




</div>
                          </p>
                      </td>
                      <td class="px-5 py-5 text-sm bg-white border-b border-gray-200 "
                      
                      >
                          <p class="text-gray-900 whitespace-no-wrap">
                              
<div class="flex items-center">


<svg
                      onClick={()=>{setEditId(item._id);setStatus(item.status);setDate(new Date(item.date));setEditAttendence(true)}}

class="h-6 w-6 text-red-500 cursor-pointer"   width="24"  height="24"  viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>    



</div>
                          </p>
                      </td>
                      
                   
                    
                  </tr>

                        </>
                      )

                    }

                          
                       

                        }):<></>}

                       
                       
                       
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>:<></>
  }

  {prescriptions?<>
  
    <div className="grid  grid-cols-2  md:grid-cols-4  md:gap-4      ">


      {data?data.materials.map(item=>{

//         if(item.prescription!=""){

//           return(
      
// <div class="w-64 m-auto overflow-hidden shadow-lg rounded-2xl h-90">
//     <img alt="Prescription" src={item.prescription} class="rounded-t-lg"/>
   
// </div>

//           )
//         }

return(

<>

<div class="relative w-64 p-4 overflow-hidden bg-white shadow-lg rounded-2xl">
    <img alt="moto" src="/images/object/1.png" class="absolute w-40 h-40 mb-4 -right-20 -bottom-8"/>
    <div class="w-4/6">
        <p class="mb-2 text-lg font-medium text-gray-800">
            {item.item.name}
        </p>
        <p class="text-s text-gray-400">
            Quantity: {item.qty}
        </p>
        <p class="text-s text-gray-400">
            Bill: {item.item.bill}
        </p>
        <p class="text-s text-gray-400">
            Expire: {item.item.expire.substring(0,10)}
        </p>
        <p class="text-xl font-medium text-indigo-500">
            Nu.{item.item.price * item.qty}
        </p>
    </div>
</div>



</>
)
      }):<></>}



</div>

  </>:<></>}



  

   
   {
showimage? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto   relative w-auto  mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-2 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setShowImage(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          {/*body*/}
           


              
<div class="p-4 mx-auto text-center bg-white border-t-4 border-indigo-500 rounded shadow w-72 dark:bg-gray-800">

  <image
  
  src={zoomimage}
  
  ></image>
    
</div>





         
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)


:null

}



{
edit? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto   relative w-auto  mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-2 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setEdit(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          {/*body*/}
           
          <div className="py-1 px-2">
          <span className="px-1 text-sm text-gray-600">Amount</span>

          <div class='flex flex-row'>
          <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Advance Amount"
            value={amount}
            onChange={(e)=>setAmount(e.target.value)}
            >

            </input>

                   </div>
           
          </div>

          <div className="col-span-6 sm:col-span-4 py-1 px-2">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Date</label>
                <DatePicker className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
           selected={date} onChange={date => setDate(date)} />

              </div>


<div class="container flex flex-col items-center justify-center w-full mx-auto bg-white rounded-lg shadow dark:bg-gray-800">
    <ul class="flex flex-col divide-y divide">

      

        
      
    </ul>
</div>


          {/* <div className="py-1 px-2">
          <span className="px-1 text-sm text-gray-600">Email</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Email"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            >

            </input>
          </div> */}

          <div class="py-5 rounded-t sm:px-6">
    <div class="overflow-auto bg-white shadow dark:bg-gray-800 sm:rounded-md">
      
        <div class="w-full p-4 mx-auto ">
            <button type="button" class="py-2 px-4  bg-blue-500 hover:bg-blue-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            
            onClick={()=>{
              updateProfile();
                // postOrder(data)
                
              
              }}
            >
                Update
            </button>


        </div>
    </div>
</div>




         
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)
:null

}
{
overtime? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto   relative w-auto  mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-2 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setOvertime(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          {/*body*/}
           
          <div className="py-1 px-2">
          <span className="px-1 text-sm text-gray-600">Hours</span>

          <div class='flex flex-row'>
          <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Overtime Hour"
            value={hour}
            onChange={(e)=>setHour(e.target.value)}
            >

            </input>

                   </div>
           
          </div>

          <div className="col-span-6 sm:col-span-4 py-1 px-2">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Date</label>
                <DatePicker className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
           selected={date} onChange={date => setDate(date)} />

              </div>


<div class="container flex flex-col items-center justify-center w-full mx-auto bg-white rounded-lg shadow dark:bg-gray-800">
    <ul class="flex flex-col divide-y divide">

      

        
      
    </ul>
</div>


          {/* <div className="py-1 px-2">
          <span className="px-1 text-sm text-gray-600">Email</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Email"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            >

            </input>
          </div> */}

          <div class="py-5 rounded-t sm:px-6">
    <div class="overflow-auto bg-white shadow dark:bg-gray-800 sm:rounded-md">
      
        <div class="w-full p-4 mx-auto ">
            <button type="button" class="py-2 px-4  bg-blue-500 hover:bg-blue-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            
            onClick={()=>{
              addOvertime();
                // postOrder(data)
                
              
              }}
            >
                Add
            </button>


        </div>
    </div>
</div>




         
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)
:null

}
{
addattendance? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto   relative w-auto  mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-2 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setAddAttendence(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          {/*body*/}
           
          <div className="py-1 px-2">
          <span className="px-1 text-sm text-gray-600">Status</span>

          <div class='flex flex-row'>
       


<select class="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" name="animals"
          value={status}
          onChange={(e)=>setStatus(e.target.value)}
          >
    <option value="">
        Select an option
    </option>
    <option value={1}>
        Present
    </option>
    <option value={0}>
        Absent 
    </option>
    <option value={0.5}>
        Half Day
    </option>
   
</select>


                   </div>
           
          </div>

          <div className="col-span-6 sm:col-span-4 py-1 px-2">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Date</label>
                <DatePicker className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
           selected={date} onChange={date => setDate(date)} />

              </div>


<div class="container flex flex-col items-center justify-center w-full mx-auto bg-white rounded-lg shadow dark:bg-gray-800">
    <ul class="flex flex-col divide-y divide">

      

        
      
    </ul>
</div>


          {/* <div className="py-1 px-2">
          <span className="px-1 text-sm text-gray-600">Email</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Email"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            >

            </input>
          </div> */}

          <div class="py-5 rounded-t sm:px-6">
    <div class="overflow-auto bg-white shadow dark:bg-gray-800 sm:rounded-md">
      
        <div class="w-full p-4 mx-auto ">
            <button type="button" class="py-2 px-4  bg-blue-500 hover:bg-blue-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            
            onClick={()=>{
              addNewAttencence();
                // postOrder(data)
                
              
              }}
            >
                Add
            </button>


        </div>
    </div>
</div>




         
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)
:null

}

{
editAmount? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto   relative w-auto  mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-2 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setEditAmount(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          {/*body*/}
           
          <div className="py-1 px-2">
          <span className="px-1 text-sm text-gray-600">Amount</span>

          <div class='flex flex-row'>
          <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Advance Amount"
            value={amount}
            onChange={(e)=>setAmount(e.target.value)}
            >

            </input>

                   </div>
           
          </div>

          <div className="col-span-6 sm:col-span-4 py-1 px-2">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Date</label>
                <DatePicker className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
           selected={date} onChange={date => setDate(date)} />

              </div>


<div class="container flex flex-col items-center justify-center w-full mx-auto bg-white rounded-lg shadow dark:bg-gray-800">
    <ul class="flex flex-col divide-y divide">

      

        
      
    </ul>
</div>


          {/* <div className="py-1 px-2">
          <span className="px-1 text-sm text-gray-600">Email</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Email"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            >

            </input>
          </div> */}

          <div class="py-5 rounded-t sm:px-6">
    <div class="overflow-auto bg-white shadow dark:bg-gray-800 sm:rounded-md">
      
        <div class="w-full p-4 mx-auto ">
            <button type="button" class="py-2 px-4  bg-blue-500 hover:bg-blue-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            
            onClick={()=>{
              updateAdvance()
                // postOrder(data)
                
              
              }}
            >
                Update
            </button>


        </div>
    </div>
</div>




         
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)


:null

}
{
editovertime? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto   relative w-auto  mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-2 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setEditOvertime(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          {/*body*/}
           
          <div className="py-1 px-2">
          <span className="px-1 text-sm text-gray-600">Hour</span>

          <div class='flex flex-row'>
          <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Advance Amount"
            value={hour}
            onChange={(e)=>setHour(e.target.value)}
            >

            </input>

                   </div>
           
          </div>

          <div className="col-span-6 sm:col-span-4 py-1 px-2">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Date</label>
                <DatePicker className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
           selected={date} onChange={date => setDate(date)} />

              </div>


<div class="container flex flex-col items-center justify-center w-full mx-auto bg-white rounded-lg shadow dark:bg-gray-800">
    <ul class="flex flex-col divide-y divide">

      

        
      
    </ul>
</div>


          {/* <div className="py-1 px-2">
          <span className="px-1 text-sm text-gray-600">Email</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Email"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            >

            </input>
          </div> */}

          <div class="py-5 rounded-t sm:px-6">
    <div class="overflow-auto bg-white shadow dark:bg-gray-800 sm:rounded-md">
      
        <div class="w-full p-4 mx-auto ">
            <button type="button" class="py-2 px-4  bg-blue-500 hover:bg-blue-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            
            onClick={()=>{
              updateOvertime()
                // postOrder(data)
                
              
              }}
            >
                Update
            </button>


        </div>
    </div>
</div>




         
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)


:null

}
{
editattendence? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto   relative w-auto  mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-2 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setEditAttendence(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          {/*body*/}
           
          <div className="py-1 px-2">
          <span className="px-1 text-sm text-gray-600">Status</span>

          <select class="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" name="animals"
          value={status}
          onChange={(e)=>setStatus(e.target.value)}
          >
    <option value="">
        Select an option
    </option>
    <option value={1}>
        Present
    </option>
    <option value={0}>
        Absent 
    </option>
    <option value={0.5}>
        Half Day
    </option>
   
</select>
           
          </div>

          <div className="col-span-6 sm:col-span-4 py-1 px-2">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Date</label>
                <DatePicker className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
           selected={date} onChange={date => setDate(date)} />

              </div>


<div class="container flex flex-col items-center justify-center w-full mx-auto bg-white rounded-lg shadow dark:bg-gray-800">
    <ul class="flex flex-col divide-y divide">

      

        
      
    </ul>
</div>


          {/* <div className="py-1 px-2">
          <span className="px-1 text-sm text-gray-600">Email</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Email"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            >

            </input>
          </div> */}

          <div class="py-5 rounded-t sm:px-6">
    <div class="overflow-auto bg-white shadow dark:bg-gray-800 sm:rounded-md">
      
        <div class="w-full p-4 mx-auto ">
            <button type="button" class="py-2 px-4  bg-blue-500 hover:bg-blue-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            
            onClick={()=>{
              updateAttendence()
                // postOrder(data)
                
              
              }}
            >
                Update
            </button>


        </div>
    </div>
</div>




         
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)


:null

}





                <div className="mt-10 py-10 border-t border-gray-300 text-center">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full lg:w-9/12 px-4">
                      <p className="mb-4 text-lg leading-relaxed text-gray-800">
                        Keep track of  Employees records.
                      </p>
                      {/* <a
                        href="#pablo"
                        className="font-normal text-pink-500"
                        onClick={e => e.preventDefault()}
                      >
                        Show more
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

</>
    );

}

export default EmployeeProfile; 