
import React,{useContext, useState,useEffect} from 'react'
import {Link, useHistory,useParams} from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import Header from './Header';
import 'react-toastify/dist/ReactToastify.css';

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {UserContex} from '../App'
// import Footer from './Footer';


const SingleProduct = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useHistory();
  const [addStock,setAddStock]= useState(false)
  const [editEmoloyee,setEdit]= useState(false)
  const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
  const[address,setAddress]= useState("")
  const[email,setEmail]= useState("")
  const [data,setData]= useState({})
  const [cart,setCart]= useState([])
  const [url,setUrl] = useState([])  
  const [quote, setQuote] = useState(false)  


  const {productid} =useParams()


  

  useEffect(  ()=>{




    const l = JSON.parse(localStorage.getItem('cart'))

    if(l){
      setCart(l)
    }


    fetch(`/getSingleConsumerStock/${productid}`,{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.stock)
      setUrl(result.stock.pic)
      
  })

  },[])


//   const addtoCart = (item)=>{

//     // console.log("ADD TO CART",item)
//     setCart(olditem=>[...olditem, item])
  
//     fetch('/addToCart',{
  
//       method:"post",
//       headers:{
//         Authorization: "Bearer " +localStorage.getItem("jwt"),
  
//         "Content-Type":"application/json"
  
//       },
//       body:JSON.stringify({
//         product:item,
//         consumer:state._id
        
//       })
  
//     }).then(res=> res.json())
//       .then(results=>{
        
//         // setData(results.order)
//       })
  
  
  
//   const json = localStorage.getItem("cart");
//     const previousPosts = json ? JSON.parse(json) : [];
//     const updatedPosts = [...previousPosts, item];
//     // const uniquePosts = Array.from(new Set(updatedPosts))
//     const stringifyed = JSON.stringify(updatedPosts);
//     localStorage.setItem("cart", stringifyed);
//   }
  

    return(


<>
<Header></Header>





{data &&data.hasOwnProperty("pic") ?
<>
<div class="relative max-w-screen-xl p-4 px-4 mx-auto bg-white dark:bg-gray-800 sm:px-6 lg:px-8 ">
    <div class="relative">
        <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">

            
            



<Carousel showArrows={true}  showThumbs={true}

>
         
         
{url ? url.map(pic=>{
 return(

<div  
>

   <img  src={pic} alt="medicine" class=" " />
  

    </div>



   

 )
}):<></> }         



</Carousel>

                         
            <div class="ml-auto lg:col-start-2 lg:max-w-2xl">
                <p class="text-base font-semibold leading-6 text-blue-500 uppercase">
                    Description
                </p>
                <h4 class="mt-2 text-2xl font-extrabold leading-8 text-gray-900 dark:text-white sm:text-3xl sm:leading-9">
                    {data.name}
                </h4>
                <p class="mt-4 text-lg leading-6 text-gray-500 dark:text-gray-300">
                    {data.description}
                </p>
                <ul class="gap-6 mt-8 md:grid md:grid-cols-2 mb-6">
                    <li class="mt-6 lg:mt-0">
                        <div class="flex">
                            <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full dark:text-green-500 drark:bg-transparent">
                            <svg class="h-8 w-8 text-green-800"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

                            </span>
                            <span class="ml-4 text-base font-medium leading-6 text-gray-500 dark:text-gray-200">
                               Nu. {data.rate}
                            </span>
                        </div>
                    </li>
                    <li class="mt-6 lg:mt-0">
                        <div class="flex">
                            <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full dark:text-green-500 drark:bg-transparent">
                            <svg class="h-10 w-10 text-green-800"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="5" width="16" height="16" rx="2" />  <line x1="16" y1="3" x2="16" y2="7" />  <line x1="8" y1="3" x2="8" y2="7" />  <line x1="4" y1="11" x2="20" y2="11" />  <line x1="11" y1="15" x2="12" y2="15" />  <line x1="12" y1="15" x2="12" y2="18" /></svg>
                            </span>
                            <span class="ml-4 text-base font-medium leading-6 text-gray-500 dark:text-gray-200">
                                Expiry date: {data.expire.substring(0,10)}
                            </span>
                        </div>
                    </li>
                    <li class="mt-6 lg:mt-0">
                        <div class="flex">
                            <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full dark:text-green-500 drark:bg-transparent">
                            <svg class="h-10 w-10 text-green-800"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="12 4 4 8 12 12 20 8 12 4" />  <polyline points="4 12 12 16 20 12" />  <polyline points="4 16 12 20 20 16" /></svg>
                            </span>
                            <span class="ml-4 text-base font-medium leading-6 text-gray-500 dark:text-gray-200">
                                {data.quantity} Available
                            </span>
                        </div>
                    </li>
                    <li class="mt-6 lg:mt-0">
                        <div class="flex">
                            <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full dark:text-green-500 drark:bg-transparent">
                            <svg class="h-10 w-10 text-green-800"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"/>
</svg>

                            </span>
                            <span class="ml-4 text-base font-medium leading-6 text-gray-500 dark:text-gray-200">
                                Consultation Available
                            </span>
                        </div>
                    </li>
                </ul>



        <div class="flex flex-row">

        {/* <button type="button" class="py-2 mt-4 mr-2 px-4 flex justify-center items-center  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
        
        onClick={()=>{setQuote(true)}}

        
        >
<svg class="h-8 w-8 text-white mr-2"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />  <polyline points="22,6 12,13 2,6" /></svg>Get a Quote
</button> */}


        <button type="button" class="py-2 mt-4 px-4 flex justify-center items-center  bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
        
        onClick={()=>{window.open('tel:+97577428435')}}

        
        >
<svg class="h-8 w-8 text-white mr-2"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />  <path d="M15 7a2 2 0 0 1 2 2" />  <path d="M15 3a6 6 0 0 1 6 6" /></svg>

Get a Quote

</button>



        </div>


        {quote?


<>
  <div
    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
  >
    <div className="justify-center w-auto my-6   w-auto my-6 mx-auto ">
      {/*content*/}
      <div className=" border-0 rounded-lg shadow-lg justify-center content-center flex flex-col w-full bg-white outline-none focus:outline-none">
        {/*header*/}
        <div className="flex rounded-full items-start  p-2 ">
          
          <button
            className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
            onClick={() => setQuote(false)}
          >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
        </div>

        
        {/*body*/}
        <div className="relative p-1 justify-center flex-auto">
         
        <form >



          
      <div className=" overflow-hidden sm:rounded-md">
        <div className=" py-3 bg-white sm:p-6">
          <div className="grid grid-cols-2 gap-4">
       
           
        
            <div className="col-span-6 sm:col-span-4">
              <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Name</label>
             <input type="text"  
             className="space-y-6 text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
              
              value={name}
              onChange={(e)=>setName(e.target.value)} 
              ></input>
            </div>

            <div className="col-span-6 sm:col-span-4">
              <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Email</label>
             <input type="text"  
             className="space-y-6 text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
              
              value={email}
              onChange={(e)=>setEmail(e.target.value)} 
              ></input>
            </div>
            
            <div className="col-span-6 sm:col-span-4">
              <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Phone</label>
             <input type="text"  
             className="space-y-6 text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
              
              value={phone}
              onChange={(e)=>setPhone(e.target.value)} 
              ></input>
            </div>
            
            
         
          
        
          
          

            {/* <div className="py-1">
                      <span className="px-1 text-sm text-gray-600">Departure Date</span>
                      <DatePicker className="rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" selected={Date} onChange={date => setDate(date)} />
      
                      </div> */}


          </div>
            
     
        </div>
        
        
      </div>


    </form>
        </div>
        <div class="flex items-center justify-between p-3 ml-2 mr-2 bg-green-100 rounded">
      <span class="p-2 bg-white rounded-lg">
          <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
              <path d="M1520 1216q0-40-28-68l-208-208q-28-28-68-28-42 0-72 32 3 3 19 18.5t21.5 21.5 15 19 13 25.5 3.5 27.5q0 40-28 68t-68 28q-15 0-27.5-3.5t-25.5-13-19-15-21.5-21.5-18.5-19q-33 31-33 73 0 40 28 68l206 207q27 27 68 27 40 0 68-26l147-146q28-28 28-67zm-703-705q0-40-28-68l-206-207q-28-28-68-28-39 0-68 27l-147 146q-28 28-28 67 0 40 28 68l208 208q27 27 68 27 42 0 72-31-3-3-19-18.5t-21.5-21.5-15-19-13-25.5-3.5-27.5q0-40 28-68t68-28q15 0 27.5 3.5t25.5 13 19 15 21.5 21.5 18.5 19q33-31 33-73zm895 705q0 120-85 203l-147 146q-83 83-203 83-121 0-204-85l-206-207q-83-83-83-203 0-123 88-209l-88-88q-86 88-208 88-120 0-204-84l-208-208q-84-84-84-204t85-203l147-146q83-83 203-83 121 0 204 85l206 207q83 83 83 203 0 123-88 209l88 88q86-88 208-88 120 0 204 84l208 208q84 84 84 204z">
              </path>
          </svg>

      </span>
      {data.name}

  </div>
        
        <div className="flex items-center  justify-end p-6 ">
          <button
            className="bg-red-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
            type="button"
            style={{ transition: "all .15s ease"  }}
            onClick={() => setQuote(false)}
          >
            Cancel
          </button>
          <button
            className="bg-blue-400 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
            type="button"
            style={{ transition: "all .15s ease" }}
            onClick={() => {setQuote(false);}}
          >
            Send Message 
          </button>
        </div>
      </div>
    </div>
  </div>
  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
</>
:<></>}

         
{/*          
         {  !cart.includes(productid)? 
         
         <button type="button" class="py-2 mt-4 px-4 flex justify-center items-center  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
        
        onClick={()=>{addtoCart(productid)}}

        
        >
<svg class="h-8 w-8 mr-2 text-white"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="12" y1="8" x2="12" y2="16" />  <line x1="8" y1="12" x2="16" y2="12" /></svg>
    Add to Cart
</button>:


<>

<span class="px-4 py-2 mt-6  text-base rounded-full text-green-600  bg-green-200 ">
        In Cart
    </span>         
         </>}  */}
        
        
       








            </div>
           
        </div>
    </div>
</div>






<div>

{/* <p class="mt-2 mb-4 text-xl font-extrabold leading-8 tracking-tight text-gray-900 dark:text-white sm:text-2xl text-center">
            Recommended for you
        </p> */}

<div class="flex flex-col bg-white m-auto p-auto">

<div
  class="flex overflow-x-scroll pb-10  hide-scroll-bar"
>
  <div
    class="flex flex-nowrap  lg:ml-24 md:ml-10 ml-4 "
  >
  





  </div>
</div>
</div>
 
</div>

</>:<>LOADING...</>

}










{/* <Footer></Footer> */}
</>
  


    );
}

export default SingleProduct;