import React, {useContext, useRef, useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import DatePicker from 'react-datepicker'
import {UserContex} from '../App'
import { Carousel } from 'react-responsive-carousel';

import NavBar from './nav'

import "react-responsive-carousel/lib/styles/carousel.min.css";

require('react-datepicker/dist/react-datepicker.css')


const Inventory = ()=>{


  const history = useHistory()
  const {state, dispatch}= useContext(UserContex)

  const  [data, setData]= useState([])

  const [search,setSearch] = useState("")

  const [addStock,setAddStock]= useState(false)
  const [editStock,setEditStock]= useState(false)

  const[name,setName]= useState("")
  const[nameEdit,setNameEdit]= useState("")
  const[idEdit,setidEdit]= useState("")
  const[quantity,setQuantity]= useState()
  const[quantityEdit,setQuantityEdit]= useState()
  const[rate,setRate]= useState()
  const[bill,setBill]= useState()
  const[buyrate,setBuyRate]= useState()
  const[rateEdit,setRateEdit]= useState()
  const [date,setDate] = useState(new Date())
  const[cop,setCOP] = useState(false)
  const [labor,setLabour] = useState(0)
  const [timberCost,setTimberCost] = useState(0)
  const [materialCost,setMaterialCost] = useState(0)
  const [otherCost,setOtherCost] = useState(0)
  const [url,setUrl] = useState([])  

  const[progress,setProgress]= useState(false)


  

//     const [startDate, setStartDate] = useState(new Date());


//   const monthNames = ["January", "February", "March", "April", "May", "June",
//   "July", "August", "September", "October", "November", "December"];

// var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

// const d = new Date();




  useEffect(  ()=>{

    fetch('/getStock',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.stocks)

      
      
  })

  },[])


  const postStockUpdate = ()=>{

    fetch('/addStock',{

      method:"post",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
        name:name,
        quantity:quantity,
        rate:rate,
        buyrate:buyrate,
        bill:bill,
        expire:date,
        pic:url
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        window.location.reload(false);
      }
    })
    .catch(err=>{
      console.log(err)
    })
    history.push('/inventory')

  }

  
  const postStockUpdateUpdate = ()=>{

    fetch('/updateStock',{

      method:"put",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
        id:idEdit,
        name:nameEdit,
        quantity:quantityEdit,
        rate:rateEdit,
        buyrate:buyrate,
        bill:bill,
        expire:date,
        pic:url
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        window.location.reload(false);
      }
    })
    .catch(err=>{
      console.log(err)
    })
    history.push('/inventory')

  }





  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/search-stock',{

      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setData(results.stock)
      })


  }

  const singleStock = (Itemid)=>{

      console.log(Itemid)
    fetch('/getSingleStock',{
      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),
        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        id:Itemid
      })
  }).then(res=>res.json())
  .then(result=>{


    // setDataEdit(result.stock)
    console.log(result)
    setidEdit(result.stock._id)
    setNameEdit(result.stock.name)
    setQuantityEdit(result.stock.quantity)
    setRateEdit(result.stock.rate)
    setBuyRate(result.stock.buyrate)
    setBill(result.stock.bill)
    setUrl(result.stock.pic)
            
  })

  }


 
//   const uploadppt=({target:{files}})=>{

//     setProgress(true)

//     let data = new FormData()

//     for(const file of files){

//    data.append('uploadimage', file)
//    data.append('name',file.name)
// }
//        fetch("/uploadimage", {

//          method:"post",
//          body: data
//      })
//      .then(res=>res.json())
//      .then(data=>{

//       setUrl(old=>[...old, data.url])
//       setProgress(false)

      

       
//      })
//      .catch(err=>{
//          console.log(err)
//      })

// }


  


const uploadppt=({target:{files}})=>{

  setProgress(true)

 let data = new FormData()

 for(const file of files){

data.append('uploadimage', file)
data.append('name',file.name)
}
    fetch("/uploadimage", {

      method:"post",
      body: data
  })
  .then(res=>res.json())
  .then(data=>{

        setUrl(old=>[...old, data.url])
        setProgress(false)

   

    
  })
  .catch(err=>{
      console.log(err)
  })

}



return(

    <div>


 <script src="https://cdn.jsdelivr.net/gh/alpinejs/alpine@v2.x.x/dist/alpine.min.js" defer></script>

 <div  className="flex h-screen bg-white">

 <div className=" "></div>

        <link rel="stylesheet" href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css" />
  






        <div className="flex-1 flex flex-col overflow-hidden ">

            
          <NavBar/>


            <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white">
  <div className="container mx-auto px-6 py-8">
    <h3 className="text-gray-400 text-3xl font-medium">Inventory</h3>


<div className="flex  md:flex-row flex-col ...">


<div class="shadow-lg rounded-xl bg-gray-200 w-full md:w-64 p-6 bg-white dark:bg-gray-500 relative overflow-hidden">
    <p class="text-black text-xl">
        Items
    </p>
    <div class="flex items-center my-4 text-blue-500 rounded justify-between">
        <span class="rounded-lg p-2 bg-white">
        <svg class="h-8 w-8 text-gray-400"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M14.5 10c-.83 0-1.5-.67-1.5-1.5v-5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5z" />  <path d="M20.5 10H19V8.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />  <path d="M9.5 14c.83 0 1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5S8 21.33 8 20.5v-5c0-.83.67-1.5 1.5-1.5z" />  <path d="M3.5 14H5v1.5c0 .83-.67 1.5-1.5 1.5S2 16.33 2 15.5 2.67 14 3.5 14z" />  <path d="M14 14.5c0-.83.67-1.5 1.5-1.5h5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-5c-.83 0-1.5-.67-1.5-1.5z" />  <path d="M15.5 19H14v1.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z" />  <path d="M10 9.5C10 8.67 9.33 8 8.5 8h-5C2.67 8 2 8.67 2 9.5S2.67 11 3.5 11h5c.83 0 1.5-.67 1.5-1.5z" />  <path d="M8.5 5H10V3.5C10 2.67 9.33 2 8.5 2S7 2.67 7 3.5 7.67 5 8.5 5z" /></svg>        </span>
        <div class="flex flex-col w-full ml-2 items-start justify-evenly">
            <p class="text-black text-lg">
                {data.length ? data.length:0}
            </p>
            <p class="text-gray-700 text-sm">
                Total Item Types
            </p>
        </div>
    </div>
   
    <div class="mt-4">
        <button type="button" class="py-2 px-4  bg-black hover:bg-gray-400 focus:ring-gray-400 focus:ring-offset-gray-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md "
        
        onClick={()=>setAddStock(true)}
        >
            Add Stocks +
        </button>
    </div>
</div>

<div class="relative w-full  cursor-pointer  hover:bg-gray-200 md:ml-4 md:mt-0 mt-4 p-4 overflow-hidden bg-white shadow-lg rounded-xl md:w-60 dark:bg-gray-800" 
  
  onClick={()=>setCOP(true)}
>
    <p class="text-black text-xl text-center">
        Cost of Production
    </p>
    <div class="flex items-center my-4 text-blue-500 rounded justify-between">
        <span class="rounded-lg p-2 ">
        <svg class="h-10 w-10 text-gray-500 object-center content-center"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
>  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="3" width="16" height="18" rx="2" />  <rect x="8" y="7" width="8" height="3" rx="1" />  <line x1="8" y1="14" x2="8" y2="14.01" />  <line x1="12" y1="14" x2="12" y2="14.01" />  <line x1="16" y1="14" x2="16" y2="14.01" />  <line x1="8" y1="17" x2="8" y2="17.01" />  <line x1="12" y1="17" x2="12" y2="17.01" />  <line x1="16" y1="17" x2="16" y2="17.01" /></svg>        
        
           </span>
        <div class="flex flex-col w-full ml-2 items-start justify-evenly">
            <p class="text-black text-lg">
                Calculate
            </p>
            <p class="text-gray-700 text-sm">
                Cost of Production
            </p>
        </div>
    </div>
   
    <div class="mt-4">
        <button type="button" class="py-2 px-4  bg-black hover:bg-gray-400 focus:ring-gray-400 focus:ring-offset-gray-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md "
        
        // onClick={()=>setCOP(true)}
        >
            Calculate COP
        </button>
    </div>
</div>

  
</div>
    
   
    
    <br></br>

    {
addStock? 
(<>


{
addStock? 
(

  <>
  
    <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full mb-5" >
      <div className="md:flex w-full">
        
        <div className="w-full md:w-1/2 py-10 px-5 md:px-10">
          <div className="text-center mb-10">
            <h1 className="font-bold text-3xl text-gray-900">Add Product </h1>
          </div>
          <div>
<div class="grid max-w-xl grid-cols-2 gap-4 ">


<div className="py-1">
<span className="px-1 text-sm text-gray-600">Name</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Name"
value={name}
onChange={(e)=>setName(e.target.value)}
>

</input>
</div>

<div className="py-1">
<span className="px-1 text-sm text-gray-600">Quantity</span>
                <input type="number"  className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                placeholder='Quantity'
                value={quantity}
                onChange={(e)=>setQuantity(e.target.value)} 
                ></input>
</div>
</div>
<div class="grid max-w-xl grid-cols-2 gap-4 ">

<div className="py-1 ">
  <div>
  <span className="px-1 text-sm text-gray-600">Cost of Production</span>
<input  type="number" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Cost of Production"
value={buyrate}
onChange={(e)=>setBuyRate(e.target.value)}
>

</input>
  </div>

 




</div>

<div className="py-1">
<span className="px-1 text-sm text-gray-600">Selling Rate</span>
                <input type="number"  className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                
               placeholder='Selling Price'
                value={rate}
                onChange={(e)=>setRate(e.target.value)} 
                ></input>
</div>

</div>
<div class="cursor-pointer  items-center"
>
  <svg class="h-16 w-16 text-gray-500 object-center content-center"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
  
  onClick={()=>setCOP(true)}>  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="3" width="16" height="18" rx="2" />  <rect x="8" y="7" width="8" height="3" rx="1" />  <line x1="8" y1="14" x2="8" y2="14.01" />  <line x1="12" y1="14" x2="12" y2="14.01" />  <line x1="16" y1="14" x2="16" y2="14.01" />  <line x1="8" y1="17" x2="8" y2="17.01" />  <line x1="12" y1="17" x2="12" y2="17.01" />  <line x1="16" y1="17" x2="16" y2="17.01" /></svg>
  Calculate COP
  </div>

{/* <div class="grid max-w-xl grid-cols-2 gap-4 "> */}



{/* </div> */}
<div class="grid max-w-xl grid-cols-2 gap-4 ">


<div className="py-1">
<span className="px-1 text-sm text-gray-600">Bill No</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Bill No"
value={bill}
onChange={(e)=>setBill(e.target.value)}
>

</input>
</div>

                      
 <div className="py-1">
                 <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Manufacture Date</label>
                 <DatePicker className="rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" selected={date} onChange={date => setDate(date)} />

              </div>
</div>

  

<div className="col-span-4 w-full mb-5 ">
                        <label className="flex flex-col items-center  bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-300 hover:text-white">
         <svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
             <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
         </svg>
        <span className="mt-2 text-base leading-normal">Upload</span>
        <input type='file' className="hidden" 
        onChange={uploadppt}
        />
        
    </label>



 

    
                               </div>
 
     



  <div className="w-full px-3 mb-5">

    
  
      <button className="block w-full max-w-xs mx-auto bg-blue-300 hover:bg-blue-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold"
                  onClick={() => {setAddStock(false);postStockUpdate()}}

      > Add Stock</button>
      <button className="block w-full max-w-xs mx-auto bg-red-300 hover:bg-red-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold mt-4"
                  onClick={() => {setAddStock(false)}}

      > Cancel</button>
                  


    </div>



    
</div>
       
</div>

<div className=" md:block md:w-1/2 w-full  bg-white py-10 px-10">


<Carousel showArrows={true} >
         
         
               {url ? url.map(pic=>{
                return(
                  <div>
                  <img src={pic} alt="medicine"  />
          
                   </div>
                )
               }):<></> }         
        
       
         
        
       

   

     </Carousel>


     {progress?         <span className=" text-base leading-normal"> Uploading...</span>:   <span className=" text-base leading-normal"> Upload Photo </span>
         }
                <input type='file' className="hidden"  required
                onChange={uploadppt}
                />
                
        
            {progress?
                <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
            :<></>}

</div>
          </div>
        </div>
  </>






)


:null

}

</>

)


:null

}


{cop?


  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="justify-center w-auto my-6   w-auto my-6 mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg justify-center content-center flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-2 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setCOP(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>

          
          {/*body*/}
          <div className="relative p-1 justify-center flex-auto">
           
          <form >



            
        <div className=" overflow-hidden sm:rounded-md">
          <div className=" py-3 bg-white sm:p-6">
            <div className="grid grid-cols-2 gap-4">
         
             
          
              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Labour Cost</label>
               <input type="number"  
               className="space-y-6 text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                
                value={labor}
                onChange={(e)=>setLabour(e.target.value)} 
                ></input>
              </div>

              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Timber Cost</label>
               <input type="number"  
               className="space-y-6 text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                
                value={timberCost}
                onChange={(e)=>setTimberCost(e.target.value)} 
                ></input>
              </div>
              
              
              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Materials Cost</label>
               <input type="number"  
               className="space-y-6 text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                
                value={materialCost}
                onChange={(e)=>setMaterialCost(e.target.value)} 
                ></input>
              </div>
              
            
              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Others</label>
               <input type="number"  
                              className="space-y-6 text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

                value={otherCost}
                onChange={(e)=>setOtherCost(e.target.value)} 
                ></input>
              </div>
              
            
          
            
            

              {/* <div className="py-1">
                        <span className="px-1 text-sm text-gray-600">Departure Date</span>
                        <DatePicker className="rounded-lg w-full
                  bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" selected={Date} onChange={date => setDate(date)} />
        
                        </div> */}


            </div>
              
       
          </div>
          
          
        </div>


      </form>
          </div>
          <div class="flex items-center justify-between p-3 ml-2 mr-2 bg-green-100 rounded">
        <span class="p-2 bg-white rounded-lg">
            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1520 1216q0-40-28-68l-208-208q-28-28-68-28-42 0-72 32 3 3 19 18.5t21.5 21.5 15 19 13 25.5 3.5 27.5q0 40-28 68t-68 28q-15 0-27.5-3.5t-25.5-13-19-15-21.5-21.5-18.5-19q-33 31-33 73 0 40 28 68l206 207q27 27 68 27 40 0 68-26l147-146q28-28 28-67zm-703-705q0-40-28-68l-206-207q-28-28-68-28-39 0-68 27l-147 146q-28 28-28 67 0 40 28 68l208 208q27 27 68 27 42 0 72-31-3-3-19-18.5t-21.5-21.5-15-19-13-25.5-3.5-27.5q0-40 28-68t68-28q15 0 27.5 3.5t25.5 13 19 15 21.5 21.5 18.5 19q33-31 33-73zm895 705q0 120-85 203l-147 146q-83 83-203 83-121 0-204-85l-206-207q-83-83-83-203 0-123 88-209l-88-88q-86 88-208 88-120 0-204-84l-208-208q-84-84-84-204t85-203l147-146q83-83 203-83 121 0 204 85l206 207q83 83 83 203 0 123-88 209l88 88q86-88 208-88 120 0 204 84l208 208q84 84 84 204z">
                </path>
            </svg>
        </span>
        <div class="flex items-center justify-between w-full ml-2">
            <p>
                Total COP
            </p>
            <p>
               Nu. {parseInt(labor)+parseInt(timberCost)+parseInt(materialCost)+parseInt(otherCost)}
            </p>
        </div>
    </div>
          
          <div className="flex items-center  justify-end p-6 ">
            <button
              className="bg-red-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
              type="button"
              style={{ transition: "all .15s ease"  }}
              onClick={() => setCOP(false)}
            >
              Cancel
            </button>
            <button
              className="bg-blue-400 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
              type="button"
              style={{ transition: "all .15s ease" }}
              onClick={() => {setCOP(false);setBuyRate(parseInt(labor)+parseInt(timberCost)+parseInt(materialCost)+parseInt(otherCost))}}
            >
              Use the value 
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
:<></>}

    {
editStock? 
(
  <>
  
  <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full mb-5" >
    <div className="md:flex w-full">
      
      <div className="w-full md:w-1/2 py-10 px-5 md:px-10">
        <div className="text-center mb-10">
          <h1 className="font-bold text-3xl text-gray-900">Update Product {idEdit} </h1>
        </div>
        <div>
<div class="grid max-w-xl grid-cols-2 gap-4 ">


<div className="py-1">
<span className="px-1 text-sm text-gray-600">Name</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Name"
value={nameEdit}
onChange={(e)=>setNameEdit(e.target.value)}
>

</input>
</div>

<div className="py-1">
<span className="px-1 text-sm text-gray-600">Quantity</span>
              <input type="number"  className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
              bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
              placeholder='Quantity'
              value={quantityEdit}
              onChange={(e)=>setQuantityEdit(e.target.value)} 
              ></input>
</div>
</div>
<div class="grid max-w-xl grid-cols-2 gap-4 ">

<div className="py-1 ">
<div>
<span className="px-1 text-sm text-gray-600">Cost of Production</span>
<input  type="number" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Cost of Production"
value={buyrate}
onChange={(e)=>setBuyRate(e.target.value)}
>

</input>
</div>






</div>

<div className="py-1">
<span className="px-1 text-sm text-gray-600">Selling Rate</span>
              <input type="number"  className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
              bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
              
             placeholder='Selling Price'
              value={rateEdit}
              onChange={(e)=>setRateEdit(e.target.value)} 
              ></input>
</div>

</div>
<div class="cursor-pointer  items-center"
>
<svg class="h-16 w-16 text-gray-500 object-center content-center"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"

onClick={()=>setCOP(true)}>  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="3" width="16" height="18" rx="2" />  <rect x="8" y="7" width="8" height="3" rx="1" />  <line x1="8" y1="14" x2="8" y2="14.01" />  <line x1="12" y1="14" x2="12" y2="14.01" />  <line x1="16" y1="14" x2="16" y2="14.01" />  <line x1="8" y1="17" x2="8" y2="17.01" />  <line x1="12" y1="17" x2="12" y2="17.01" />  <line x1="16" y1="17" x2="16" y2="17.01" /></svg>
Calculate COP
</div>

{/* <div class="grid max-w-xl grid-cols-2 gap-4 "> */}



{/* </div> */}
<div class="grid max-w-xl grid-cols-2 gap-4 ">


<div className="py-1">
<span className="px-1 text-sm text-gray-600">Bill No</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Bill No"
value={bill}
onChange={(e)=>setBill(e.target.value)}
>

</input>
</div>

                    
<div className="py-1">
               <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Manufacture Date</label>
               <DatePicker className="rounded-lg w-full
        bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" selected={date} onChange={date => setDate(date)} />

            </div>
</div>



<div className="col-span-4 w-full mb-5 ">
                      <label className="flex flex-col items-center  bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-300 hover:text-white">
       <svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
           <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
       </svg>
      <span className="mt-2 text-base leading-normal">Upload</span>
      <input type='file' className="hidden" 
      onChange={uploadppt}
      />
      
  </label>





  
                             </div>

   



<div className="w-full px-3 mb-5">

  

    <button className="block w-full max-w-xs mx-auto bg-blue-300 hover:bg-blue-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold"
                onClick={() => {setAddStock(false);postStockUpdateUpdate()}}

    > Update Stock</button>
    <button className="block w-full max-w-xs mx-auto bg-red-300 hover:bg-red-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold mt-4"
                onClick={() => {setEditStock(false)}}

    > Cancel</button>
                


  </div>



  
</div>
     
</div>

<div className=" md:block md:w-1/2 w-full  bg-white py-10 px-10">


<Carousel showArrows={true} >
       
       
             {url ? url.map(pic=>{
              return(
                <div>
                <img src={pic} alt="medicine"  />
        
                 </div>
              )
             }):<></> }         
      
     
       
      
     

 

   </Carousel>


   {progress?         <span className=" text-base leading-normal"> Uploading...</span>:   <span className=" text-base leading-normal"> Upload Photo </span>
       }
              <input type='file' className="hidden"  required
              onChange={uploadppt}
              />
              
      
          {progress?
              <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
          :<></>}

</div>
        </div>
      </div>
</>

//   <>
//     <div
//       className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
//     >
//       <div className="relative w-auto my-6  relative w-auto my-6 mx-auto ">
//         {/*content*/}
//         <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
//           {/*header*/}
//           <div className="flex rounded-full items-start  p-5 ">
            
//             <button
//               className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
//               onClick={() => setEditStock(false)}
//             >
// <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
//           </div>
//           {/*body*/}
//           <div className="relative p-6 flex-auto">
           
//           <form >



            
//         <div className=" overflow-hidden sm:rounded-md">
//           <div className="px-4 py-5 bg-white sm:p-6">
//             <div className="grid grid-cols-6 gap-6">
//               <div className="col-span-6 sm:col-span-4">
//                 <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">Name</label>
//                 <input type="text" 
//                 name="first_name"
//                  id="first_name" 
//                  autoComplete="given-name"
//                 className="rounded-lg w-full
//                 bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
//                 value={nameEdit}
//                 onChange={(e)=>setNameEdit(e.target.value)}  
//                   />
//               </div>
              
         
             
          
//               <div className="col-span-6 sm:col-span-4">
//                 <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">quantity</label>
//                <input type="number"  className="rounded-lg w-full
//                 bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
//                 value={quantityEdit}
//                 onChange={(e)=>setQuantityEdit(e.target.value)} 
//                 ></input>
//               </div>
              
//               <div className="col-span-6 sm:col-span-4">
//                 <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">buy Rate</label>
//                <input type="number"  className="rounded-lg w-full
//                 bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
//                 value={buyrate}
//                 onChange={(e)=>setBuyRate(e.target.value)} 
//                 ></input>
//               </div>
              
//               <div className="col-span-6 sm:col-span-4">
//                 <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Rate</label>
//                <input type="number"  className="rounded-lg w-full
//                 bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
//                 value={rateEdit}
//                 onChange={(e)=>setRateEdit(e.target.value)} 
//                 ></input>
//               </div>
//               <div className="col-span-6 sm:col-span-4">
//                 <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Bill No</label>
//                <input type="text"  className="rounded-lg w-full
//                 bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
//                 value={bill}
//                 onChange={(e)=>setBill(e.target.value)} 
//                 ></input>
//               </div>


             
            

//               {/* <div className="py-1">
//                         <span className="px-1 text-sm text-gray-600">Departure Date</span>
//                         <DatePicker className="rounded-lg w-full
//                   bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" selected={Date} onChange={date => setDate(date)} />
        
//                         </div> */}


          
// <div className="col-span-6 sm:col-span-4">
//                 <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Date</label>
//                 <DatePicker className="rounded-lg w-full
//           bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" selected={date} onChange={date => setDate(date)} />

//               </div>

          
              
//             </div>
              
       
//           </div>
          
          
//         </div>


//       </form>
//           </div>

//           <div className="col-span-4 w-full mb-5 ">
//                         <label className="flex flex-col items-center  bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-300 hover:text-white">
//          <svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
//              <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
//          </svg>
//         <span className="mt-2 text-base leading-normal">Upload</span>
//         <input type='file' className="hidden" 
//         onChange={uploadppt}
//         />
        
//     </label>

//     </div>
          
//           <div className="flex items-center  justify-end p-6 ">
//             <button
//               className="bg-red-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
//               type="button"
//               style={{ transition: "all .15s ease"  }}
//               onClick={() => setEditStock(false)}
//             >
//               Cancel
//             </button>
//             <button
//               className="bg-blue-400 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
//               type="button"
//               style={{ transition: "all .15s ease" }}
//               onClick={() => {setEditStock(false); postStockUpdate()}}
//             >
//               Update Stock 
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//     <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
//   </>
)


:null

}








    <div className="w-full h-10 pl-3 pr-2 bg-gray-100 border-gray-50 rounded-full  outline-none  border-none flex focus:outline-none justify-between items-center relative">
  <input type="text" name="search" id="search" placeholder="Search" 
         className=" w-full bg-gray-100 border-none rounded-full    focus:outline-none focus:border-white active:outline-none"
         value= {search} onChange={(e)=>fetchPost(e.target.value)}
         />
  <button type="submit" className="ml-1 outline-none border-none focus:border-none  active:outline-none">
    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
         viewBox="0 0 24 24" className="w-6 h-6">
      <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
    </svg>
  </button>
</div>

 


    <div className="mt-8">
    </div>
    <div className="flex flex-col mt-8">
      <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="px-6 py-3 border-b border-gray-200 bg-black text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                Name
                  </th>
               
                <th className="px-6 py-3 border-b border-gray-200 bg-black text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                Selling Price                   
                   </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-black text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                  
                Qnty
                  
                  </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-black text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                  
                MFD
                  
                  </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-black text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                  
                Bill
                  
                  </th>
                
              </tr>
            </thead>




 <tbody className="bg-white">
            {data.map(item=>{


          var a =item.expire.substring(0,10)

                var b = date.toISOString().substring(0,10)


                var date1 = new Date(b);
                var date2 = new Date(a);
                
                // var timeDiff = Math.abs(date1.getTime() - date2.getTime());
                // var diffDays = Math.ceil(parseInt((date2 - date1) / (24 * 3600 * 1000)));
                

              return(

                
            
              <tr data-href="/profile" key={item._id} style={{cursor:"pointer"}} class="bg-white" >

                  <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                  
                  onClick={()=>{setEditStock(true);singleStock(item._id)}}
                  >
                             
                  <div className="flex items-center">
                                    
                    <div className="ml-4">
                      <div className="text-sm leading-5 font-medium text-gray-900">{item.name}
                      </div>
                    </div>
                  </div>
                </td>


                
              
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                  onClick={()=>{setEditStock(true);singleStock(item._id)}}

                >
                  <div className="text-sm leading-5 text-gray-900">{item.rate}</div>
                </td>
               
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
               onClick={()=>{setEditStock(true);singleStock(item._id)}}

                >
                  <div className="text-sm leading-5 text-gray-900">{item.quantity}</div>
                </td>             
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
               onClick={()=>{setEditStock(true);singleStock(item._id)}}

                >
                  <div className="text-sm leading-5 text-gray-900">{item.expire.substring(0, 10)}</div>


              </td>             
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
               onClick={()=>{setEditStock(true);singleStock(item._id)}}

                >
                  <div className="text-sm leading-5 text-gray-900">{item.bill}</div>
                </td>             
              </tr> 


              )
            })}
           
  
             
            </tbody>

          
          </table>
        </div>
      </div>
    </div>
  </div>
</main>
</div>

    </div>
  </div>  
);


  
}


export default Inventory


