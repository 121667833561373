import React, {useContext, useRef, useEffect, useState} from 'react'
import {useParams,Link, useHistory} from 'react-router-dom'
import DatePicker from 'react-datepicker'
import Select from "react-select";

import { Document, Page, Text, View, StyleSheet , PDFViewer,PDFDownloadLink,Image} from '@react-pdf/renderer';


import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

// import Generatereport from './Generatereport';
import NavBar from './nav';

import {UserContex} from '../App'

const Expense = ()=>{

  const history = useHistory()
  const {state, dispatch}= useContext(UserContex)

  const  [data, setData]= useState([])
  const  [creditData, setCreditData]= useState([])
  const  [TotalExpense, setTotalExpense]= useState()
  
  const [search,setSearch] = useState("")

  const [addCredit,setAddCredit]= useState(false)

  const[name,setName]= useState("")
  const[description,setDescription]= useState("")
  const[amount,setAmount]= useState()
  const[jrnl,setJrnl]= useState()
  const[date,setDate]= useState(new Date)
  const [showCalender,setShowCalender] = useState(false)

  const fileName = 'Bill'


    


  useEffect(  ()=>{

   
    fetch(`/getExpenses`,{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{

      setCreditData(result.expenses)
      setTotalExpense(result.sumExpense)
      
  })

   





  },[])



  const postCredit = ()=>{

    fetch('/addExpenses',{

      method:"post",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
        name,
        description,
        jrnl,
        amount,
      date})
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
        window.location.reload(false);

      }

      else{
        
        window.location.reload(false);
    

      }
    })
    .catch(err=>{
      console.log(err)
    })

  }
  const deleteExpense = (id)=>{

    fetch(`/deleteexpense/${id}`,{
        method: "delete",
        headers:{
            Authorization:"Bearer "+localStorage.getItem("jwt")
        }
    }).then(res=>res.json())
    .then(result=>{
      window.location.reload(false);

    }).catch(err=>{
        console.log(err)
    })
}


  




  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/search-expense',{

      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),
        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setCreditData(results.expense)
        var sum =0
        results.expense.map(expense=>{
          sum+=expense.amount

        })

        setTotalExpense(sum)
      })


  }

  
    
  const searchdate=(query)=>{

  
  
    fetch('/searchdateexpense',{
  
      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),
  
        "Content-Type":"application/json"
  
      },
      body:JSON.stringify({
       date: query
      })
  
    }).then(res=> res.json())
      .then(results=>{
        
        setCreditData(results.order)

        var sum =0
        results.order.map(expense=>{
          sum+=expense.amount

        })

        setTotalExpense(sum)
  
       
        
      })
  
  
  }
  
  
  const PDFComponent = (da) => (


    <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
      <Image style={styles.logo} src="/logo.png"
   />
  
        <Text style={styles.address}>Green Gold Wood Works</Text>
        <Text style={{fontSize:10, marginBottom: 3}}>Tsirang</Text>
        <Text style={{fontSize:10,marginBottom: 3}}>TPN: GAB66324</Text>
        <Text style={{fontSize:10,marginBottom: 3}}>Contact:77428435 / 77413093</Text>
  
      </View>
  
      <View style={styles.invoiceDetails}>
        <View style={styles.itemList}>
          <View style={styles.itemRow}>
            <Text style={styles.label}>Name</Text>
            <Text style={styles.label}>Description</Text>
            <Text style={styles.label}>Amount</Text>
            <Text style={styles.label}>Date</Text>
          </View>
          {creditData.map((item, index) => (
            <View key={index} style={styles.itemRow}>
              <Text style={styles.itemDescription}>{item.name}</Text>
              <Text style={styles.itemDescription}>{item.description}</Text>
              <Text style={styles.itemDescription}>{item.amount}</Text>
              <Text style={styles.itemDescription}>{item.date.substring(0,10)}</Text>
            </View>
          ))}
        </View>
       
        <View style={styles.totalRow}>
          <Text style={styles.totalLabel}>Total:{TotalExpense}</Text>
          {/* <Text style={styles.totalValue}>{invoice.total}</Text> */}
        </View>
      </View>
    </Page>
  </Document>
    
  );
  
  const styles = StyleSheet.create({
    page: {
      fontFamily: 'Helvetica',
      padding: 30,
    },
    header: {
      marginBottom: 20,
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 10,
    },
    address: {
      fontSize: 12,
      marginBottom: 10,
    },
    invoiceDetails: {
      marginBottom: 20,
    },
    label: {
      fontSize: 20,
      fontWeight: 'bold',
    },
    value: {
      fontSize: 12,
    },
    itemList: {
      marginTop: 20,
    },
    itemRow: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      marginBottom: 8,
      marginLeft:8
    },
    itemDescription: {
      width: '20%',
      textAlign:'center'
    },
    itemQuantity: {
      width: '15%',
      textAlign: 'left',
    },
    itemAmount: {
      width: '25%',
      textAlign: 'right',
    },
    totalRow: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: 10,
    },
    totalLabel: {
      fontSize: 14,
      fontWeight: 'bold',
    },
    totalValue: {
      fontSize: 14,
      marginLeft: 10,
    },
    logo: {
      width: 80, 
      height: 80, 
      marginBottom: 10,
      justifyContent: 'center',
      alignItems: 'center',  },
  
  
    horizontalLine: {
      borderTop: 1,
      borderColor: 'black',
      marginVertical: 10,
      width: '100%',
    },
  });
  
      

return(

    <div>


 <script src="https://cdn.jsdelivr.net/gh/alpinejs/alpine@v2.x.x/dist/alpine.min.js" defer></script>

 <div  className="flex h-screen bg-white">

 <div className=" "></div>

        <link rel="stylesheet" href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css" />
  






        <div className="flex-1 flex flex-col overflow-hidden ">

            
<NavBar/>


            <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white">
  <div className="container mx-auto px-6 py-8">
    <h3 className="text-gray-400 text-3xl font-medium">Expenses</h3>


<div className="flex md:flex-row flex-col ...">


<div class="shadow-lg rounded-xl bg-gray-200 w-full md:w-64 p-6 bg-white dark:bg-gray-500 relative overflow-hidden">

    <div class="flex items-center my-4 text-blue-500 rounded justify-between">
        <span class="rounded-lg p-2 bg-white">
        <svg class="h-8 w-8 text-gray-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="7" y="9" width="14" height="10" rx="2" />  <circle cx="14" cy="14" r="2" />  <path d="M17 9v-2a2 2 0 0 0 -2 -2h-10a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h2" /></svg>        
        
        
             </span>
        <div class="flex flex-col w-full ml-2 items-start justify-evenly">
            <p class="text-black text-lg">
            Nu. {TotalExpense}
            </p>
            <p class="text-gray-700 text-sm">
                Total Expenses
            </p>
            
        </div>

      
    </div>
    <div class="mt-4">
        <button type="button" class="py-2 px-4  bg-black hover:bg-gray-400 focus:ring-gray-400 focus:ring-offset-gray-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md "
        
        onClick={()=>setAddCredit(true)}
        >
            Add Expense +
        </button>
    </div>
</div>




  
</div>
    
   
    
  
    <br/>








    <div className="w-full h-10 pl-3 pr-2 bg-gray-100 border-gray-50 rounded-full  outline-none  border-none flex focus:outline-none justify-between items-center relative">
  <input type="text" name="search" id="search" placeholder="Search" 
         className=" w-full bg-gray-100 border-none rounded-full    focus:outline-none focus:border-white active:outline-none"
         value= {search} onChange={(e)=>fetchPost(e.target.value)}
         />
  <button type="submit" className="ml-1 outline-none border-none focus:border-none  active:outline-none">
    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
         viewBox="0 0 24 24" className="w-6 h-6">
      <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
    </svg>
  </button>
</div>

<br/>
    
    <div class="flex flex-row">


  

<div>

<span class={`px-4 py-2 m-2 flex w-48 items-center text-base rounded-full text-blue-500   ${showCalender?"bg-blue-200":"border border-indigo-300 undefined"} cursor-pointer`}
onClick={()=>setShowCalender(!showCalender)}
>
<svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="5" width="16" height="16" rx="2" />  <line x1="16" y1="3" x2="16" y2="7" />  <line x1="8" y1="3" x2="8" y2="7" />  <line x1="4" y1="11" x2="20" y2="11" />  <rect x="8" y="15" width="2" height="2" /></svg>
    Search by Date
</span>

</div>



</div>



<div class="flex space-x-4">

{/* <MyPDF /> */}

{/* <Generatereport/> */}





</div>



{/* <button type="button" class=" py-2 px-4 flex justify-center items-center  bg-green-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "

onClick={()=>setAddCredit(true)}
>
<svg class="h-8 w-8 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
</svg>

    Add Expenses
</button> */}



<br/>
{showCalender?<>

<div class="h-1/2 w-1/2">
  <FullCalendar
  
  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
  headerToolbar={{
    // left: 'prev,next today',
    // center: 'title',
    // right: 'dayGridMonth',
  }}
  initialView='dayGridMonth'
  editable={true}
  selectable={true}
  selectMirror={true}
  dayMaxEvents={true}
  height={300}
  // weekends={weekendsVisible}
  // events={data} 
  // select={handleDateSelect}
  // eventContent={renderEventContent}
  //  eventClick={handleEventClick}
  // eventsSet={handleEvents}

  dateClick={(info)=>{

      searchdate(info.dateStr)

      // console.log("INFOOO",info.dateStr)
  }}
  
/>
</div>

<br/>

</>:<></>}

<div >
          <PDFDownloadLink document={<PDFComponent test={"TESt sf"}/>} fileName="bill.pdf">
      {({ blob, url, loading, error }) =>
        loading ? 'Loading document...' : <>
        <span class={`px-4 py-2 m-2 flex w-24 items-center text-base rounded-full text-blue-500  hover:bg-blue-200 border border-indigo-300 undefined  cursor-pointer`}
>
<svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />  <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />  <rect x="7" y="13" width="10" height="8" rx="2" /></svg>
    Print
</span>
        </>
      }
    </PDFDownloadLink>
            
            </div>


<div className="flex ">
      <div className="grid  grid-cols-2 md:grid-cols-6 space-x-1 space-y-1">

    


        { creditData?  creditData.map(item=>{

            return(

  
              <div className="   bg-white w-full flex items-center p-2 rounded-xl shadow-md border transition duration-500 transform hover:-translate-y-1 hover:scale-111 ease-in-out">
              <div className="flex-grow p-5   ">
              <div style={{cursor:"pointer"}} className="absolute top-1 right-1 text-red-600"
                  
                  onClick={()=>{if(window.confirm('Are you sure you want to delete?')) deleteExpense(item._id)}}
             
                   >
                     <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
             
                   ></path>  
                   </svg></div>
                   <div className="font-semibold text-gray-700">
                  {item.name}
                  
                </div>
                <div className="text-sm text-gray-500">
                  Description: {item.description}
                </div>
                {item.jrnl?<div className="text-sm text-gray-500">
                  Jrnl: {item.jrnl}
                </div>:<></>
                
              }
                
              
                <div className="text-sm text-gray-500">
                  Amount : {item.amount}
                </div>
                <div className="text-sm text-gray-500">
                  Date : {item.date.substring(0,10)}
                </div>
            
              </div>
            </div>
            )

        }): <>Loading! </>


        }
      
        
          
   
   
          
        
      
      </div>
     
    </div>
 
    {
addCredit? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto my-6  relative w-auto my-6 mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-5 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setAddCredit(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          {/*body*/}
          <div className="relative p-6 flex-auto">
           
          <form >



            
        <div className=" overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 bg-white sm:p-6">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">Name</label>
                
                <input type="text" 
              name="first_name"
               id="first_name" 
               autoComplete="given-name"
              className="rounded-lg w-full
              bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
              value={name}
              onChange={(e)=>setName(e.target.value)}  
                />
              
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">Description</label>

              <select type="text" 
              name="first_name"
               id="first_name" 
               autoComplete="given-name"
              className="rounded-lg w-full
              bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
              value={description}
              onChange={(e)=>setDescription(e.target.value)}  
                >

              <option value="">
                      Select an option
                  </option>
                 
                  <option value="Fuel">
                  Fuel
                  </option>
                  <option value="Bhutanese Payment">
                  Bhutanese Payment
                  </option>
                  <option value="Indian Payment">
                  Indian Payment
                  </option>
                  <option value="Timber Purchase">
                  Timber Purchase                  
                  </option>
                  <option value="Timber Advance">
                  Timber Advance                  
                  </option>
                  <option value="Machine Parts">
                  Machine Parts                   
                  </option>
                  <option value="Mess">
                  Mess                   
                  </option>
                  <option value="Others">
                  Others                   
                  </option>

                </select>



              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">Jrnl</label>

              <input type="text" 
              name="first_name"
               id="first_name" 
               autoComplete="given-name"
              className="rounded-lg w-full
              bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
              value={jrnl}
              onChange={(e)=>setJrnl(e.target.value)}  
                />
                
            
                {/* <input type="text" 
                name="first_name"
                 id="first_name" 
                 autoComplete="given-name"
                className="rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                value={name}
                onChange={(e)=>setName(e.target.value)}  
                  /> */}
              </div>
              
         
             
          
              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Amount</label>
               <input type="number"  className="rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                
                value={amount}
                onChange={(e)=>setAmount(e.target.value)} 
                ></input>
              </div>
              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Date</label>
                <DatePicker className="rounded-lg w-full
        bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" selected={date} onChange={date => setDate(date)} />

              </div>
              

            
          
              
            </div>
              
       
          </div>
          
          
        </div>


      </form>
          </div>
          
          <div className="flex items-center  justify-end p-6 ">
            <button
              className="bg-red-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
              type="button"
              style={{ transition: "all .15s ease"  }}
              onClick={() => setAddCredit(false)}
            >
              Cancel
            </button>
            <button
              className="bg-blue-400 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
              type="button"
              style={{ transition: "all .15s ease" }}
              onClick={() => {setAddCredit(false); postCredit() }}
            >
              Add Expense 
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)


:null

}
   
  </div>
</main>
</div>

    </div>
  </div>  
);


  
}



export default Expense